import { EmailTemplateTypes, PolicyType } from "shared";
import { When } from "react-if";
import { useMemo } from "react";

import { useAdhocActionModal } from "../../../../hooks/useAdhocActionModal";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { EmailTemplateSelector } from "../PolicyForm/Fields/EmailTemplateSelector";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { fieldIsValid } from "../../../../lib/helper";
import { FormRow } from "../../../Molecules/Form/FormRow";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { useAccountingResources } from "../../../../hooks/useAccountingResources";
import { ICS_ARTICLE } from "../../../../constants/links";

export function AdhocInvoiceEmail() {
  const { type, currentSystemPolicy, currentSystemPolicyForm, update, invoiceId, invoiceNumber, invalids, isBulk, invoiceIds } = useAdhocActionModal();

  const { data: resources, isLoading } = useAccountingResources();

  const showFileAttachments = useMemo(() => {
    return resources?.fileAccessScope;
  }, [ resources, isLoading ]);

  if (!currentSystemPolicy || !currentSystemPolicyForm) return null;

  if (!type) return null;

  if (type !== PolicyType.REMINDER || currentSystemPolicy.policy_type !== PolicyType.REMINDER) return null;

  if (!invoiceId && !invoiceIds) return null;

  const headingText = isBulk ?
    `Send one off invoice reminder emails for ${invoiceIds.length} invoices` :
    `Send a one off invoice reminder email for ${invoiceNumber || `this invoice`}`;

  return (
    <div>
      <Heading>
        { headingText }
      </Heading>

      <div className={ `p-4 space-y-2` }>
        <EmailTemplateSelector
          type={ EmailTemplateTypes.REMINDER }
          value={ currentSystemPolicyForm.template_id }
          handleChange={ update }
          invalid={ fieldIsValid(`template_id`, invalids) }
          name={ `template_id` }
          nullable
          label={ `Email template` }
          description={ `Only email templates of the type "Reminder" will be available here.` }
          currentPolicyId={ currentSystemPolicyForm.id }
        />
        <FormRow>
          <Grid cols={ 6 }>
            <GridItem span={ 6 }>
              <Toggle
                label={ `Attach invoice as PDF` }
                name={ `include_invoice_pdf` }
                checked={ currentSystemPolicyForm.include_invoice_pdf }
                onChange={ update }
                description={ `Attach the invoice as a PDF to the email` }
              />
            </GridItem>
          </Grid>
        </FormRow>
        <FormRow>
          <When condition={ showFileAttachments }>
            <Grid cols={ 6 }>
              <GridItem span={ 6 }>
                <Toggle
                  label={ `Include invoice attachments` }
                  name={ `include_attachments` }
                  checked={ currentSystemPolicyForm.include_attachments }
                  onChange={ update }
                  description={ `Includes any other files attached to the invoice in your accounting system.` }
                />
              </GridItem>
            </Grid>
          </When>
        </FormRow>
        <FormRow>
          <Grid cols={ 6 }>
            <GridItem span={ 6 }>
              <Toggle
                label={ `Attach calendar invite` }
                helpLink={ ICS_ARTICLE }
                name={ `attach_ics` }
                checked={ currentSystemPolicyForm.attach_ics }
                onChange={ update }
                description={ `Includes an attachment to add a reminder on the due date to your customers calendar if possible.` }
              />
            </GridItem>
          </Grid>
        </FormRow>
        <FormRow>
          <Grid cols={ 6 }>
            <GridItem span={ 6 }>
              <Toggle
                label={ `Mark as sent in accounting system` }
                checked={ currentSystemPolicyForm.mark_as_sent || false }
                onChange={ update }
                name={ `mark_as_sent` }
              />
            </GridItem>
          </Grid>
        </FormRow>
      </div>
    </div>
  );
}
