import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Default, Switch, Case, When } from 'react-if';
import { ToastContainer } from 'react-toastify';
import { MixpanelEvents } from 'shared';

import { useGetSelectedOrganisation } from '../hooks/useGetSelectedOrganisation';
import { env } from '../../common/lib/env';
import { useUser } from '../hooks/useUser';
import { useOrganisations } from '../hooks/useOrganisations';
import { useActionTableModels } from '../hooks/useActions';
import { useContactParams } from '../hooks/useContacts';
import { useOnboarding } from '../hooks/useOnboarding';
import { classNames } from '../../common/lib/classNames';
import { Strap } from '../../common/Atoms/Strap';
import { Button } from '../../common/Atoms/Button';
import { mixpanelService } from '../../common/lib/mixpanel';

import { DesktopSidebar } from './Navigation/DesktopSidebar';
import { Sidebar } from './Navigation/Sidebar';
import { Navigation } from './Navigation/Navigation';
import { NotificationArea } from './NotificationArea';
import { Spinner } from './Common/Spinner';
import { SubscriptionStrap } from './SubscriptionStrap';
import { RevertLateFeeModal } from './Modals/RevertLateFee';
import { ActionDrawer } from './Actions/ActionDrawer';
import { PolicyDrawer } from './Settings/Policies/PolicyModal';
import { MaintenanceBanner } from './MaintenanceBanner';
import { BookCallModal } from './Modals/BookCall';
import { EmailReportModal } from './Modals/EmailReport';
import { AdhocActionModal } from './Modals/AdhocActionModal/AdhocActionModal';
import { StatementPreview } from './Modals/StatementPreview';

export function Root() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data: organisations, isLoading: organisationLoading } = useOrganisations();
  const { data: user, isLoading: userLoading } = useUser();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentOrg = useGetSelectedOrganisation();
  const { resetParams: resetContactParams } = useContactParams();
  const { resetModels } = useActionTableModels();
  const { isOnboarding } = useOnboarding();

  // Show toast when actions are generated
  // DISABLED TEMP AS LOCKS PER CONTACT
  // useActionCreateStatus();

  const returnToXeroTenant = useMemo(() => {
    return searchParams.get(`returnToXeroTenantId`);
  }, [searchParams]);

  const loginSource = useMemo(() => {
    return searchParams.get(`loginSource`);
  }, [searchParams]);

  useEffect(() => {
    if (currentOrg) {
      // Already have an org, do nothing
      return;
    }

    if (returnToXeroTenant) {
      // came from the xero launcher, default the org
      // tenant id === external org id
      const org = organisations.find(org => org.externalId === returnToXeroTenant);

      if (org) {
        navigate(`/${org.id}`);
      }

      return;
    }

    if (pathname === `/` && organisations.length > 0) {
      // Has loaded orgs, but none selected, so default to first
      navigate(`/${organisations[0].id}`);
    }

  }, [pathname, organisations, returnToXeroTenant, currentOrg]);

  useEffect(() => {
    if (currentOrg) {
      // Reset the states
      resetContactParams();
      resetModels();
      // Todo invoices
    }
  }, [currentOrg?.id]);

  useEffect(() => {
    if (loginSource) {
      if (loginSource === `app_store_button`) {
        mixpanelService.trackMixpanelEvent(MixpanelEvents.LoginFromXeroAppStore);
      }
    }
  }, [loginSource]);

  return (
    <>
      { /* Modals & Drawers  */ }
      <RevertLateFeeModal />
      <ActionDrawer />
      <PolicyDrawer />
      <BookCallModal />
      <EmailReportModal />
      <AdhocActionModal />
      <StatementPreview />
      { /* <WelcomeQuestions /> */ }
      <div>
        <When condition={ !isOnboarding }>
          <Sidebar
            currentOrg={ currentOrg }
            setSidebarOpen={ setSidebarOpen }
            sidebarOpen={ sidebarOpen }
          />
          <DesktopSidebar currentOrg={ currentOrg } />
        </When>
        <div className={
          classNames(
            `flex flex-1 flex-col`,
            // Only leave space for side nav when not onboarding
            !isOnboarding && `md:ml-64`,
          ) }>
          <MaintenanceBanner />
          <SubscriptionStrap />
          <When condition={ user?.adminOverride }>
            <Strap
              type={ `error` }
            >
              <a href={ env.apiUrl + `/signoffas` }>
                <Button color={ `none` }>
                  { `Admin Override Active` }
                </Button>
              </a>
            </Strap>
          </When>
          <Navigation
            setSidebarOpen={ setSidebarOpen }
          />
          { /* Main Content Area */ }
          <main
            id={ `main-content` }
          >

            <ToastContainer
              position={ `bottom-center` }
              autoClose={ 2000 }
              hideProgressBar={ false }
              newestOnTop={ true }
              closeOnClick
              draggable
              pauseOnHover
              theme={ `colored` }
              className={ `toast-container` }
            />
            <Switch>
              <Case condition={ userLoading }>
                <div>
                  <Spinner message={ `Loading your account details...` }
                    center />
                </div>
              </Case>
              <Case condition={ organisationLoading }>
                <div>
                  <Spinner message={ `Loading your connected organisation details...` }
                    center />
                </div>
              </Case>
              <Default>
                <div className={ `py-6` }>
                  <div className={ `mx-auto max-w-8xl px-4 sm:px-6 md:px-8` }>
                    <When condition={ !pathname.startsWith(`/billing`) }>
                      <NotificationArea />
                    </When>
                    { /* Where the route content is loaded */ }
                    <Outlet />
                  </div>
                </div>
              </Default>
            </Switch>
          </main>
        </div>
      </div>
    </>
  );
}
