import { useNavigate } from "react-router";
import { When } from "react-if";

import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { useGetSubscriptionMetadata } from "../../hooks/useGetSubscription";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { classNames } from "../../../common/lib/classNames";

interface IActionLimitCTAProps {
  ctaText?: string;
  afterClick?: () => void;
}

export function ActionLimitCTA({
  ctaText = `Remove Limit`,
  afterClick,
}: IActionLimitCTAProps) {
  const currentOrg = useGetSelectedOrganisation();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const navigate = useNavigate();

  const canStartPlan = !!subscriptionMetadata?.id;

  function onStartPlan() {
    if (canStartPlan) {
      if (afterClick) {
        afterClick();
      }

      navigate(`/billing/subscriptions/${subscriptionMetadata.id}`);
    }
  }

  return (
    <When condition={ currentOrg?.usingFreeActionTrial }>
      { () => (
        <div className={ classNames(
          canStartPlan ? `flex items-end` : ``,
        ) }>
          <Paragraph className={ `mt-4` }>
            <Paragraph
              as={ `span` }
              variant={ `primary` }
            >
              { currentOrg?.freeActionsUsed }
            </Paragraph>
            <Paragraph
              as={ `span` }
              variant={ `secondary` }
            >
              { ` out of ` }
            </Paragraph>
            <Paragraph
              as={ `span` }
              variant={ `primary` }
            >
              { currentOrg?.freeActionLimit }
            </Paragraph>
            <Paragraph
              as={ `span` }
              variant={ `secondary` }
            >
              { ` free actions used.` }
            </Paragraph>
          </Paragraph>

          <When condition={ canStartPlan }>
            <a
              href={ `#` }
              onClick={ e => {
                e.preventDefault();
                onStartPlan();
              } }
            >
              <Paragraph
                variant={ `link` }
                size={ `xs` }
                className={ `ml-1 pb-0.5` }
              >
                { ctaText }
              </Paragraph>
            </a>
          </When>
        </div>
      ) }
    </When>
  );
}
