import { createSlice } from '@reduxjs/toolkit';

export interface IStatementPreviewModal {
  show: boolean;
  html: string | null;
}

const initialState: IStatementPreviewModal = {
  show: false,
  html: null,
};

const statementPreviewModalSlice = createSlice({
  name: `statementPreviewModal`,
  initialState,
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setState } = statementPreviewModalSlice.actions;

export const statementPreviewModalReducer = statementPreviewModalSlice.reducer;
