import React, { useMemo } from 'react';
import { Case, Switch, When } from 'react-if';
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import { classNames }  from '../lib/classNames';
import { LoadingOverlay } from '../../main/components/Molecules/LoadingOverlay';

import { TableRow, getClassnameForStackable } from './TableRow';
import { TableHeading } from './TableHeading';
import { Paragraph } from './Typography/Paragraph';

export interface ITableHeading {
  name: string
  label: string
  stackable?: `sm` | `lg`
  textAlign?: `right` | `left`
  className?: string
  sortCallback?: (name: string, direction: `asc` | `desc`) => void
  sortDirection?: `asc` | `desc`
}

interface ITableProps {
  headingTitle?: string
  headingDescription?: string | React.ReactNode
  headingButton?: React.ReactNode
  headings: ITableHeading[]
  rows: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }[]
  onRowClick?: (row) => void
  emptyMessage?: string
  className?: string
  loading?: boolean
  loadingMessage?: string
  currentSortColumn?: string
  onToggleAll?: () => void
  allChecked?: boolean
  selectedIds?: Array<number | string>;
  onSelectedChange?: (id: number | string) => void;
  checkboxRef?: React.RefObject<HTMLInputElement>;
}

export function Table({
  headingTitle,
  headingDescription,
  headingButton,
  headings,
  rows,
  onRowClick,
  emptyMessage,
  className,
  loading,
  currentSortColumn,
  loadingMessage,
  onToggleAll,
  allChecked,
  selectedIds,
  onSelectedChange,
  checkboxRef,
}: ITableProps) {
  const shouldShowCheckboxes = !!onToggleAll && !!selectedIds &&!!onSelectedChange;

  const parsedHeadings = useMemo(() => {
    return headings.map((r, i) => {
      return (
        <th
          key= { i }
          className={
            classNames(
              getClassnameForStackable(r.stackable).heading,
              (i === 0 && shouldShowCheckboxes) ? `text-left sm:pl-0 pl-4 pr-3` : `text-center`,
              i === headings.length - 1 ? `relative pl-3 pr-4 sm:pr-0` : ``,
              (i !== 0 && i !== headings.length - 1) && `px-3`,
              `text-sm py-3.5 font-semibold text-gray-900`,
              r.textAlign === `left` && `text-left`,
              r.className,
            )
          }
        >
          <div className={
            classNames(
              `flex flex-row`,
              i === 0 ? `justify-start` : `justify-center`,
              r.textAlign === `left` && `justify-start`,
            )
          }>
            { r.label }

            <When condition={ !!r.sortCallback }>
              <button
                className={ `h-5 w-5 ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300` }
                onClick={ () => r.sortCallback(r.name, currentSortColumn === `asc` ? `desc` : `asc`) }
              >
                <Switch>
                  <Case condition={ r.name !== currentSortColumn }>
                    <ChevronUpDownIcon className={ `h-5 w-5` }
                      aria-hidden={ `true` } />
                  </Case>
                  <Case condition={ r.sortDirection === `desc` }>
                    <ChevronDownIcon className={ `h-5 w-5` }
                      aria-hidden={ `true` } />
                  </Case>
                  <Case condition={ r.sortDirection === `asc` }>
                    <ChevronUpIcon className={ `h-5 w-5` }
                      aria-hidden={ `true` } />
                  </Case>
                </Switch>
              </button>
            </When>
          </div>
        </th>
      );
    });
  }, [headings, shouldShowCheckboxes]);

  if (rows.length === 0 && emptyMessage && !loading) {
    return (
      <div className={ `flex flex-row justify-center items-center` }>
        <Paragraph
          className={ `my-12` }
          variant={ `secondary` }
        >
          { emptyMessage }
        </Paragraph>
      </div>
    );
  }

  return (
    <LoadingOverlay
      loading={ loading }
      message={ loadingMessage }
      className={ classNames(
        `min-h-[150px]`,
        className,
      ) }
    >
      <TableHeading
        title={ headingTitle }
        description={ headingDescription }
        button={ headingButton }
      />
      <div className={
        classNames(
          `-mx-4 mt-2 sm:-mx-0`,
          className,
        ) }>

        <table className={ `min-w-full divide-y divide-gray-300` }>
          <thead>
            <tr>
              <When condition={ shouldShowCheckboxes }>
                { () => (
                  <th scope={ `col` }
                    className={ `relative w-12 px-6 sm:w-16 sm:px-8` }>
                    <input
                      type={ `checkbox` }
                      className={ `absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-500 sm:left-6` }
                      ref={ checkboxRef }
                      checked={ allChecked }
                      onChange={ onToggleAll }
                    />
                  </th>
                ) }
              </When>
              {
                parsedHeadings
              }
            </tr>
          </thead>
          <tbody className={
            classNames(
              `divide-y divide-gray-200 bg-white`,
            ) }>
            { rows.map((row, i) => (
              <TableRow
                row={ row }
                headings={ headings }
                key={ i }
                onRowClick={ onRowClick }
                shouldShowCheckboxes={ shouldShowCheckboxes }
                selectedIds={ selectedIds }
                onSelectedChange={ onSelectedChange }
              />
            )) }
          </tbody>
        </table>
      </div>
    </LoadingOverlay>
  );
}
