import { When } from "react-if";

import { Button } from "../../../common/Atoms/Button";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { useBulkCancelEscalationsMutation, useBulkCloseEscalationsMutation } from "../../services/api/escalationApi/escalation";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

const MAX_BATCH = 50;

interface ISelectedEscalationOptionsProps {
  selectedEscalationIds: number[];
  setSelectEscalationIds: (ids: number[]) => void;
}

export function SelectedEscalationOptions({
  selectedEscalationIds,
  setSelectEscalationIds,
}: ISelectedEscalationOptionsProps) {
  const currentOrg = useGetSelectedOrganisation();
  const [bulkCloseApi, { isLoading: bulkCloseLoading }] = useBulkCloseEscalationsMutation();
  const [bulkCancelApi, { isLoading: bulkCancelLoading }] = useBulkCancelEscalationsMutation();

  const escalationCountText = `${selectedEscalationIds.length} escalation${selectedEscalationIds.length === 1 ? `` : `s`}`;

  async function onClick(type: `close` | `cancel`) {
    if (currentOrg?.id) {
      if (type === `close`) {
        bulkCloseApi({
          organisationId: currentOrg.id,
          escalationIds: selectedEscalationIds,
        });
      }

      if (type === `cancel`) {
        bulkCancelApi({
          organisationId: currentOrg.id,
          escalationIds: selectedEscalationIds,
        });
      }
    }

    // Then clear
    setSelectEscalationIds([]);
  }

  return (
    <div className={ `` }>
      <div className={ `mt-3 space-x-3` }>
        <Button
          onClick={ () => onClick(`close`) }
          loading={ bulkCloseLoading }
        >
          { `Close ${escalationCountText}` }
        </Button>

        <Button
          onClick={ () => onClick(`cancel`) }
          loading={ bulkCancelLoading }
        >
          { `Cancel ${escalationCountText}` }
        </Button>
      </div>

      <When condition={ selectedEscalationIds.length > MAX_BATCH }>
        <Paragraph
          variant={ `warning` }
        >
          { `You can only close or cancel a maximum of ${MAX_BATCH} escalations at a time.` }
        </Paragraph>
      </When>
    </div>
  );
}
