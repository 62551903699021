import { FilterColumnsArgs, GetColumnForNewFilterArgs, GRID_CHECKBOX_SELECTION_FIELD, GRID_TREE_DATA_GROUPING_FIELD, GridColDef } from "@mui/x-data-grid-pro";

// Common utils for data grid
const hiddenFields = [
  GRID_TREE_DATA_GROUPING_FIELD,
  GRID_CHECKBOX_SELECTION_FIELD,
];

export const getTogglableColumns = (columns: GridColDef[], neverShowColumns = []) => {
  return columns
    .filter(column => !hiddenFields.includes(column.field))
    .filter(column => !neverShowColumns.includes(column.field))
    .map(column => column.field);
};

export const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs, dontShow?: string[]) => {
  // remove already filtered fields from list of columns
  const filteredFields = currentFilters?.map(item => item.field);

  return columns
    .filter(
      colDef =>
        colDef.filterable &&
        (colDef.field === field || !filteredFields.includes(colDef.field)) &&
        !dontShow?.includes(colDef.field),
    )
    .map(column => column.field);
};

export const getColumnForNewFilter = ({
  currentFilters,
  columns,
}: GetColumnForNewFilterArgs) => {
  const filteredFields = currentFilters?.map(({ field }) => field);
  const columnForNewFilter = columns
    .filter(
      colDef => colDef.filterable && !filteredFields.includes(colDef.field),
    )
    .find(colDef => colDef.filterOperators?.length);

  return columnForNewFilter?.field ?? null;
};
