// New Hook using the built in MUI-X State
import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { IInvoiceParams } from "shared";
import { useParams } from "react-router";

import { reset, resetFilter, resetPagination, resetSort, setFilterModel, setSortModel, setPaginationModel } from "../slices/invoiceTableModels";
import { RootState } from "../store";
import { useFetchInvoicesQuery } from "../services/api/invoiceApi/invoice";
import { parseCurrencyFilter } from "../lib/currencyHelpers";

export enum InvoiceTableFields {
  invoiceNumber = `invoiceNumber`,
  invoiceReference = `invoiceReference`,
  invoiceStatus = `invoiceStatus`,
  contactName = `contactName`,
  invoiceIssueDate = `invoiceIssueDate`,
  invoiceDueDate = `invoiceDueDate`,
  invoiceTotal = `invoiceTotal`,
  invoiceOutstanding = `invoiceOutstanding`,
  frozen = `frozen`,
  groupId = `groupId`,
  isLateFee = `IsLateFee`,
  daysOverdue = `daysOverdue`,
}

export enum InvoiceTableHiddenFields {}

const sortColumnMap: Record<InvoiceTableFields, string> = {
  [InvoiceTableFields.invoiceNumber]: `xero_number`,
  [InvoiceTableFields.invoiceReference]: `xero_reference`,
  [InvoiceTableFields.invoiceStatus]: `xero_status`,
  [InvoiceTableFields.contactName]: `xero_contact_name`,
  [InvoiceTableFields.invoiceIssueDate]: `issue_date_string`,
  [InvoiceTableFields.invoiceDueDate]: `due_date_string`,
  [InvoiceTableFields.invoiceTotal]: `xero_amount_total`,
  [InvoiceTableFields.invoiceOutstanding]: `xero_amount_due`,
  [InvoiceTableFields.frozen]: `skip`,
  [InvoiceTableFields.groupId]: ``,
  [InvoiceTableFields.isLateFee]: `created_by_us`,
  [InvoiceTableFields.daysOverdue]: `days_overdue`,
};

export function useInvoices() {
  const invoiceTableParams = useInvoiceTableModels();
  const { organisationId } = useParams();

  const {
    filterModel,
    sortModel,
    paginationModel,
  } = invoiceTableParams;

  // Extract and build the params object
  const page = paginationModel.page + 1; // MUI is 0-based
  const limit = paginationModel.pageSize;

  const status = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceStatus)?.value || null;
  const frozen = filterModel.items.find(item => item.field === InvoiceTableFields.frozen)?.value; // Dont default to allow false to pass in
  const groupId = filterModel.items.find(item => item.field === InvoiceTableFields.groupId)?.value || null;
  const contactId = filterModel.items.find(item => item.field === `contactId`)?.value || null;
  const reference = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceReference)?.value || null;
  const number = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceNumber)?.value || null;
  const contactName = filterModel.items.find(item => item.field === InvoiceTableFields.contactName)?.value || null;
  const minDueDate = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceDueDate && item.operator === `onOrAfter`)?.value || null;
  const maxDueDate = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceDueDate && item.operator === `onOrBefore`)?.value || null;
  const minIssueDate = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceIssueDate && item.operator === `onOrAfter`)?.value || null;
  const maxIssueDate = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceIssueDate && item.operator === `onOrBefore`)?.value || null;
  const totalAmount = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceTotal)?.value;
  const totalAmountOperator = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceTotal)?.operator || null;
  const dueAmount = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceOutstanding)?.value;
  const dueAmountOperator = filterModel.items.find(item => item.field === InvoiceTableFields.invoiceOutstanding)?.operator || null;
  const isLateFee = filterModel.items.find(item => item.field === InvoiceTableFields.isLateFee)?.value; // Dont default to allow false to pass in
  const daysOverdue = filterModel.items.find(item => item.field === `daysOverdue`)?.value;
  const daysOverdueOperator = filterModel.items.find(item => item.field === `daysOverdue`)?.operator || null;
  const quickSearchStr = filterModel.quickFilterValues?.[0] || null;

  // Sort
  // Todo - Multi sort?
  let sortColumn = sortModel[0]?.field || null;
  const sortOrder = sortModel[0]?.sort || null;

  if (sortColumn && sortColumn in sortColumnMap) {
    // Map back to DB values
    sortColumn = sortColumnMap[sortColumn as InvoiceTableFields];
  }

  // Create the params object
  const searchParams: IInvoiceParams = {
    page,
    limit,
    invoiceTextSearch: quickSearchStr,
    xeroStatus: status,
    frozen,
    groupId,
    contactId,
    reference,
    number,
    contactName,
    dueDateMinDate: minDueDate ? minDueDate.split(`T`)[0] : null,
    dueDateMaxDate: maxDueDate ? maxDueDate.split(`T`)[0] : null,
    issueDateMinDate: minIssueDate ? minIssueDate.split(`T`)[0] : null,
    issueDateMaxDate: maxIssueDate ? maxIssueDate.split(`T`)[0] : null,
    totalAmount: parseCurrencyFilter(totalAmount),
    totalAmountOperator: totalAmountOperator as `>=` | `<=` | `=` | null,
    dueAmount: parseCurrencyFilter(dueAmount),
    dueAmountOperator: dueAmountOperator as `>=` | `<=` | `=` | null,
    sortColumn,
    sortType: sortOrder,
    isLateFee,
    daysOverdue,
    daysOverdueOperator: daysOverdueOperator as `>=` | `<=` | `=`,
  };

  const result = useFetchInvoicesQuery({
    ...searchParams,
    organisationId,
  }, {
    skip: !organisationId,
    pollingInterval: 1000 * 15, // 15 seconds
    skipPollingIfUnfocused: true,
    refetchOnMountOrArgChange: true,
  });

  return result;
}

export function useInvoiceTableModels() {
  const dispatch = useDispatch();
  const invoiceTableModels = useSelector((s: RootState) => s.invoiceTabelModels);

  return {
    // State
    filterModel: invoiceTableModels.filterModel,
    sortModel: invoiceTableModels.sortModel,
    paginationModel: invoiceTableModels.paginationModel,

    // Actions
    setFilterModel: (model: GridFilterModel) => dispatch(setFilterModel(model)),
    setSortModel: (model: GridSortModel) => dispatch(setSortModel(model)),
    setPaginationModel: (model: GridPaginationModel) => dispatch(setPaginationModel(model)),
    resetModels: () => dispatch(reset()),
    resetSort: () => dispatch(resetSort()),
    resetFilter: () => dispatch(resetFilter()),
    resetPagination: () => dispatch(resetPagination()),
  };
}
