import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { HashtagIcon } from '@heroicons/react/20/solid';
import { PercentBadgeIcon } from '@heroicons/react/24/outline';

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { useFetchInvoiceHistoryMetricsQuery } from '../../../services/api/organisationApi/organisation';
import { Select } from '../../../../common/Atoms/Select';
import { LoadingOverlay } from '../../Molecules/LoadingOverlay';
import { Button } from '../../../../common/Atoms/Button';

export const paidOnTimeColor = `#2ADACE`;

export const overdueColor = `#467691`;

export const paidLateColor = `#FF5E5C`;

export const outstandingColor = `#34454E`;

const periodOptions = [
  { value: 3, label: `3 months` },
  { value: 6, label: `6 months` },
  { value: 9, label: `9 months` },
  { value: 12, label: `12 months` },
];

export function InvoiceHistory() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const [period, setPeriod] = useState(6);
  // New state to toggle between 'totals' and 'percentage'
  const [displayMode, setDisplayMode] = useState<`totals` | `percentage`>(`totals`);

  // Only load this dataset to keep queries light
  const { data: metricsResponse, isLoading } = useFetchInvoiceHistoryMetricsQuery(
    { orgId: organisationId, period },
    {
      skip: !organisationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const chartSetting = {
    yAxis: [
      {
        label: displayMode === `totals` ? `Number of Invoices` : `Percentage`,
      },
    ],
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: `translate(-5px, 0)`,
      },
    },
  };

  // Original data in totals
  const data = useMemo(() => {
    if (!metricsResponse?.history)
      return [
        {
          paidLate: 0,
          outstanding: 0,
          overdue: 0,
          paid: 0,
          label: `Jan`,
        },
      ];

    return metricsResponse.history.map(item => ({
      ...item,
      paidLate: item.paidLate || 0,
    }));
  }, [metricsResponse]);

  // Convert totals to percentages when needed
  const displayData = useMemo(() => {
    if (displayMode === `totals`) {
      return data;
    }
    else {
      return data.map(item => {
        const total = item.overdue + item.paid + item.paidLate + item.outstanding;

        return {
          ...item,
          overdue: total > 0 ? (item.overdue / total) * 100 : 0,
          paid: total > 0 ? (item.paid / total) * 100 : 0,
          paidLate: total > 0 ? (item.paidLate / total) * 100 : 0,
          outstanding: total > 0 ? (item.outstanding / total) * 100 : 0,
        };
      });
    }
  }, [data, displayMode]);

  // Use the proper formatter based on display mode
  const seriesData = useMemo(() => {
    const hasPaidLate = data.some(item => item.paidLate > 0);
    const valueFormatter =
      displayMode === `totals`
        ? (value: number | null) => `${value}`
        : (value: number | null) => `${value?.toFixed(0)}%`;

    return [
      { dataKey: `paidLate`, label: `Paid Late`, valueFormatter, color: paidLateColor },
      { dataKey: `outstanding`, label: `Outstanding`, valueFormatter, color: outstandingColor },
      { dataKey: `overdue`, label: `Overdue`, valueFormatter, color: overdueColor },
      { dataKey: `paid`, label: `Paid`, valueFormatter, color: paidOnTimeColor },
    ].filter(item => item.dataKey !== `paidLate` || hasPaidLate);
  }, [data, displayMode]);

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card className={ `min-h-fit` }>
        <MultiButtonCardHeader
          title={ `Invoice History` }
          cta={
            <div className={ `flex space-x-2` }>
              <div className={ `w-32` }>
                <Select
                  options={ periodOptions }
                  onChange={ value => setPeriod(value.value) }
                  selected={ period }
                />
              </div>
              <div>
                <Button
                  className={ `rounded-tr-none rounded-br-none rounded-tl-md rounded-bl-md focus:ring-0` }
                  color={ displayMode === `totals` ? `lateGreen` : `lateGreenOutline` }
                  onClick={ () => setDisplayMode(`totals`) }
                >
                  <HashtagIcon className={ `w-5 h-5` } />
                </Button>

                <Button
                  className={ `rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md focus:ring-0` }
                  color={ displayMode === `percentage` ? `lateGreen` : `lateGreenOutline` }
                  onClick={ () => setDisplayMode(`percentage`) }
                >
                  <PercentBadgeIcon className={ `w-5 h-5` } />
                </Button>
              </div>
            </div>
          }
        />
        <div className={ `min-h-[470px] h-[400px] min-w-[400px]` }>
          <BarChart
            dataset={ displayData }
            xAxis={ [{ scaleType: `band`, dataKey: `label`, label: `Due Date` }] }
            series={ seriesData }
            { ...chartSetting }
          />
        </div>
      </Card>
    </LoadingOverlay>
  );
}
