import mixpanel, { Config } from 'mixpanel-browser';
import { IMixpanelEventProperties, MixpanelEvents } from 'shared';

import { env } from './env';

// Used in prod, deployed in GCP Cloud Run
const PROXY_URL = `https://analytics.paidnice.com`;

class Mixpanel {
  client: typeof mixpanel
  constructor() {
    if (env.mixpanelToken && !env.isCypress) {
      console.log(`M Init`);

      this.client = mixpanel;

      const config: Partial<Config> = {
        debug: env.isDev,
        track_pageview: false,
        persistence: `cookie`,
        cross_subdomain_cookie: true,
        ignore_dnt: true,
      };

      if (env.isProd) {
        config.api_host = PROXY_URL;
      }

      this.client.init(env.mixpanelToken, config);
    }
    else {
      console.log(`M Not Init`);
    }
  }

  identify(userId: string) {
    if (this.client) {
      console.log(`m ident`);
      this.client.identify(userId);
    }
  }

  pageLoad() {
    if (this.client) {
      this.client.track(MixpanelEvents.AppLoaded);
    }
  }

  portalLoad() {
    if (this.client) {
      this.client.track(MixpanelEvents.PortalLoaded);
    }
  }

  demoCallClicked() {
    if (this.client) {
      this.client.track(MixpanelEvents.InAppDemoCallClicked);
    }
  }

  logout() {
    if (this.client) {
      this.client.reset();
    }
  }

  // PORTAL
  portalPageLoad(resource: `invoice` | `contact` | `quote`) {
    if (this.client) {
      this.client.track(MixpanelEvents.PortalPageLoaded, {
        resource,
      });
    }
  }

  portalInvoiceNotFound() {
    if (this.client) {
      this.client.track(MixpanelEvents.PortalInvoiceNotFound);
    }
  }

  portalInvoiceXeroClick() {
    if (this.client) {
      this.client.track(MixpanelEvents.PortalInvoiceXeroClicked);
    }
  }

  // Got bored of writing repeated functions!
  trackMixpanelEvent(event: MixpanelEvents, properties?: IMixpanelEventProperties) {
    if (this.client) {
      this.client.track(event, properties);
    }
  }
}

export const mixpanelService = new Mixpanel();
