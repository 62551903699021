import React, { CSSProperties, forwardRef } from 'react';
import { Tooltip } from '@mui/material';

import { classNames }  from '../lib/classNames';

import { SmallSpinner } from './SmallSpinner';
import { Transition } from './Transition';

const colors = {
  'lateGreen': `bg-lateGreen-600 hover:bg-lateGreen-700 focus:ring-lateGreen-500 text-white`,
  'lateGreenOutline': `bg-transparent border-lateGreen-700 hover:bg-lateGreen-700 focus:ring-lateGreen-500 text-lateGreen-600 hover:text-white`,
  'orangeOutline': `bg-transparent border-orange-500 hover:bg-orange-500 focus:ring-orange-500 text-orange-500 hover:text-white`,
  'red': `bg-red-800 hover:bg-red-900 focus:ring-red-800 text-white`,
  'gray': `bg-gray-200 hover:bg-gray-400 focus:ring-gray-500 text-lateGreen-800`,
  'green': `bg-green-500 hover:bg-green-700 focus:ring-green-500 text-white`,
  'orange': `bg-orange-500 hover:bg-orange-700 focus:ring-orange-500 text-white`,
  'purple': `bg-purple-700 hover:bg-purple-800 focus:ring-purple-700 text-white`,
};

const classes = {
  'sm': `inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 max-h-6`,
  'md': `inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2`,
  'lg': `inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2`,
  'xl': `inline-flex items-center px-5 py-2 border border-transparent text-base font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2`,
  '2xl': `inline-flex items-center px-6 py-3 border border-transparent text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 `,
  '3xl': `inline-flex items-center px-8 py-6 border border-transparent text-lg font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 `,
};

export type ButtonColorOpts = `lateGreen` | `red` | `gray` | `none` | `lateRed` | `green` | `orange` | `purple` | `lateGreenOutline` | `orangeOutline`;

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  loadingText?: string
  active?: boolean
  tooltip?: string
  icon?: React.ReactNode
  disabled?: boolean
  loading?: boolean
  className?: string
  color?: ButtonColorOpts
  children?: React.ReactNode
  size?: `sm` | `md` | `lg` | `xl` | `2xl` | `3xl`
  width?: string
  isIcon?: boolean
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(({
  loadingText,
  active,
  tooltip,
  icon,
  disabled,
  loading,
  className,
  color = `lateGreen`,
  children,
  size = `md`,
  width,
  isIcon,
  ...props
}, ref) => {
  let display = children;

  if (loading && loadingText) {
    display = loadingText;
  }

  const style: CSSProperties = {};

  if (width) {
    style.width = width;
  }

  const button = (
    <span>
      <button
        ref={ ref }
        type={ `button` }
        style={ style }
        className={
          classNames(
            `transition-all ease-in duration-300 max-h-[32px] whitespace-nowrap text-center `,
            classes[size],
            colors[color],
            className ? (className.includes(`rounded`) ? className : classNames(className, `rounded-md`)) : `rounded-md`,
            disabled && `opacity-50 cursor-not-allowed`,
            active && `bg-lateGreen-700`,
            isIcon && `p-0`,
          )
        }
        { ...props }
        disabled={ disabled }
      >
        <Transition show={ !!loading }>
          <div>
            <SmallSpinner
              className={ `mr-2` }
            />
          </div>
        </Transition>
        { display }
        <Transition show={ !!icon }>
          <div>
            { icon }
          </div>
        </Transition>
      </button>
    </span>
  );

  return (
    <Tooltip title={ tooltip }>
      { button }
    </Tooltip>
  );
});

Button.displayName = `Button`;

export { Button };
