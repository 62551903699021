import { createSlice } from '@reduxjs/toolkit';
import { IAdhocActionResponse, IAdhocActionResponseNew, IPolicy, IPolicyPreviewResponse, PolicyType } from 'shared';

export interface IAdhocActionModal {
  type?: PolicyType;
  show: boolean;
  currentPolicy?: IPolicy;
  currentPolicyForm?: IPolicy;
  contactId?: number;
  contactName?: string;
  invoiceId?: string;
  invoiceNumber?: string;
  result?: IAdhocActionResponse;
  /** The new result for bulk, but goal is to replace the main result to keep it generic */
  resultNew?: IAdhocActionResponseNew;
  preview?: IPolicyPreviewResponse;
  view: `preview` | `result`;
  previewLoading?: boolean;
  applyLoading?: boolean;
  updatePolicyLoading?: boolean;
  isBulk?: boolean;
  invoiceIds?: string[];
  contactIds?: number[];
}

export const initialState: IAdhocActionModal = {
  type: undefined,
  show: false,
  contactId: undefined,
  contactName: undefined,
  invoiceId: undefined,
  invoiceNumber: undefined,
  currentPolicyForm: undefined,
  currentPolicy: undefined,
  result: undefined,
  resultNew: undefined,
  preview: undefined,
  view: `result`,
  previewLoading: false,
  applyLoading: false,
  updatePolicyLoading: false,
  isBulk: false,
  invoiceIds: undefined,
  contactIds: undefined,
};

const adhocActionModalSlice = createSlice({
  name: `adhocActionModal`,
  initialState,
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { setState, reset } = adhocActionModalSlice.actions;

export const adhocActionModalReducer = adhocActionModalSlice.reducer;
