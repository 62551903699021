import { DefaultizedPieValueType, PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { Fragment, useMemo } from "react";
import { SendgridMessageEventType } from "shared";
import { When } from "react-if";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router";
import { DateTime } from "luxon";
import { Tooltip } from "@mui/material";

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useFetchEmailMetricsQuery } from "../../../services/api/organisationApi/organisation";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { Badge } from "../../../../common/Atoms/Badge";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { HelpIcon } from "../../Molecules/HelpIcon";
import { useEmailModal } from "../../../hooks/useEmailModal";

import { outstandingColor, paidLateColor, paidOnTimeColor } from "./InvoiceHistory";

const dataMap = {
  [SendgridMessageEventType.Open]: {
    label: `Opened`,
    color: paidOnTimeColor,
  },
  [SendgridMessageEventType.Bounce]: {
    label: `Bounced`,
    color: paidLateColor,
  },
  [SendgridMessageEventType.Click]: {
    label: `Clicked`,
    color: paidOnTimeColor,
  },
  [SendgridMessageEventType.Deferred]: {
    label: `Deferred`,
    color: paidLateColor,
  },
  [SendgridMessageEventType.Dropped]: {
    label: `Dropped`,
    color: paidLateColor,
  },
  [SendgridMessageEventType.SpamReport]: {
    label: `Reported as Spam`,
    color: paidLateColor,
  },
  [SendgridMessageEventType.Processed]: {
    label: `Processed`,
    color: outstandingColor,
  },
  [SendgridMessageEventType.Delivered]: {
    label: `Delivered`,
    color: paidOnTimeColor,
  },
};

export function EmailDeliveryWidget() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { open } = useEmailModal();

  const { data: emailMetrics, isLoading } = useFetchEmailMetricsQuery({ orgId: organisationId }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
  });

  const data = useMemo(() => {
    if (!emailMetrics?.totals) return [];

    return Object.keys(emailMetrics.totals)
      .filter(key => (key in dataMap))
      .map(key => {
        return {
          ...dataMap[key],
          value: emailMetrics.totals[key],
        };
      });

  }, [emailMetrics, isLoading]);

  const TOTAL = data.map(item => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;

    return `${(percent * 100).toFixed(0)}%`;
  };

  function onReportClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    open();
  }

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card className={ `min-h-full min-w-full` }>
        <MultiButtonCardHeader
          title={ `Email Delivery` }
          description={ `Quick insights into any recent email delivery issues` }
        />
        <div className={ `min-w-[400px] mt-4` } >
          <When condition={ data.length === 0 }>
            <div className={ `min-h-[200px] h-full flex flex-col items-center justify-center text-center` }>
              <EnvelopeIcon className={ `w-12 h-12 mx-auto text-gray-300` } />
              <Paragraph variant={ `secondary` }>
                { `Email delivery data will appear here once you start sending emails.` }
              </Paragraph>
            </div>
          </When>
          <div className={ `h-[300px]` }>
            <PieChart
              series={ [{
                data,
                paddingAngle: 5,
                innerRadius: 20,
                outerRadius: 90,
                arcLabel: getArcLabel,
                arcLabelMinAngle: 30,
              }] }
              sx={ {
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: `white`,
                  fontSize: 14,
                  fontWeight: 600,
                },
              } }
              slotProps={
                {
                  legend: {
                    direction: `column`,
                    position: {
                      vertical: `middle`,
                      horizontal: `right`,
                    },
                  },
                }
              }
            />
          </div>
          <When condition={ emailMetrics?.recentFailures.length > 0 }>
            { () => (
              <CardContent>
                <Heading
                  variant={ `secondary` }
                  className={ `mb-1` }
                >
                  { `Recent Issues` }
                </Heading>
                <div className={ `grid grid-cols-3 gap-y-2` }>
                  { emailMetrics.recentFailures.map((row, idx) => {
                    return (
                      <Fragment
                        key={ `email-fail-${idx}` }
                      >
                        { /* Name link */ }
                        { /* <WrappedLink
                        to={ `/contacts/${contact.id}` }
                      > */ }
                        <Tooltip
                          title={ DateTime.fromJSDate(new Date(row.date)).toFormat(`yyyy-MM-dd HH:mm:ss`) }
                        >
                          <span>
                            <Paragraph
                              variant={ `secondary` }
                              as={ `span` }
                            >
                              { DateTime.fromJSDate(new Date(row.date)).toRelative() }
                            </Paragraph>
                          </span>
                        </Tooltip>
                        <Paragraph
                          variant={ `secondary` }
                          className={ `truncate flex` }
                        >
                          { row.email }
                          <When condition={ row.reason }>
                            <HelpIcon tooltip={ row.reason } />
                          </When>
                        </Paragraph>
                        { /* </WrappedLink> */ }

                        { /* Age */ }
                        <div className={ `max-w-fit place-self-end` }>

                          <Badge
                            color={ `red` }
                          >
                            { dataMap[row.eventType].label }
                          </Badge>

                        </div>

                      </Fragment>
                    );
                  })
                  }
                </div>

                { /* Link to view full failure report */ }
                <div className={ `flex justify-center mt-4` }>
                  <a
                    href={ `#` }
                    onClick={ onReportClick }
                  >
                    <Paragraph
                      variant={ `link` }
                      className={ `text-xs` }
                    >
                      { `View Full Report` }
                    </Paragraph>
                  </a>
                </div>
              </CardContent>
            ) }

          </When>
        </div>
      </Card>
    </LoadingOverlay>
  );
}
