import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import { When } from "react-if";
import { MixpanelEvents } from "shared";

import { Card } from "../../../../../common/Atoms/Card";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { useContact } from "../../../../hooks/useContact";
import { env } from "../../../../../common/lib/env";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Badge } from "../../../../../common/Atoms/Badge";
import { WrappedLink } from "../../../WrappedLink";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { useAdhocActionModal } from "../../../../hooks/useAdhocActionModal";
import { mixpanelService } from "../../../../../common/lib/mixpanel";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { useToggleContactFreezeMutation } from "../../../../services/api/contactsApi/contact";
import { Strap } from "../../../../../common/Atoms/Strap";
import { classNames } from "../../../../../common/lib/classNames";
import { Button } from "../../../../../common/Atoms/Button";

import { ContactStats } from "./ContactStats";
import { TagSelector } from "./TagSelector";

export function ContactHeading() {
  const { data: contact } = useContact();
  const currentOrg = useGetSelectedOrganisation();
  const { openSendStatement } = useAdhocActionModal();
  const [toggleFreeze, { isLoading: toggleFreezeLoading }] = useToggleContactFreezeMutation();

  function onSendStatement() {
    openSendStatement(contact.id, contact.name);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.ContactPageSendStatementNowClick);
  }

  function onToggleFreeze() {
    toggleFreeze({
      contactId: contact.id,
      organisationId: currentOrg?.id,
    });
  }

  if (!contact) {
    return null;
  }

  // New UI goes to new group URL
  const groupLink = `/groups/${contact.extraData.group.id}`;

  return (
    <>

      <When condition={ contact.frozen }>
        <Strap
          type={ `frozen` }
          className={ `sm:rounded-t-lg` }
        >
          { `This contact is frozen. No policies will apply to it` }
        </Strap>
      </When>
      <Card
        className={
          classNames(
            contact.frozen && `rounded-t-none`,
          )
        }
      >
        <div className={ `flex justify-between items-start` }>
          <div className={ `flex space-x-1` }>
            <div>
              <When condition={ contact?.parentContactId }>
                <WrappedLink
                  to={ `/contacts/${contact.parentContactId}` }
                  className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
                >
                  { `${ contact?.parentContactName } ↰` }
                </WrappedLink>
              </When>
              <div className={ `flex` }>
                <Heading>
                  { contact.name }
                </Heading>
                <a
                  href={ `${env.portalWebUrl}/c/${contact.hash}` }
                  target={ `_blank` }
                  rel={ `noopener noreferrer` }
                  className={ `ml-1` }
                >
                  <Tooltip title={ `View customer portal` }>
                    <ArrowTopRightOnSquareIcon className={ `w-5 h-5 text-blue-400` } />
                  </Tooltip>
                </a>
              </div>
              <When condition={ contact?.childContacts && contact?.childContacts.length > 0 }>
                { contact.childContacts.map(child => (
                  <p key={ child.id }>
                    <WrappedLink
                      key={ child.id }
                      to={ `/contacts/${child.id}` }
                      className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
                    >
                      { `↳ ${ child.name }` }
                    </WrappedLink>
                  </p>
                )) }
              </When>
              <Paragraph
                variant={ `help` }>
                { contact?.externalAccountNumber }
              </Paragraph>

              { /* Tags */ }
              <div className={ `mt-2` }>
                <TagSelector />
              </div>
            </div>

            <WrappedLink
              to={ groupLink }
            >
              <Tooltip title={ `View group` }>
                <Badge
                >
                  { contact.extraData?.group?.title }
                </Badge>
              </Tooltip>
            </WrappedLink>
          </div>
          { /* Quick Actions */ }
          <div>
            <Toggle
              checked={ contact.frozen || false }
              onChange={ onToggleFreeze }
              name={ `frozen` }
              label={ `Freeze Contact` }
              className={ `mb-2` }
              description={ `Prevent any policies applying to this contact.` }
              loading={ toggleFreezeLoading }
            />
            <ul className={ `text-right` }>
              { /*  Send a statement */ }
              <li>
                <a
                  href={ `#` }
                  onClick={ e => {
                    e.preventDefault();
                    onSendStatement();
                  } }
                >
                  <Button>
                    { `Send Statement` }
                  </Button>
                </a>
              </li>

              <When condition={ currentOrg?.accountingSystemType === `XERO` }>
                <li>
                  <a
                    href={ `https://go.xero.com/organisationlogin/default.aspx?shortcode=${currentOrg.shortcode}&redirecturl=/Contacts/View/${contact.externalId}` }
                    target={ `_blank` }
                    rel={ `noopener noreferrer` }
                  >
                    <Paragraph
                      variant={ `link` }
                    >
                      { `View in Xero` }
                    </Paragraph>
                  </a>
                </li>
              </When>
            </ul>
          </div>
        </div>
        <ContactStats />
      </Card>
    </>
  );
}
