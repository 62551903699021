import { PolicyType, IInvalidField, IPolicy, policyConfigV2 } from 'shared';
import { Case, Switch, When } from 'react-if';
import { useSelector } from 'react-redux';
import { AdjustmentsVerticalIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { BeakerIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';

import { Button } from '../../../../common/Atoms/Button';
import { Notification } from '../../../../common/Atoms/Notification';
import { Form } from '../../Molecules/Form';
import { usePolicySanityChecks } from '../../../hooks/usePolicySanityChecks';
import { RootState } from '../../../store';
import { LoadingOverlay } from '../../Molecules/LoadingOverlay';
import { ITab, Tabs } from '../../../../common/Atoms/Tabs';
import { Card } from '../../../../common/Atoms/Card';
import { useAdvancedPolicyFields } from '../../../hooks/useAdvancedPolicyFields';
import { classNames } from '../../../../common/lib/classNames';

import { PolicyLateFee } from './PolicyTypes/PolicyLateFee';
import { PolicyPromptPaymentDiscount } from './PolicyTypes/PolicyPromptPaymentDiscount';
import { PolicyReminder } from './PolicyTypes/PolicyReminder';
import { PolicySms } from './PolicyTypes/PolicySms';
import { GroupSelector } from './PolicyForm/GroupSelector';
import { AdvancedFields } from './PolicyForm/AdvancedFields/AdvancedFields';
import { PolicyIntervalLateFee } from './PolicyTypes/PolicyStatementLateFee';
import { PolicyStatement } from './PolicyTypes/PolicyStatement';
import { PolicyEscalation } from './PolicyTypes/PolicyEscalation';
import { PolicyAICall } from './PolicyTypes/PolicyAICall';
import { PolicyQuote } from './PolicyTypes/PolicyQuote';

// For the children components
export interface IPolicyProps {
  policy: IPolicy;
  invalids: IInvalidField[];
  handleChange: (obj: { name: string; value: string | number | boolean | Date }) => void;
}

export type HandleChange = (obj: { name: string; value: string | number | boolean | Date | number[] }, isGroupsChange?: boolean) => void;

interface IPolicyItemProps {
  onSave: (closeAfterSave?: boolean) => void;
  onDelete: () => void;
  invalids: IInvalidField[];
  loading?: boolean;
  handleChange: HandleChange;
}

export function PolicyItem({
  onSave,
  onDelete,
  invalids,
  loading,
  handleChange,
}: IPolicyItemProps) {
  // Hooks
  const drawerProps = useSelector((s: RootState) => s.policyDrawer);
  const { policyFormData, formDataDirty } = drawerProps;
  const [tab, setTab] = useState<`main` | `advanced` | `test`>(`main`);
  const advancedFieldsInUse = useAdvancedPolicyFields(policyFormData?.policy);

  const tabs: ITab[] = useMemo(() => {
    const arr: ITab[] = [
      {
        name: `Policy Configuration`,
        onClick: () => setTab(`main`),
        href: `main`,
        icon: Cog6ToothIcon,
        current: tab === `main`,
      },
      {
        name: `More Options & Filters`,
        onClick: () => setTab(`advanced`),
        href: `advanced`,
        icon: AdjustmentsVerticalIcon,
        current: tab === `advanced`,
        badge: advancedFieldsInUse.length ? {
          text: `${advancedFieldsInUse.length}`,
          color: `gray`,
        } : undefined,
      },
    ];

    // arr.push({
    //   name: `Test & Preview`,
    //   onClick: () => setTab(`test`),
    //   href: `test`,
    //   icon: RocketLaunchIcon,
    //   current: tab === `test`,
    // });

    return arr;
  }, [tab, advancedFieldsInUse, policyFormData]);

  const warnings = usePolicySanityChecks(policyFormData?.policy);

  if (!policyFormData || !policyFormData.policy) {
    return null;
  }

  const { policy } = policyFormData;

  if (policy.policy_type && policy?.system && !policyConfigV2[policy.policy_type]?.allowedSystemEditor) {
    toast.error(`Not allowed to edit this policy type. Please contact support.`);
    console.error(`Not allowed to edit this policy type`);

    return null;
  }

  return (
    <LoadingOverlay
      loading={ loading }>
      <Form>
        <div
          className={ `flex flex-col justify-between h-full` }
        >
          <GroupSelector
            policyFormData={ policyFormData }
            handleChange={ handleChange }
          />

          <Card className={ classNames(
            `space-y-6 mb-6 mt-4 shadow-md rounded-lg`,
          ) }>
            <Tabs
              navigational={ false }
              tabs={ tabs }
              className={ `pb-0` }
            />

            { /* Early Access */ }
            <When condition={ policy.policy_type === PolicyType.AI_CALL }>
              <Notification
                type={ `pro` }
                icon={ BeakerIcon }
              >
                { `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }
              </Notification>
            </When>

            <When condition={ tab === `main` }>
              <Switch>
                <Case condition={ policy.policy_type === PolicyType.LATE_FEE }>
                  <PolicyLateFee
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.STATEMENT_LATE_FEE }>
                  <PolicyIntervalLateFee
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.DISCOUNT }>
                  <PolicyPromptPaymentDiscount
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.REMINDER }>
                  <PolicyReminder
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.SMS }>
                  <PolicySms
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.STATEMENT }>
                  <PolicyStatement
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.ESCALATION }>
                  <PolicyEscalation
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.AI_CALL }>
                  <PolicyAICall
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.QUOTE_EMAIL }>
                  <PolicyQuote
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
              </Switch>
            </When>
            <When condition={ tab === `advanced` }>
              <AdvancedFields
                policyFormData={ policyFormData.policy }
                handleChange={ handleChange }
                invalids={ invalids }
              />
            </When>

            { /* <When condition={ tab === `test` }>
              <PolicyTestViewRoot
                policyFormData={ policyFormData }
                unsavedChanges={ formDataDirty }
                onSave={ onSave }
              />
            </When> */ }
          </Card>

          <When condition={ warnings.length }>
            <div className={ `space-y-2` }>
              {
                warnings.map(warning => (
                  <Notification type={ `warning` }
                    key={ warning }>
                    { warning }
                  </Notification>
                ))
              }
            </div>
          </When>
          <Switch>
            <Case condition={ invalids.length }>
              <div className={ `transition ease-in-out duration-250 mb-4` }>
                <Notification
                  type={ `error` }
                  title={ `Errors found in policy:` }
                >
                  <span className={ `flex flex-col` }>
                    {
                      invalids.map(err => (
                        <span key={ err.message }>
                          { err.message }
                        </span>
                      ))
                    }
                  </span>
                </Notification>
              </div>
            </Case>
          </Switch>

          <When condition={ policy.policy_type === PolicyType.SMS }>
            <Notification
              type={ `info` }
              className={ `mt-6` }>
              { `SMS messages are charged at $0.10 each` }
            </Notification>
          </When>

          <When condition={ policy.policy_type === PolicyType.QUOTE_EMAIL }>
            <Notification
              type={ `pro` }
              icon={ BeakerIcon }
              border
            >
              { `This is a brand new feature! Please let us know if you have any feedback or requests!` }
            </Notification>
          </When>

          <When condition={ policy.policy_type === PolicyType.AI_CALL }>
            <Notification
              type={ `info` }
              className={ `mt-6` }>
              { `AI Calls are charged at 1.00 credit per minute deducted from your balance.` }
            </Notification>
          </When>
          <div className={ `flex justify-between mt-2 sm:mt-6` }>
            <div className={ `flex justify-start space-x-6` }>
              <Button
                onClick={ onDelete }
                color={ `red` }
              >
                { `Delete Policy` }
              </Button>

              <Button
                onClick={ () => onSave() }
                disabled={ !formDataDirty }
              >
                { `Save & Close` }
              </Button>
            </div>
            { /*
            <When condition={ tab !== `test` }>
              <Button
                onClick={ () => setTab(`test`) }
                color={ `purple` }
              >
                { `Test & Preview` }
              </Button>
            </When> */ }
          </div>
        </div>
      </Form>
    </LoadingOverlay>
  );
}
