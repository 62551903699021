import { useState } from "react";
import { BeakerIcon } from "@heroicons/react/20/solid";

import { Dropdown } from "../../../../common/Atoms/Dropdown";
import { useContactTags } from "../../../hooks/useContactTags";
import { Modal } from "../../../../common/Atoms/Modal";
import { InputTextAddon } from "../../../../common/Atoms/InputTextAddon";
import { Button } from "../../../../common/Atoms/Button";
import { useCreateContactTagMutation } from "../../../services/api/contactTagsApi/contactTags";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { useApplyTagMutation, useClearTagsMutation } from "../../../services/api/contactsApi/contacts";
import { Notification } from "../../../../common/Atoms/Notification";

interface IApplyTagsProps {
  selectedContacts: number[];
}

const NEW_TAG = `CREATE_NEW_TAG_OPTION_VALUE`;
const CLEAR_TAGS = `CLEAR_TAGS_OPTION_VALUE`;

export function ApplyTags({ selectedContacts }: IApplyTagsProps) {
  const { tags, isLoading } = useContactTags();
  const [showNewTagInput, setShowNewTagInput] = useState(false);
  const [applyTags, { isLoading: applyLoading }] = useApplyTagMutation();
  const [clearTags, { isLoading: clearLoading }] = useClearTagsMutation();
  const currentOrg = useGetSelectedOrganisation();

  const tagOptions = tags
    .map(tag => ({
      value: tag,
      label: tag,
    }));

  function handleSelect(e: { value: string; label: string }) {
    if (e.value === NEW_TAG) {
      setShowNewTagInput(true);

      return;
    }

    if (e.value === CLEAR_TAGS) {
      clearTagsFn();

      return;
    }

    applyTags({
      organisationId: currentOrg.id,
      contactIds: selectedContacts,
      tag: e.value,
    });
  }

  function clearTagsFn() {
    clearTags({
      organisationId: currentOrg.id,
      contactIds: selectedContacts,
    });
  }

  return (
    <>
      <CreateTagModal
        open={ showNewTagInput }
        onClose={ () => setShowNewTagInput(false) }
      />
      <div>
        <Dropdown
          options={ [
            [
              { value: NEW_TAG, label: `Create new tag` },
              { value: CLEAR_TAGS, label: `Clear current tags` },
            ],
            tagOptions,
          ] }
          onSelect={ handleSelect }
          label={ `Apply tags` }
          size={ `sm` }
          disabled={ !selectedContacts.length }
          loading={ isLoading || applyLoading || clearLoading }
          theme={ `outline` }
        />
      </div>
    </>
  );
}

function CreateTagModal({ open, onClose }: { open: boolean; onClose: () => void }){
  const [newTag, setNewTag] = useState(``);
  const [createTag, { isLoading: isCreating }] = useCreateContactTagMutation();
  const currentOrg = useGetSelectedOrganisation();
  const { refetch } = useContactTags();

  async function save() {
    const res = await createTag({
      organisationId: currentOrg.id,
      tagName: newTag,
    });

    if (!Object.keys(res).includes(`error`)) {
      refetch();
      setNewTag(``);
      onClose();

      return;
    }
  }

  return (
    <Modal
      open={ open }
      setOpen={ onClose }
      title={ `Create A New Contact Tag` }
    >
      <div className={ `space-y-4 px-4` }>

        <Notification
          type={ `pro` }
          icon={ BeakerIcon }
        >
          { `Tags are a new way to categorise contacts. Each policy can now specify which contacts to include or exclude based on the tags!` }
        </Notification>

        <div className={ `flex justify-between items-end` }>
          <InputTextAddon
            value={ newTag }
            onChange={ e => setNewTag(e.value.toString()) }
            placeholder={ `Enter a new tag name` }
            label={ `Tag Name` }
            className={ `w-full mr-6` }
          />

          <Button
            onClick={ save }
            className={ `h-full mt-0` }
            loading={ isCreating }
            disabled={ !newTag || isCreating }
          >
            { `Save` }
          </Button>
        </div>
      </div>
    </Modal>
  );
}
