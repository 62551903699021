import { Switch, Case, Default } from 'react-if';
import { ICompanyAddress, IStatementColumns, IStatementColumnSettings, IUpdateSettingsRequest  } from 'shared';

import { Divider } from '../../../../../common/Atoms/Divider';
import { Card } from '../../../../../common/Atoms/Card';
import { Spinner } from '../../../Common/Spinner';
import { CardContent } from '../../../../../common/Atoms/CardContent';
import { useGeneralSettingsForm } from '../../../../hooks/useGeneralSettingsForm';
import { SaveAndError } from '../SaveAndError';

import { SafeMode } from './SafeMode';
import { Branding } from './Branding';
import { OrganisationDetails } from './OrganisationDetails';

export type OnChangeFn = (field: keyof IUpdateSettingsRequest, value: boolean | string | ICompanyAddress | number | IStatementColumnSettings | IStatementColumns) => void

export function GeneralSettings() {
  const {
    formData,
    onChange,
    invalids,
    waiting,
    hasUnsavedChanges,
  } = useGeneralSettingsForm();

  return (
    <Card>
      <CardContent>
        <Switch>
          <Case condition={ waiting }>
            <Spinner center/>
          </Case>
          <Default>
            <div className={ `py-6 ` }>
              <SafeMode
                safeModeOn={ !formData.active }
                setSafeModeOn={ onChange }
              />
              <Divider weight={ `light` } />
              <OrganisationDetails
                formData={ formData }
                onChange={ onChange }
                invalids={ invalids }
                hasUnsavedChanges={ hasUnsavedChanges }
              />
              <Branding
                formData={ formData }
                onChange={ onChange }
                invalids={ invalids }
              />
            </div>
            <SaveAndError
            />
          </Default>
        </Switch>
      </CardContent>
    </Card>
  );
}
