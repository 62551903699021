import { ACCOUNTING_CONFIG, EmailTemplateTypes, InterestRateCountry, PolicyAmountsTaxLineType, PolicyCalculationType, PolicyIntervalType, PolicyStatementInterestTo, PolicyStatementType, allowedRegions, findByAlias, getOrdinalSuffix, policyWeeklyDayIntervalMap } from 'shared';
import { useMemo } from 'react';
import { When } from 'react-if';

import { RadioCards } from '../../../../../common/Atoms/RadioCards';
import { RadioStack } from '../../../../../common/Atoms/RadioStack';
import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { fieldIsValid } from '../../../../lib/helper';
import { useAccountingSystemTerm } from '../../../../hooks/useAccountingSystemTerm';
import { Grid } from '../../../../../common/Atoms/Grid';
import { ForwardRefGridItem, GridItem } from '../../../../../common/Atoms/GridItem';
import { Select } from '../../../../../common/Atoms/Select';
import { AccountSelector } from '../PolicyForm/AccountSelector';
import { IPolicyProps } from '../PolicyItem';
import { Toggle } from '../../../../../common/Atoms/Toggle';
import { Transition } from '../../../../../common/Atoms/Transition';
import { useAllowedFeature } from '../../../../hooks/useAllowedFeature';
import { useAccountingSystemSetting } from '../../../../hooks/useAccountingSystemSetting';
import { TaxSelector } from '../PolicyForm/TaxSelector';
import { useAccountingSystemTaxApplicability } from '../../../../hooks/useAccountingSystemTaxApplicability';
import { useHasValidSubscription } from '../../../../hooks/useHasValidSubscription';
import { BANK_OF_ENGLAND_ARTICLE } from '../../../../constants/links';
import { useGetSelectedOrganisation } from '../../../../hooks/useGetSelectedOrganisation';
import { EmailTemplateSelector } from '../PolicyForm/Fields/EmailTemplateSelector';

const statusValues = [
  {
    title: `Approved`,
    value: `AUTHORISED`,
  },
  {
    title: `Draft`,
    value: `DRAFT`,
  },
];

const calcucateOptions = [
  {
    title: `Interest Rate Per Annum`,
    description: `Applies a % of overdue, prorated per day`,
    value: PolicyCalculationType.PRORATA,
  },
  {
    title: `Percentage`,
    description: `Applies a fee equal to the percentage of the due amount owing at statement issue time`,
    value: PolicyCalculationType.PERCENT,
  },
];

const intervalOptions = [
  {
    label: `Monthly`,
    value: PolicyIntervalType.MONTHLY,
  },
  {
    label: `Weekly`,
    value: PolicyIntervalType.WEEKLY,
  },
];

const statementToOptions = [
  {
    label: `When this policy is applied`,
    value: PolicyStatementInterestTo.DEFAULT,
  },
  {
    label: `End of previous month`,
    value: PolicyStatementInterestTo.END_OF_PREVIOUS_MONTH,
  },
];

// TODO, Conditionally show
const taxInclusiveTypes = [
  {
    label: `Tax Inclusive`,
    value: PolicyAmountsTaxLineType.INCLUSIVE,
  },
  {
    label: `Tax Exclusive`,
    value: PolicyAmountsTaxLineType.EXCLUSIVE,
  },
  {
    label: `Not Applicable`,
    value: PolicyAmountsTaxLineType.NOT_APPLICABLE,
  },
];

const dueOptions = [
  {
    label: `End of calculation period`,
    value: 0,
  },
  {
    label: `1 week after calculation`,
    value: 7,
  },
  {
    label: `2 weeks after calculation`,
    value: 14,
  },
  {
    label: `30 days after calculation`,
    value: 30,
  },
];

function getInterestRateCopy(region: string) {
  if (findByAlias(region) === InterestRateCountry.UK) {
    return {
      label: `Apply the Bank of England base rate`,
      description: `Will apply the BoE base rate + your percent. Using the correct base rate(s) that the invoice period spans accross.`,
      helpLink: BANK_OF_ENGLAND_ARTICLE,
    };
  }

  return null;
}

export function PolicyIntervalLateFee({
  policy,
  invalids,
  handleChange,
}: IPolicyProps) {
  const currentOrg = useGetSelectedOrganisation();
  const allowedFeature = useAllowedFeature(`statementInterest`);
  const hideInvoiceStatus = useAccountingSystemSetting(ACCOUNTING_CONFIG.HIDE_INVOICE_STATUS);
  const accountingSystemTaxApplicability = useAccountingSystemTaxApplicability();
  const hasValidSub = useHasValidSubscription();

  const allowedCentralRate = useMemo(() => {
    if (currentOrg) {
      return allowedRegions.includes(currentOrg.region);
    }

    return false;
  }, [currentOrg]);

  const percentPostfix = useMemo(() => {
    if (!policy.use_central_interest_rate || !allowedCentralRate) {
      return policy.calculation_type === PolicyCalculationType.PERCENT ? `%` : `% annually`;
    }

    return policy.calculation_type === PolicyCalculationType.PERCENT ? `%` : `% + BoE rate annually`;

  }, [policy.calculation_type, policy.use_central_interest_rate, allowedCentralRate]);

  const intervalValues = useMemo(() => {
    if (!policy) return [];

    if (policy.interval_type === PolicyIntervalType.MONTHLY) {
      return Array.from(Array(31).keys()).map(i => ({
        label: `${i + 1}${getOrdinalSuffix(i + 1)}`,
        value: i + 1,
      }));
    }

    return Object.keys(policyWeeklyDayIntervalMap).map(key => ({
      label: policyWeeklyDayIntervalMap[key],
      value: Number(key),
    }));

  }, [policy?.interval_type]);

  if (!allowedFeature && hasValidSub) {
    return `Please upgrade your plan to use this feature.`;
  }

  const percentLabel = policy.calculation_type === PolicyCalculationType.PERCENT ? `Percent` : `Percent Per Annum`;

  const includedInvoiceOptions = useMemo(() => {
    const label = policy?.calculation_type === PolicyCalculationType.PRORATA ? `Calculate interest from` : `Include Invoices`;

    const options = [
      {
        label: policy?.calculation_type === PolicyCalculationType.PRORATA ? `The invoice due date` : `With an overdue balance`,
        value: PolicyStatementType.OVERDUE,
      },
      {
        label: policy?.calculation_type === PolicyCalculationType.PRORATA ? `The invoice issue date` : `With an outstanding or overdue balance`,
        value: PolicyStatementType.OUTSTANDING,
      },
    ];

    return {
      label,
      options,
    };
  }, [policy]);

  const isProrata = policy?.calculation_type === PolicyCalculationType.PRORATA;

  return (
    <>
      <When condition={ !hideInvoiceStatus }>
        <RadioCards
          label={ `Send new penalty invoices as` }
          options={ statusValues }
          onChange={ handleChange }
          selected={ policy.invoice_status }
          cols={ 2 }
          name={ `invoice_status` }
        />
      </When>

      <RadioStack
        options={ calcucateOptions }
        onChange={ handleChange }
        selected={ policy.calculation_type }
        name={ `calculation_type` }
      />

      <Transition
        show={ isProrata && allowedCentralRate }
        speed={ `slow` }
      >
        <div>
          <Grid
            cols={ 3 }
            gapX={ 4 }
          >
            <GridItem span={ 3 }>
              <Toggle
                { ...getInterestRateCopy(currentOrg?.region) }
                checked={ policy.use_central_interest_rate }
                onChange={ handleChange }
                name={ `use_central_interest_rate` }
                invalid={ fieldIsValid(`use_central_interest_rate`, invalids) }
              />
            </GridItem>
          </Grid>
        </div>
      </Transition>

      <Grid
        cols={ 3 }
      >
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <AccountSelector
            label={ useAccountingSystemTerm(`Fee Income Account`) }
            value={ policy.xero_account_code }
            valueKey={ `code` }
            onChange={ handleChange }
            name={ `xero_account_code` }
            invalid={ fieldIsValid(`xero_account_code`, invalids) }
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <TaxSelector
            label={ useAccountingSystemTerm(`Tax Rate`) }
            code={ policy.tax_rate }
            onChange={ handleChange }
            name={ `tax_rate` }
            invalid={ fieldIsValid(`tax_rate`, invalids) }
          />
        </GridItem>
        <GridItem
          span={ 1 }
          position={ `bottom` }
        >
          <InputTextAddon
            invalid={ fieldIsValid(`percent`, invalids) }
            helpIcon={ `Apply a percent of overdue line item to the late fee. Set to 0 to not add` }
            label={ percentLabel }
            value={ policy.percent }
            onChange={ handleChange }
            disabled={ policy.calculation_type === PolicyCalculationType.NOT_APPLICABLE }
            addOnText={ percentPostfix }
            name={ `percent` }
          />
        </GridItem>
      </Grid>

      <Grid cols={ 3 }>
        <GridItem span={ 1 }>
          <Select
            label={ `When should this be applied?` }
            options={ intervalOptions }
            onChange={ handleChange }
            selected={ policy.interval_type }
            name={ `interval_type` }
          />
        </GridItem>
        <GridItem span={ 1 }>
          <Select
            label={ `Which day of the ${policy.interval_type === PolicyIntervalType.MONTHLY ? `month` : `week`}?` }
            options={ intervalValues }
            onChange={ handleChange }
            selected={ policy.day_index }
            name={ `day_index` }
            helpIcon={ policy.interval_type === PolicyIntervalType.MONTHLY ? `If the day of the month is greater than the number of days in the month, the last day of the month will be used` : `` }
          />
        </GridItem>
        <GridItem span={ 1 }>
          <Select
            label={ `Due on` }
            options={ dueOptions }
            onChange={ handleChange }
            selected={ policy.penalty_due_date }
            name={ `penalty_due_date` }
          />
        </GridItem>
      </Grid>

      <Grid cols={ isProrata ? 2 : 1 }>
        <GridItem span={ 1 }>
          <Select
            label={ includedInvoiceOptions.label }
            options={ includedInvoiceOptions.options }
            onChange={ handleChange }
            selected={ policy.statement_type }
            name={ `statement_type` }
          />
        </GridItem>

        <Transition
          show={ isProrata }
          speed={ `slow` }
        >
          <ForwardRefGridItem span={ 1 }>
            <Select
              label={ `Calculate interest to` }
              options={ statementToOptions }
              onChange={ handleChange }
              selected={ policy.statement_interest_to || PolicyStatementInterestTo.DEFAULT }
              name={ `statement_interest_to` }
            />
          </ForwardRefGridItem>
        </Transition>
      </Grid>

      <When condition={ policy.statement_type === PolicyStatementType.OVERDUE }>
        <Grid cols={ 2 }>
          <GridItem span={ 1 }>
            <InputTextAddon
              label={ `Minimum Overdue Debt Age` }
              helpIcon={ `Only include invoices/debts that are overdue by at least this many days.` }
              value={ policy.overdue_age_min }
              onChange={ handleChange }
              name={ `overdue_age_min` }
              invalid={ fieldIsValid(`overdue_age_min`, invalids) }
              addOnText={ `days` }
              dataType={ `number` }
            />
          </GridItem>
          <GridItem span={ 1 }>
            <InputTextAddon
              label={ `Maximum Overdue Debt Age` }
              helpIcon={ `Only include invoices/debts that are overdue by no more than this many days. Leave blank for no limit.` }
              value={ policy.overdue_age_max }
              onChange={ handleChange }
              name={ `overdue_age_max` }
              invalid={ fieldIsValid(`overdue_age_max`, invalids) }
              addOnText={ `days` }
              dataType={ `number` }
            />
          </GridItem>
        </Grid>
      </When>

      <EmailTemplateSelector
        value={ policy.template_id }
        type={ EmailTemplateTypes.STATEMENT_INTEREST_EMAIL }
        handleChange={ handleChange }
        invalid={ fieldIsValid(`template_id`, invalids) }
        name={ `template_id` }
        label={ `Send email notification to customer when interest is applied` }
        nullable
        emptyText={ `Do not send email notification` }
        helpIcon={ `Create or edit the template under Email Templates.` }
        currentPolicyId={ policy.id }
      />

      <Transition
        speed={ `slow` }
        show={ isProrata || policy.calculation_type === PolicyCalculationType.PERCENT }
      >
        <div>
          <Grid cols={ 2 }>
            <GridItem span={ 1 }>
              <Toggle
                label={ `Include previous interest charges` }
                description={ `Should we include previous interest charges in the calculation? (Compounding Interest)` }
                helpIcon={ `This will include or exclude any invoice created by Paidnice as a interest charge or late fee` }
                name={ `include_previous_interest` }
                onChange={ handleChange }
                checked={ policy.include_previous_interest }
                invalid={ fieldIsValid(`include_previous_interest`, invalids) }
              />
            </GridItem>
            <GridItem span={ 1 }>
              <Toggle
                label={ `Adjust for Credit` }
                description={ `If the customer has any unallocated credit notes. Should we apply an adjustment to the charges?` }
                name={ `adjust_for_credit` }
                onChange={ handleChange }
                helpIcon={ `The reduction of the fee will be calculated with the same parameters as above` }
                checked={ policy.adjust_for_credit }
                invalid={ fieldIsValid(`adjust_for_credit`, invalids) }
              />
            </GridItem>
          </Grid>
        </div>
      </Transition>

      <When condition={ accountingSystemTaxApplicability }>
        <Select
          label={ `If the amounts are taxable, are they tax inclusive or exclusive?` }
          options={ taxInclusiveTypes }
          onChange={ handleChange }
          selected={ policy.amounts_tax_line_type }
          name={ `amounts_tax_line_type` }
        />
      </When>
    </>
  );
}
