import { allowedRegions, EmailTemplateTypes, findByAlias, InterestRateCountry, PolicyCalculationType, PolicyProcessType, PolicyType } from "shared";
import { When, Switch, Case } from "react-if";
import { useMemo } from "react";

import { useAdhocActionModal } from "../../../../hooks/useAdhocActionModal";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { RadioCards } from "../../../../../common/Atoms/RadioCards";
import { CurrencyInput } from "../../../../../common/Atoms/CurrencyInput";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { RadioStack } from "../../../../../common/Atoms/RadioStack";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { BANK_OF_ENGLAND_ARTICLE } from "../../../../constants/links";
import { useAccountingSystemTerm } from "../../../../hooks/useAccountingSystemTerm";
import { fieldIsValid } from "../../../../lib/helper";
import { AccountSelector } from "../PolicyForm/AccountSelector";
import { EmailTemplateSelector } from "../PolicyForm/Fields/EmailTemplateSelector";
import { PenaltyDueDate } from "../PolicyForm/Fields/PenaltyDueDate";
import { TaxSelector } from "../PolicyForm/TaxSelector";
import { Grid } from "../../../../../common/Atoms/Grid";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { Transition } from "../../../../../common/Atoms/Transition";

const typeOptions = [
  {
    title: `New Invoice`,
    description: `Creates a new invoice for each late fee issued.`,
    value: PolicyProcessType.NEW_INVOICE,
  },
  {
    title: `Update Invoice`,
    description: `Add as a new line item to the existing invoice.`,
    value: PolicyProcessType.UPDATE_INVOICE,
  },
];

const calcucateOptions = [
  {
    title: `Interest Rate Per Annum`,
    description: `Applies a % of overdue, prorated per day`,
    value: PolicyCalculationType.PRORATA,
  },
  {
    title: `Percentage`,
    description: `Applies a fee equal to the percentage of the due amount owing at the time of late fee issue`,
    value: PolicyCalculationType.PERCENT,
  },
  {
    title: `Fixed`,
    description: `Applies a fixed fee in the invoice's base currency`,
    value: PolicyCalculationType.FIXED,
  },
];

function getInterestRateCopy(region: string) {
  if (findByAlias(region) === InterestRateCountry.UK) {
    return {
      label: `Apply the Bank of England base rate`,
      description: `Will apply the BoE base rate + your percent. Using the correct base rate(s) that the invoice period spans accross.`,
      helpLink: BANK_OF_ENGLAND_ARTICLE,
    };
  }

  return null;
}

export function AdhocLateFee() {
  const { type, currentSystemPolicy, currentSystemPolicyForm, update, invalids, invoiceId, invoiceNumber, isBulk, invoiceIds } = useAdhocActionModal();
  const currentOrg = useGetSelectedOrganisation();

  const percentLabel = currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT ? `Percent` : `Percent Per Annum`;

  const allowedCentralRate = useMemo(() => {
    if (currentOrg) {
      return allowedRegions.includes(currentOrg.region);
    }

    return false;
  }, [currentOrg]);

  const percentPostfix = useMemo(() => {
    if (!currentSystemPolicyForm.use_central_interest_rate || !allowedCentralRate) {
      return currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT ? `%` : `% annually`;
    }

    return currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT ? `%` : `%pa + BoE`;

  }, [currentSystemPolicyForm.calculation_type, currentSystemPolicyForm.use_central_interest_rate, allowedCentralRate]);

  if (!currentSystemPolicy || !currentSystemPolicyForm) return null;

  if (!type) return null;

  if (type !== PolicyType.LATE_FEE || currentSystemPolicy.policy_type !== PolicyType.LATE_FEE) return null;

  if (!invoiceId && !invoiceIds) return null;

  const headingText = isBulk ?
    `Issue a one off late fee charge for ${invoiceIds.length} invoices` :
    `Issue a one off late fee charge for ${invoiceNumber || `this invoice`}`;

  return (
    <div>
      <Heading>
        { headingText }
      </Heading>

      <div className={ `p-4 space-y-2` }>
        <RadioCards
          label={ `How should we issue the late fee` }
          options={ typeOptions }
          onChange={ update }
          selected={ currentSystemPolicyForm.process_type }
          cols={ 2 }
          name={ `process_type` }
        />

        <When condition={ currentSystemPolicyForm.process_type === PolicyProcessType.UPDATE_INVOICE }>
          <Toggle
            label={ `Issue a new invoice if unable to update existing?` }
            description={ `If the invoice is locked or has any payments/credits applied, we will issue as a new invoice instead.` }
            helpIcon={ `It is not possible to update a invoice that has any payments, credit notes or is in a locked period. You can choose to send as new invoice, which will only contain this late fee.` }
            checked={ currentSystemPolicyForm.on_update_fail_send_new }
            onChange={ update }
            name={ `on_update_fail_send_new` }
            invalid={ fieldIsValid(`on_update_fail_send_new`, invalids) }
          />
        </When>

        <RadioStack
          options={ calcucateOptions }
          onChange={ update }
          selected={ currentSystemPolicyForm.calculation_type }
          name={ `calculation_type` }
        />

        <Transition
          show={ currentSystemPolicyForm.calculation_type === PolicyCalculationType.PRORATA && allowedCentralRate }
          speed={ `slow` }
        >
          <div>
            <Grid
              cols={ 3 }
              gapX={ 4 }
            >
              <GridItem span={ 3 }>
                <Toggle
                  { ...getInterestRateCopy(currentOrg?.region) }
                  checked={ currentSystemPolicyForm.use_central_interest_rate }
                  onChange={ update }
                  name={ `use_central_interest_rate` }
                  invalid={ fieldIsValid(`use_central_interest_rate`, invalids) }
                />
              </GridItem>
            </Grid>
          </div>

        </Transition>

        <Grid
          cols={ 3 }
          gapX={ 4 }
        >
          <GridItem
            position={ `bottom` }
          >
            <AccountSelector
              label={ useAccountingSystemTerm(`Fee Income Account`) }
              value={ currentSystemPolicyForm.xero_account_code }
              valueKey={ `code` }
              onChange={ update }
              name={ `xero_account_code` }
              invalid={ fieldIsValid(`xero_account_code`, invalids) }
            />
          </GridItem>
          <GridItem
            position={ `bottom` }
          >
            <TaxSelector
              label={ useAccountingSystemTerm(`Tax Rate`) }
              code={ currentSystemPolicyForm.tax_rate }
              onChange={ update }
              name={ `tax_rate` }
              invalid={ fieldIsValid(`tax_rate`, invalids) }
            />
          </GridItem>
          <GridItem
            position={ `bottom` }
          >
            <Switch>
              <Case condition={ currentSystemPolicyForm.calculation_type === PolicyCalculationType.FIXED }>
                <CurrencyInput
                  helpIcon={ `This amount will be in the same currency as the invoice.` }
                  label={ `Fee Amount` }
                  invalid={ fieldIsValid(`fixed_fee_amount`, invalids) }
                  value={ currentSystemPolicyForm.fixed_fee_amount }
                  onChange={ update }
                  name={ `fixed_fee_amount` }
                />
              </Case>
              <Case condition={ currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT || currentSystemPolicyForm.calculation_type === PolicyCalculationType.PRORATA }>
                <InputTextAddon
                  invalid={ fieldIsValid(`percent`, invalids) }
                  label={ percentLabel }
                  value={ currentSystemPolicyForm.percent }
                  onChange={ update }
                  addOnText={ percentPostfix }
                  name={ `percent` }
                />
              </Case>
            </Switch>
          </GridItem>
        </Grid>
        <div className={ `grid grid-cols-12 gap-4` }>
          <div className={ `col-span-7` }>
            <InputTextAddon
              label={ `Description` }
              value={ currentSystemPolicyForm.line_item_text }
              onChange={ update }
              name={ `line_item_text` }
              invalid={ fieldIsValid(`line_item_text`, invalids) }
              description={ `Appears on the invoice line item. Use #PenaltyDate to insert the penalty issued date` }
            />
          </div>

          <When condition={ currentSystemPolicyForm.process_type === PolicyProcessType.NEW_INVOICE || currentSystemPolicyForm.on_update_fail_send_new }>
            <div className={ `col-span-5` }>
              <PenaltyDueDate
                policyFormData={ currentSystemPolicyForm }
                handleChange={ update }
                invalids={ invalids }
              />
            </div>
          </When>
        </div>

        <EmailTemplateSelector
          value={ currentSystemPolicyForm.template_id }
          type={ EmailTemplateTypes.LATE_FEE }
          handleChange={ update }
          invalid={ fieldIsValid(`template_id`, invalids) }
          name={ `template_id` }
          label={ `Send email notification to customer when late fee is applied` }
          nullable
          emptyText={ `Do not send email notification` }
          helpIcon={ `Create or edit the template under Email Templates.` }
          currentPolicyId={ currentSystemPolicyForm.id }
        />
      </div>
    </div>
  );
}
