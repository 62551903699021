import { useEffect, useMemo, useState } from "react";
import { policyConfigV2, PolicyResourceType } from "shared";
import { When } from "react-if";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";

import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useContactParams, useContacts } from "../../../../hooks/useContacts";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { LoadingOverlay } from "../../../Molecules/LoadingOverlay";
import { Divider } from "../../../../../common/Atoms/Divider";
import { useContact } from "../../../../hooks/useContact";
import { Notification } from "../../../../../common/Atoms/Notification";
import { useGetInvoices, useInvoiceParams } from "../../../../hooks/useGetInvoices";
import { useInvoice } from "../../../../hooks/useInvoice";
import { DatePopover } from "../../../../../common/Components/DatePopover";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { usePreviewPolicy } from "../../../../hooks/usePreviewPolicy";
import { Button } from "../../../../../common/Atoms/Button";
import { RootState } from "../../../../store";

interface PolicyTestViewRootProps {
  onSave: (closeAfterSave?: boolean) => void;
}

// TODO Quotes
export function PolicyTestViewRoot({ onSave }: PolicyTestViewRootProps) {
  const [searchStr, setSearchStr] = useState(``);
  const currentOrg = useGetSelectedOrganisation();
  const drawerProps = useSelector((s: RootState) => s.policyDrawer);
  const { policyFormData, formDataDirty } = drawerProps;
  const debouncedSearchStr = useDebounce(searchStr, 350);
  const {
    params: contactParams,
    updateParams: updateContactParams,
    resetParams: resetContactParams,
  } = useContactParams();

  const {
    params: invoiceParams,
    updateParams: updateInvoiceParams,
    resetParams: resetInvoiceParams,
  } = useInvoiceParams();
  const {
    data: invoices,
    isFetching: invoicesFetching,
    isLoading: invoicesLoading,
  } = useGetInvoices();
  const { data: contacts, isFetching: contactsFetching, isLoading: contactsLoading } = useContacts();
  const [selectedResource, setSelectedResource] = useState<{ id: string | number; type: PolicyResourceType} | null>(null);
  const {
    data: contact,
    isLoading: contactLoading,
    isFetching: contactFetching,
  } = useContact(selectedResource?.type === PolicyResourceType.CONTACT ? selectedResource.id : undefined);
  const {
    data: invoice,
    isLoading: invoiceLoading,
    isFetching: invoiceFetching,
  } = useInvoice(selectedResource?.type === PolicyResourceType.INVOICE ? selectedResource.id : undefined);

  const { openPreviewPolicy } = usePreviewPolicy();

  const resourceType = useMemo(() => {
    if (!policyFormData?.policy) {
      return null;
    }

    return policyConfigV2[policyFormData.policy.policy_type]?.resourceType;
  }, [policyFormData?.policy]);

  useEffect(() => {
    if (debouncedSearchStr?.length >= 1) {
      if (resourceType === PolicyResourceType.CONTACT) {
        resetContactParams();

        updateContactParams({
          ...contactParams,
          searchStr: debouncedSearchStr,
          page: 1,
          limit: 6,
        });
      }

      if (resourceType === PolicyResourceType.INVOICE) {
        resetInvoiceParams();

        updateInvoiceParams({
          ...invoiceParams,
          invoiceTextSearch: debouncedSearchStr,
          page: 1,
          limit: 6,
        });
      }
    }
  }, [debouncedSearchStr]);

  const results: {
    label: string;
    value: string | number;
  }[] = useMemo(() => {
    if (!resourceType || !debouncedSearchStr) {
      return [];
    }

    if (resourceType === PolicyResourceType.CONTACT) {
      return contacts?.contacts.map(c => ({
        label: `${c.name}`,
        value: c.id,
      })) || [];
    }

    if (resourceType === PolicyResourceType.INVOICE) {
      return invoices?.invoices.map(i => ({
        label: `${i.invoice.number} - ${i.contact.name}`,
        value: i.invoice.id,
      })).slice(0, 6) || [];
    }

    return [];
  }, [contacts, resourceType, debouncedSearchStr, invoices]);

  const checkResultData: { applies: boolean; message: string | React.ReactNode } | null = useMemo(() => {
    if (!selectedResource) {
      return null;
    }

    if (!policyFormData?.policy?.id) {
      return null;
    }

    if (selectedResource.type === PolicyResourceType.CONTACT) {
      if (!contact) {
        return null;
      }

      if (!contact.applicablePolicies?.[policyFormData.policy.id]) {
        return null;
      }

      return {
        applies: contact.applicablePolicies[policyFormData.policy.id].appliesToContact,
        message: contact.applicablePolicies[policyFormData.policy.id].message,
      };
    }

    if (selectedResource.type === PolicyResourceType.INVOICE) {
      if (!invoice) {
        return null;
      }

      if (!invoice.policyCheck.policyResults?.[policyFormData.policy.id]) {
        return {
          applies: false,
          message: `This invoice contact is not in the same group as this policy`,
        };
      }

      const result = invoice.policyCheck.policyResults[policyFormData.policy.id];

      return {
        applies: result.applies,
        message: result.reason || (
          <span className={ `flex` }>
            <span className={ `mr-1` }>
              { `This policy will be applied on` }
            </span>
            <DatePopover
              date={ result.applies ? result.appliesOn : null }
              labelFormat={ `dd LLL yyyy` }
              organisationTimezone={ currentOrg?.validatedTimezone }
            />
          </span>
        ),
      };
    }

    return null;
  }, [selectedResource, contact, invoice]);

  const canPreview = useMemo(() => {
    if (!policyFormData?.policy) {
      return false;
    }

    let canPreview = policyConfigV2[policyFormData.policy.policy_type]?.previewConfig?.canPreview;

    if (policyConfigV2[policyFormData.policy.policy_type]?.previewConfig?.mustApplyToPreview && canPreview) {
      canPreview = !!checkResultData?.applies;
    }

    return canPreview;
  }, [policyFormData?.policy, checkResultData]);

  function onResultClick(e, value: string | number) {
    e.preventDefault();
    e.stopPropagation();

    setSelectedResource({
      id: value,
      type: resourceType,
    });
  }

  function getValueName(id: string | number): string | undefined | null {
    if (resourceType === PolicyResourceType.CONTACT) {
      return contacts?.contacts.find(c => c.id === id)?.name;
    }

    if (resourceType === PolicyResourceType.INVOICE) {
      return invoices?.invoices.find(i => i.invoice.id === id)?.invoice?.number;
    }

    return null;
  }

  function onPreview() {
    if (selectedResource?.type === PolicyResourceType.INVOICE) {
      openPreviewPolicy({
        invoiceId: selectedResource.id.toString(),
        policyId: Number(policyFormData?.policy?.id),
      });
    }

    if (selectedResource?.type === PolicyResourceType.CONTACT) {
      openPreviewPolicy({
        contactId: selectedResource.id as number,
        policyId: Number(policyFormData?.policy?.id),
      });
    }
  }

  if (!resourceType) {
    return null;
  }

  if (resourceType === PolicyResourceType.QUOTE) {
    return (
      <div
        className={ `mt-2` }
      >
        <Notification
          type={ `info` }
          title={ `Coming Soon!` }
        >
          { `The ability to test quote policies is coming soon. Please reach out for more info. You can find created actions on the "actions" page.` }
        </Notification>
      </div>
    );
  }

  return (
    <div className={ `` }>
      <When condition={ formDataDirty }>
        <Notification
          type={ `warning` }
          title={ `Unsaved changes` }
          className={ `mt-4 mb-2` }
          buttons={ (
            <Button
              onClick={ () => onSave(false) }
            >
              { `Save Changes` }
            </Button>
          ) }
        >
          { `You have unsaved changes. Save these changes before you can preview.` }
        </Notification>
      </When>
      { /* Resource Search and Result */ }

      <InputTextAddon
        placeholder={ `Search for ${resourceType === PolicyResourceType.INVOICE ? `an` : `a`} ${resourceType} to test this policy on...` }
        value={ searchStr }
        onChange={ e => setSearchStr(e.value?.toString()) }
        className={ `mt-2` }
        disabled={ formDataDirty }
      />

      <LoadingOverlay
        loading={ contactsFetching || contactsLoading || invoicesFetching || invoicesLoading }
      >
        <div className={ `grid grid-cols-3 gap-4 mt-4 min-h-[50px]` }>
          { /* Results */ }
          { results.map(r => (
            <button
              key={ r.value }
              onClick={ e => onResultClick(e, r.value) }
              disabled={ contactLoading || contactFetching || invoiceLoading || invoiceFetching }
              className={ `hover:bg-gray-50 rounded-md p-4 flex items-center justify-center` }
            >
              <Paragraph
                className={ `truncate` }
                as={ `span` }
              >
                { r.label }
              </Paragraph>
              <When condition={ selectedResource?.id === r.value }>
                <CheckCircleIcon className={ `w-4 h-4 text-green-500` } />
              </When>
            </button>
          )) }

          <When condition={ !results?.length && debouncedSearchStr?.length >= 1 }>
            <Paragraph
              className={ `col-span-3 text-center text-gray-500 pt-6` }
            >
              { `No results found` }
            </Paragraph>
          </When>
        </div>
        <When condition={ results?.length >= 6 && debouncedSearchStr?.length >= 1 }>
          <Paragraph
            className={ `text-center text-xs` }
            variant={ `help` }
          >
            { `upto 6 results shown` }
          </Paragraph>
        </When>
      </LoadingOverlay>

      { /* Results */ }
      <When condition={ !selectedResource && results.length > 1 }>
        <Paragraph
          variant={ `help` }
        >
          { `Select ${resourceType === PolicyResourceType.INVOICE ? `an` : `a`} ${resourceType} to view results` }
        </Paragraph>
      </When>
      <LoadingOverlay
        loading={ contactLoading || contactFetching || invoiceLoading || invoiceFetching }
      >
        <div>
          <When condition={ !!selectedResource }>
            <Divider
              weight={ `light` }
              spacing={ `small` }
            />
            <When condition={ !!checkResultData }>
              <Notification
                type={ checkResultData?.applies ? `success` : `warning` }
                title={ (checkResultData?.applies ? `Policy applies` : `Policy does not apply`) + ` to ${getValueName(selectedResource?.id)}` }
              >
                <span className={ `flex justify-between items-center` }>
                  { checkResultData?.message }

                  <When condition={ canPreview }>
                    { () => (
                      <Button
                        onClick={ onPreview }
                      >
                        { `Preview` }
                      </Button>
                    ) }
                  </When>
                </span>
              </Notification>
            </When>
          </When>
        </div>
      </LoadingOverlay>
    </div>
  );
}
