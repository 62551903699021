// Initially for a "draft" actions to do today

import {  useMemo } from "react";
import { ActionStatus, ActionType, IActionResponseItem, langauge } from "shared";
import { When } from "react-if";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import { ClockIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { useActionTableModels, useDashboardActions } from "../../../hooks/useActions";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { open } from "../../../slices/actionDrawer";
import { Divider } from "../../../../common/Atoms/Divider";
import { CardContent } from "../../../../common/Atoms/CardContent";

const allowedRetrospective = [
  ActionType.ESCALATION,
  ActionType.LATE_FEE,
];

function getTimeRemaining(date: Date, tz: string) {
  const endOfDayOrg = DateTime.fromJSDate(date).setZone(tz).endOf(`day`);

  return endOfDayOrg.toRelative();
}

type GroupedAction = {
  [key in ActionType]?: IActionResponseItem[];
}

export function ActionableTasks() {
  const currentOrg = useGetSelectedOrganisation();
  const dispatch = useDispatch();
  const { setCurrentTab, setSortModel } = useActionTableModels();
  const navigate = useNavigate();

  const { data: actionsRes, isLoading } = useDashboardActions();

  const actions: GroupedAction = useMemo(() => {
    const result: GroupedAction = {};
    if (!actionsRes || !actionsRes.actions) return result;

    actionsRes.actions.forEach(action => {
      if (!result[action.type]) {
        result[action.type] = [];
      }

      result[action.type]?.push(action);
    });

    return result;
  }, [actionsRes]);

  function onActionClick(actionId: string) {
    dispatch(open(actionId));
  }

  function goToActions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    setCurrentTab(ActionStatus.APPROVAL_PENDING);

    setSortModel([
      {
        field: `actionOn`,
        sort: `asc`,
      },
    ]);

    navigate(`/${currentOrg?.id}/actions?persist=true`);
  }

  const hasActions = Object.keys(actions).length > 0;
  const notShownAmount = actionsRes && (actionsRes.total - actionsRes.actions?.length || 0);

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card className={ `w-full h-full` }>
        <MultiButtonCardHeader
          title={ `Upcoming Draft Actions` }
          description={ `These are draft actions that require your review` }
          noDivider
        />
        <CardContent>
          <When condition={ !hasActions }>
            <div className={ `flex justify-center items-center min-h-[100px]` }>
              <Paragraph>
                { `No upcoming manual draft actions to approve` }
              </Paragraph>
            </div>
          </When>
          <When condition={ hasActions }>
            {
              Object.keys(actions).map(key => {
                const actionsForType = actions[key as ActionType];

                return (
                  <div
                    key={ key }
                  >
                    <Divider
                      text={ langauge.action[key].labelShort || langauge.action[key].label }
                      weight={ `lighter` }
                      spacing={ `xs` }
                      textPosition={ `left` }
                      textWeight={ `bold` }
                    />
                    {
                      actionsForType.map(action => {
                        return (
                          <a
                            href={ `#` }
                            onClick={ e => {
                              e.preventDefault();
                              onActionClick(action.id);
                            } }
                            key={ action.id }
                          >
                            <div
                              className={ `grid grid-cols-12 gap-4 hover:bg-gray-50 rounded-md py-1` }
                            >
                              <div className={ `col-span-4 flex justify-start` }>
                                <Paragraph
                                  className={ `truncate` }
                                  variant={ `secondary` }
                                >
                                  { action.contact?.name }
                                </Paragraph>
                                <When condition={ !allowedRetrospective.includes(action.type) }>
                                  <Paragraph
                                    as={ `span` }
                                    variant={ `help` }
                                    size={ `xs` }
                                    className={ `mr-1 flex items-center` }
                                  >
                                    <Tooltip
                                      title={ `This action will be removed in ${getTimeRemaining(new Date(action.actionOn), currentOrg?.validatedTimezone)} hours` }
                                    >

                                      <ClockIcon className={ `h-4 w-4 ml-1` } />
                                    </Tooltip>
                                  </Paragraph>
                                </When>
                              </div>

                              <div className={ `col-span-4 flex justify-center` }>
                                <Tooltip
                                  title={ DateTime.fromJSDate(new Date(action.actionOn)).setZone(currentOrg.validatedTimezone).toFormat(`yyyy-MM-dd HH:mm ZZZZ`) }
                                >
                                  <span>
                                    <Paragraph
                                      className={ `truncate` }
                                    >
                                      <Paragraph
                                        as={ `span` }
                                        variant={ `secondary` }
                                      >
                                        { `scheduled ` }
                                      </Paragraph>
                                      { DateTime.fromJSDate(new Date(action.actionOn)).setZone(currentOrg.validatedTimezone).toRelativeCalendar() }
                                      { /* { getPolicyInfo(action) } */ }
                                    </Paragraph>
                                  </span>
                                </Tooltip>

                              </div>

                              <div className={ `col-span-2 flex justify-center` }>
                                <Paragraph
                                  className={ `truncate` }
                                  variant={ `secondary` }
                                >
                                  { action.invoice?.xero_number || action.quote?.number }
                                </Paragraph>
                              </div>

                              <div className={ `col-span-2 flex justify-end` }>
                                <Paragraph
                                  variant={ `link` }
                                  className={ `flex items-end space-x-2` }
                                >
                                  { `Review` }
                                </Paragraph>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    }
                  </div>
                );
              })
            }
            <When condition={ notShownAmount > 0 }>
              <div className={ `flex justify-end` }>
                <Paragraph
                  variant={ `help` }
                >
                  { `+${notShownAmount} more action${notShownAmount === 1 ? `` : `s`}` }
                </Paragraph>
              </div>
            </When>
            <div className={ `flex justify-center pt-4` }>
              <a
                href={ `#` }
                onClick={ goToActions }
              >
                <Paragraph
                  variant={ `link` }
                >
                  { `Review all draft actions` }
                </Paragraph>
              </a>
            </div>
          </When>
        </CardContent>
      </Card>
    </LoadingOverlay>
  );
}

