import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IEscalationParams } from 'shared';

import { useFetchEscalationsQuery } from '../services/api/escalationApi/escalation';
import { RootState } from '../store';
import { initialState, setParams } from '../slices/escalationParams';

export function useEscalations() {
  const { organisationId, invoiceId, contactId, escalationId } = useParams();
  const params = useSelector((s: RootState) => s.escalationParams);

  let paramObject: IEscalationParams = {
    organisationId,
    invoiceId,
    type: params.type,
    status: params.status,
    scheduled: params.scheduled,
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    sortOrder: params.sortOrder,
    searchText: params.searchText,
    assignedUserId: params.assignedUserId,
    includePaid: params.includePaid,
  };

  if (invoiceId) {
    paramObject = {
      ...initialState,
      invoiceId,
      organisationId,
    };
  }

  if (contactId) {
    paramObject.contactId = Number(contactId);
    paramObject.invoiceId = null;
  }

  if (escalationId) {
    paramObject = {
      ...initialState,
      includePaid: true,
      status: null,
      scheduled: null,
      organisationId,
      escalationId: Number(escalationId),
    };
  }

  const result = useFetchEscalationsQuery(paramObject, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    pollingInterval: 1000 * 60, // 60 seconds
    skipPollingIfUnfocused: true,
  });

  if (result.data?.escalations && result.data.escalations.length && invoiceId) {
    result.data = {
      ...result.data,
      escalations: result.data.escalations.filter(e => e.invoiceId === invoiceId),
    };
  }

  // If no data yet, default to empty array
  if (!result.data) {
    result.data = {
      total: 0,
      escalations: [],
    };
  }

  if (!result.data?.escalations) {
    result.data.escalations = [];
  }

  return result;
}

export function useEscalationParams() {
  const params = useSelector((s: RootState) => s.escalationParams);
  const dispatch = useDispatch();

  const updateParams = (update: Partial<typeof params>) => {
    return dispatch(setParams(update));
  };

  const resetParams = (keepKeys?: string[]) => {
    const newState = { ...initialState };
    if (keepKeys) {
      keepKeys.forEach(key => {
        newState[key] = params[key];
      });
    }

    dispatch(setParams(newState));
  };

  return {
    resetParams,
    updateParams,
    params,
  };
}
