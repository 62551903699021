import { useMemo } from 'react';
import { useLocation, useMatches, useParams } from 'react-router-dom';
import {
  HomeIcon,
  InboxIcon,
  Cog6ToothIcon,
  UserGroupIcon,
  LinkIcon,
  EnvelopeIcon,
  WrenchIcon,
  ArrowPathRoundedSquareIcon,
  IdentificationIcon,
  BoltIcon,
  LifebuoyIcon,
  ArrowTrendingUpIcon,
  DocumentMagnifyingGlassIcon,
  CurrencyDollarIcon,
  FolderIcon,
  DocumentIcon,
  PhoneIcon,
  UsersIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { IPlanFeatures, validatePolicySync } from 'shared';
import { PlusCircleIcon } from '@heroicons/react/20/solid';

import { env } from '../../common/lib/env';

import { useFeatureFlags } from './useFeatureFlags';
import { useGroups } from './useGroups';
import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';
import { useAccountingSystemTerm } from './useAccountingSystemTerm';
import { useUser } from './useUser';

interface INavOption {
  name: string;
  external?: boolean;
  href: string;
  type?: `groupOptionDefault`;
  icon: typeof ArrowPathRoundedSquareIcon;
  current: boolean;
  /** The route requires a organisation to be connected and selected */
  requiresOrg?: boolean;
  /** Feature flag as defined in auth0 */
  requiresFeature?: `ai`;
  /** Requires an active subscription, org must also be true */
  requiresSubscription?: boolean;
  /** Requires a paid feature */
  requiresPaidFeature?: keyof IPlanFeatures;
  alwaysOpen?: boolean;
  children?: INavOption[];
  devOnly?: boolean;
  demoTarget?: string;
  key?: string | number;
  invalidCount?: number;
  validCount?: number;
  memberCount?: number;
}

export function useNavigationOpts(orgConnected) {
  const user = useUser();
  const featureFlags = useFeatureFlags();
  const { pathname } = useLocation();
  const { groupId } = useParams();
  const { data: groups } = useGroups();
  const currentOrg = useGetSelectedOrganisation();
  const quotesTerminology = useAccountingSystemTerm(`Quotes`);

  const currentGroupId = useMemo(() => {
    return groupId ? Number(groupId) : undefined;
  }, [groupId]);

  const newUIOpts: INavOption[] = useMemo(() => {
    const gs = groups ? [...groups] : [];

    const groupOpts: INavOption[] = [...gs]
      .sort(a => a.default ? -1 : 1)
      .map(group => ({
        name: group.title,
        href: `/groups/${group.id}`,
        icon: UserGroupIcon,
        current: currentGroupId === group.id,
        requiresOrg: true,
        type: group.default ? `groupOptionDefault` : undefined,
        key: group.id,
        invalidCount: group.policies?.filter(p => {
          try {
            validatePolicySync(p);

            return false;
          }
          catch (e) {
            return true;
          }
        }).length,
        validCount: group.policies?.filter(p => {
          try {
            validatePolicySync(p);

            return true;
          }
          catch (e) {
            return false;
          }
        }).length,
        memberCount: group.contactsCount,
      }));

    const opts: INavOption[] = [
      {
        name: `Dashboard`,
        href: `/`,
        icon: HomeIcon,
        current: false,
        requiresOrg: false,
      },
      {
        name: `Groups`,
        href: `SPACE`,
        current: false,
        icon: UserGroupIcon,
        requiresOrg: true,
      },
      ...groupOpts,
      {
        name: `Add new group`,
        icon: PlusCircleIcon,
        current: false,
        requiresOrg: true,
        href: `ADD_NEW_GROUP`,
      },
      {
        name: ``,
        href: `SPACE`,
        requiresOrg: true,
        icon: Cog6ToothIcon,
        current: false,
      },
      {
        name: `Contacts`,
        icon: IdentificationIcon,
        current: false,
        href: `/contacts/manage`,
        requiresOrg: true,
        demoTarget: `contacts`,
      },
      {
        name: `Invoices`,
        href: `/invoices`,
        icon: InboxIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: quotesTerminology,
        href: `/quotes`,
        icon: DocumentTextIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: ``,
        href: `SPACE`,
        requiresOrg: true,
        icon: Cog6ToothIcon,
        current: false,
      },
      {
        name: `Actions`,
        href: `/actions`,
        icon: BoltIcon,
        current: false,
        requiresOrg: true,
        demoTarget: `actions`,
      },
      {
        name: `Escalations`,
        href: `/escalations`,
        icon: ArrowTrendingUpIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: `AI Calls`,
        href: `/ai-calls`,
        icon: PhoneIcon,
        current: false,
        requiresOrg: true,
        requiresFeature: `ai`,
      },
      {
        name: `Payments`,
        href: `/payments`,
        icon: CurrencyDollarIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: `Policies`,
        href: `/policies`,
        requiresOrg: true,
        icon: DocumentIcon,
        current: false,
      },
      {
        name: `Settings`,
        href: `SPACE`,
        icon: Cog6ToothIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: `Organisation Settings`,
        icon: WrenchIcon,
        href: `/settings/general`,
        current: false,
        requiresOrg: true,
        demoTarget: `organisationSettings`,
      },
      {
        name: `Email Templates`,
        icon: EnvelopeIcon,
        current: false,
        href: `/settings/email-templates`,
        requiresOrg: true,
        demoTarget: `emailTemplates`,
      },
      {
        name: `Files`,
        icon: FolderIcon,
        href: `/settings/files`,
        current: false,
        requiresOrg: true,
      },
      {
        name: `Connection`,
        href: `/settings/connection`,
        icon: LinkIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: `Users`,
        href: `/settings/users`,
        icon: UsersIcon,
        current: false,
        requiresOrg: true,
      },
      {
        name: `Audit Logs`,
        href: `/logs`,
        current: false,
        requiresOrg: true,
        icon: DocumentMagnifyingGlassIcon,
      },
      {
        name: ``,
        href: `SPACE`,
        icon: Cog6ToothIcon,
        current: false,
      },
      {
        name: `Book a Call`,
        href: `BOOK_DEMO`,
        icon: PhoneIcon,
        current: false,
        external: true,
        demoTarget: `helpCenter`,
      },
      {
        name: `Help Center`,
        href: `https://support.paidnice.com?source=app-nav`,
        icon: LifebuoyIcon,
        current: false,
        external: true,
        demoTarget: `helpCenter`,
      },
    ];

    return opts.filter(i => {
      if (!currentOrg?.legacyUIUser && !env.legacyPolicyUser) {
        // Remove the fake policy option
        if (i.name === `Policies`) {
          return false;
        }
      }

      if (i.requiresOrg && !orgConnected) {
        return false;
      }

      return true;
    });

  }, [groups, pathname, orgConnected, featureFlags, currentOrg, quotesTerminology, user]);

  const matches = useMatches();

  return {
    navigation: newUIOpts
      .filter(i => {
        if (i.href === `/payments`) {
        // Only show payments if the user has the payments feature
          if (!currentOrg?.stripeAccount?.id) {
            return false;
          }
        }

        if (i.requiresFeature && !featureFlags[i.requiresFeature]) {
          return false;
        }

        return true;
      })
      .map(i => {
        let current = false;

        if (i.href === `/`) {
          current = pathname === `/`;
        }
        else {
        // Not pretty but adding some known checks
          if (i.href === `/actions`) {
            current = pathname.split(`/`)?.[1] === `actions`;
          }
          else {
            current = matches.some(m => m.pathname.endsWith(i.href));
          }
        }

        return {
          ...i,
          current,
        };
      }),
  };
}
