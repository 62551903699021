import { useMemo } from 'react';
import { When } from 'react-if';

import { classNames }  from '../lib/classNames';

import { ITableHeading } from './Table';
import { CheckBox } from './CheckBox';

export function getClassnameForStackable(stackable) {
  switch (stackable) {
  case `sm`:
    return {
      heading: `hidden lg:table-cell`,
      row: {
        stacked: `sm:hidden`,
        main: `hidden lg:table-cell`,
      },
    };
  case `lg`:
    return {
      heading: `hidden sm:table-cell`,
      row: {
        stacked: `sm:hidden`,
        main: `hidden sm:table-cell`,
      },
    };
  default:
    return {
      heading: ``,
    };
  }
}

interface ITableRowsProps {
  row: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  headings: ITableHeading[]
  onRowClick?: (row) => void;
  selectedIds?: Array<number | string>;
  onSelectedChange?: (id: number | string) => void;
  shouldShowCheckboxes: boolean;
}

export function TableRow({
  row,
  headings,
  onRowClick,
  selectedIds = [],
  onSelectedChange,
  shouldShowCheckboxes,
}: ITableRowsProps) {
  const hasAnyStacked = useMemo(() => {
    return headings.filter(r => r.stackable);
  }, [headings]);

  return (
    <tr
      className={
        classNames(
          onRowClick && `cursor-pointer hover:bg-gray-50`,
        )
      }
      onClick={ () => onRowClick && onRowClick(row) }
    >
      <When condition={ shouldShowCheckboxes }>
        { () => (
          <td className={ `relative sm:w-16 sm:px-8` }>
            <When condition={ selectedIds.includes(row.id) }>
              <div className={ `absolute inset-y-0 left-0 w-0.5 bg-lateGreen-600` } />
            </When>
            <CheckBox
              className={ `absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-500 sm:left-6` }
              checked={ selectedIds.includes(row.id) }
              onChange={ () => onSelectedChange(row.id) }
              stopPropagation
              placement={ `left` }
            />
          </td>
        ) }

      </When>
      {
        headings.map((r, i) => {
          return (
            <td
              key={ i }
              className={
                classNames(
                  `text-sm py-4`,
                  i === 0 ? `w-full max-w-0 text-gray-900 font-medium sm:w-auto sm:max-w-none sm:pl-0` : `px-3 text-gray-500 text-center`,
                  r.textAlign === `right` && `text-right`,
                  r.textAlign === `left` && `text-left`,
                  getClassnameForStackable(r.stackable).row?.main,
                )
              }
            >
              <When condition={ hasAnyStacked.length > 0 && i === 0 }>
                <dl className={ `font-normal lg:hidden` }>
                  {
                    hasAnyStacked.map((r, i) => {
                      return (
                        <dd
                          key={ i }
                          className={
                            classNames(
                              `mt-1 truncate`,
                              getClassnameForStackable(r.stackable).row?.stacked,
                              i === 0 ? `text-gray-700` : `text-gray-500`,
                            )
                          }>
                          { row[r.name] }
                        </dd>
                      );
                    })
                  }
                </dl>
              </When>
              { row[r.name] }
            </td>
          );
        })
      }
    </tr>
  );
}

