import { ACCOUNTING_CONFIG, IInvalidField } from "shared";
import { useMemo, useState } from "react";
import { When } from "react-if";

import { ComboBox } from "../../../../../common/Atoms/ComboBox";
import { useAccountingResources } from "../../../../hooks/useAccountingResources";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { useAccountingSystemSetting } from "../../../../hooks/useAccountingSystemSetting";
import { useAccountingSystemTooltip } from "../../../../hooks/useAccountingSystemTerm";

interface TaxSelectorProps {
  label?: string;
  code?: string | null;
  onChange: (e: { value: string; name: string }) => void;
  name: string;
  invalid?: IInvalidField;
  description?: string;
  requireAndSupressDefault?: boolean;
}

export function TaxSelector({
  label,
  code,
  onChange,
  name,
  invalid,
  description,
  requireAndSupressDefault,
}: TaxSelectorProps) {

  const { data: accountingResources, isLoading } = useAccountingResources();
  const [search, setSearch] = useState(``);

  const taxRates = isLoading ? [] : (accountingResources?.taxRates || []);
  const requireTaxRate = useAccountingSystemSetting(ACCOUNTING_CONFIG.REQUIRE_TAX_RATE);
  const defaultTaxRate = useAccountingSystemSetting(ACCOUNTING_CONFIG.DEFAULT_TAX_RATE);
  const tooltip = useAccountingSystemTooltip(`tax_rate`);

  const selected = useMemo(() => {
    if ((code === null || code === undefined) && !requireTaxRate && defaultTaxRate && !requireAndSupressDefault) {
      return defaultTaxRate;
    }

    return taxRates.find(rate => rate.value === code);
  }, [taxRates, code]);

  const options = useMemo(() => {
    const r = taxRates.filter(rate => {
      return (
        rate.label.toLowerCase().includes(search.toLowerCase()) ||
        rate.value.toLowerCase().includes(search.toLowerCase())
      );
    });

    if (defaultTaxRate && !requireAndSupressDefault) {
      r.unshift(defaultTaxRate);
    }

    return r;
  }, [taxRates, search]);

  function handleChange({ value }) {
    return onChange({
      value,
      name,
    });
  }

  return (
    <>
      <ComboBox
        label={ label }
        options={ options }
        selected={ selected || null }
        query={ search }
        onQueryChange={ e => setSearch(e.target.value) }
        onSelect={ handleChange }
        invalid={ invalid }
        placeholder={ `Search` }
        loading={ isLoading }
        helpIcon={ tooltip }
      />

      <When condition={ description }>
        <FormDescription>
          { description }
        </FormDescription>
      </When>
    </>

  );
}
