import { useDispatch, useSelector } from "react-redux";
import { Parser } from 'html-to-react';
import { useState } from "react";
import { toast } from "react-toastify";
import { When } from "react-if";

import { Modal } from "../../../common/Atoms/Modal";
import { RootState } from "../../store";
import { setState } from "../../slices/statementPreviewModal";
import { CustomIframe } from "../IFrame";
import { Notification } from "../../../common/Atoms/Notification";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { useApiRequest } from "../../hooks/useApiRequest";
import { env } from "../../../common/lib/env";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";

export function StatementPreview() {
  const dispatch = useDispatch();
  const currentOrg = useGetSelectedOrganisation();
  const [downloading, setDownloading] = useState(false);
  const request = useApiRequest();

  const modalState = useSelector((s: RootState) => s.statementPreviewModal);

  function close() {
    dispatch(setState({ show: false, html: null }));
  }

  const htmlParser = Parser();

  async function onDownload() {
    if (downloading) return;
    setDownloading(true);

    try {
      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.baseApiUrl}organisation/${currentOrg?.id}/previewStatementPdf`,
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;
      link.setAttribute(`download`, `test_statement_outstanding.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not create statement.`}`);
    }

    setDownloading(false);
  }

  return (
    <Modal
      title={ `Statement Preview` }
      open={ !!modalState.show }
      setOpen={ close }
      width={ `xl` }
    >
      <div className={ `flex justify-center w-full overflow-auto` }>
        <CustomIframe style={ { minHeight: `700px`, width: `200mm`, border: `#dbdbdb 1px solid` } }
        >
          { htmlParser.parse(modalState.html || ``) }
        </CustomIframe>
      </div>

      <Notification
        type={ `info` }
        className={ `mt-4` }
      >
        <Paragraph
          as={ `span` }
        >
          { `Exact spacings and sizing may differ slightly on the PDF. Click ` }
        </Paragraph>
        <Paragraph
          variant={ `link` }
          as={ `span` }
        >
          <a
            onClick={ e => {
              e.preventDefault();
              onDownload();
            } }
          >
            { `here` }
          </a>
        </Paragraph>
        <Paragraph
          as={ `span` }
        >
          { ` to download the PDF.` }
        </Paragraph>

        <When condition={ downloading }>
          <Paragraph
            as={ `span` }
            className={ `ml-2` }
          >
            { `Downloading...` }
          </Paragraph>
        </When>
      </Notification>
    </Modal>
  );
}
