import { DataGridPro, getGridDateOperators, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators, GridColDef, GridCsvExportOptions, GridFilterInputDate, GridRenderHeaderFilterProps, GridRowClassNameParams, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid-pro";
import { currencyFormatter, InvoiceListItem, statusUIMapXero, toDecimal } from "shared";
import { Tooltip } from "@mui/material";
import { CurrencyDollarIcon, LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useLocalStorage } from "usehooks-ts";

import { InvoiceTableFields, useInvoices, useInvoiceTableModels } from "../../hooks/useInvoices";
import { classNames } from "../../../common/lib/classNames";
import { WrappedLink } from "../WrappedLink";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Button } from "../../../common/Atoms/Button";
import { XeroStatusBadge } from "../../../common/Components/Invoice/XeroStatusBadge";
import { useGroups } from "../../hooks/useGroups";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { useAccountingSystemName } from "../../hooks/useAccountingSystemName";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { useActionInvoicesMutation } from "../../services/api/invoiceApi/invoice";

import { InvoiceBulkActionsToolbar } from "./InvoiceBulkActionsToolbar";
import { FrozenFilter } from "./CustomFilters/Frozen";
import { LateFeeFilter } from "./CustomFilters/LateFee";

const statusOptions = Object.keys(statusUIMapXero).map(key => {
  return {
    ...statusUIMapXero[key],
    label: statusUIMapXero[key].name,
    value: key,
  };
});

const freezeOptions = [
  {
    label: `Frozen`,
    value: true,
  },
  {
    label: `Not Frozen`,
    value: false,
  },
];

const isLateFeeOptions = [
  {
    label: `Only Late Fees`,
    value: true,
  },
  {
    label: `Late Fees Excluded`,
    value: false,
  },
];

export function InvoiceTableRoot() {
  const {
    data: invoicesRes,
    isLoading,
    isFetching,
  } = useInvoices();
  const { data: groupsRes } = useGroups();
  const currentOrg = useGetSelectedOrganisation();
  const [ actionInvoices] = useActionInvoicesMutation();
  const apiRef = useGridApiRef();
  const [dataGridStateInvoice, setDataGridStateInvoice] = useLocalStorage<GridInitialStatePro>(`dataGridStateInvoice`, {});

  const invoices = invoicesRes?.invoices || [];
  const count = invoicesRes?.count ? Number(invoicesRes.count) : 0;

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const { filterModel, setFilterModel, setPaginationModel, setSortModel, sortModel, paginationModel } = useInvoiceTableModels();

  const groupOptions = useMemo(() => {
    if (!groupsRes) {
      return [];
    }

    return groupsRes.map(group => {
      return {
        label: group.title,
        value: group.id,
      };
    });
  }, [groupsRes]);

  // ---- STORE AND RESTORE COL WIDTHS ----
  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      setDataGridStateInvoice(currentState);
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    if (apiRef.current?.restoreState) {

      // We only want to set column widths and order
      const stateToRestore: GridInitialStatePro = {
        columns: dataGridStateInvoice.columns,
        pinnedColumns: dataGridStateInvoice.pinnedColumns,
      };

      apiRef.current.restoreState(stateToRestore);

      // handle refresh and navigating away/refreshing
      window.addEventListener(`beforeunload`, saveSnapshot);
    }

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener(`beforeunload`, saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot, apiRef]);

  // ---- END STORE AND RESTORE COL WIDTHS ----

  const columns: GridColDef<InvoiceListItem>[] = useMemo(() => {
    const defaultWidths = {
      [InvoiceTableFields.contactName]: 170,
      [InvoiceTableFields.invoiceReference]: 100,
      [InvoiceTableFields.groupId]: 100,
    };
    const result: GridColDef<InvoiceListItem>[] = [
      {
        field: InvoiceTableFields.frozen,
        type: `singleSelect`,
        valueGetter: (value, row) => row.invoice.frozen || false,
        valueOptions: freezeOptions,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        width: 30,
        headerName: ``,
        renderHeaderFilter: (params: GridRenderHeaderFilterProps) => (
          <FrozenFilter { ...params } />
        ),
        renderCell: ({ row }) => {
          if (row.invoice.frozen) {
            return (
              <Tooltip title={ `Invoice is frozen. No policies will apply.` }>
                <span className={ `w-full h-full flex items-center` }>
                  <LockClosedIcon className={ `h-5 w-5 text-blue-400` } />
                </span>
              </Tooltip>
            );
          }

          return (
            <Tooltip title={ `Invoice not frozen` }>
              <span className={ `w-full h-full flex items-center` }>
                <LockOpenIcon className={ `h-5 w-5 text-gray-200` } />
              </span>
            </Tooltip>
          );
        },
        valueFormatter: (value, row) => {
          return row.invoice.frozen ? `Frozen` : ``;
        },
      },
      {
        field: InvoiceTableFields.isLateFee,
        type: `singleSelect`,
        valueGetter: (value, row) => row.invoice.createdByUs || false,
        valueOptions: isLateFeeOptions,
        width: 30,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        headerName: ``,
        renderHeaderFilter: (params: GridRenderHeaderFilterProps) => (
          <LateFeeFilter { ...params } />
        ),
        renderCell: ({ row }) => {
          if (row.invoice.createdByUs) {
            return (
              <Tooltip title={ `Invoice was created by Paidnice as a late fee or interest charge` }>
                <span className={ `w-full h-full flex items-center` }>
                  <CurrencyDollarIcon className={ `h-5 w-5 text-orange-400` } />
                </span>
              </Tooltip>
            );
          }

          return null;
        },
        valueFormatter: (value, row) => {
          return row.invoice.createdByUs ? `Late Fee Invoice` : ``;
        },
      },
      {
        field: InvoiceTableFields.invoiceNumber,
        headerName: `Number`,
        type: `string`,
        valueGetter: (value, row) => row.invoice.number,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        renderCell: ({ row }) => (
          <WrappedLink
            to={ `/invoices/${ row.invoice.id }` }
          >
            <Paragraph
              variant={ `link` }
              as={ `span` }
            >
              { row.invoice.number }
            </Paragraph>
          </WrappedLink>
        ),
      },
      {
        field: InvoiceTableFields.invoiceReference,
        headerName: `Reference`,
        type: `string`,
        width: defaultWidths[InvoiceTableFields.invoiceReference],
        valueGetter: (value, row) => row.invoice.reference,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
      },
      {
        field: InvoiceTableFields.invoiceStatus,
        headerName: `Status`,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: statusOptions,
        valueGetter: (value, row) => row.invoice.externalStatus,
        renderCell: ({ row }) => (
          <XeroStatusBadge
            status={ row.invoice.externalStatus as keyof typeof statusUIMapXero }
            mode={ `app` }
          />
        ),
      },
      {
        field: InvoiceTableFields.contactName,
        headerName: `Contact`,
        type: `string`,
        width: defaultWidths[InvoiceTableFields.contactName],
        valueGetter: (value, row) => row.contact.name,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        renderCell: ({ row }) => (
          <WrappedLink
            to={ `/contacts/${ row.invoice.contactId }` }
          >
            <Paragraph
              variant={ `link` }
              as={ `span` }
            >
              { row.contact.name }
            </Paragraph>
          </WrappedLink>
        ),
      },
      {
        field: InvoiceTableFields.invoiceIssueDate,
        headerName: `Issue Date`,
        valueGetter: (value, row) => row.invoice.issueDate,
        filterOperators: getGridDateOperators()
          .filter(op => {
            return [ `onOrAfter`, `onOrBefore`].includes(op.value);
          })
          .map(op => {
            return {
              ...op,
              InputComponent: CustomDateFilterInput,
            };
          }),
        width: 110,
      },
      {
        field: InvoiceTableFields.invoiceDueDate,
        headerName: `Due Date`,
        valueGetter: (value, row) => row.invoice.dueDate,
        filterOperators: getGridDateOperators()
          .filter(op => {
            return [ `onOrAfter`, `onOrBefore`].includes(op.value);
          })
          .map(op => {
            return {
              ...op,
              InputComponent: CustomDateFilterInput,
            };
          }),
        width: 110,
      },
      {
        field: InvoiceTableFields.invoiceTotal,
        type: `number`,
        headerName: `Total`,
        valueGetter: (value, row) => toDecimal(row.invoice.totals.totalInCents),
        valueFormatter: (value, row) => {
          const currency = row.invoice.currency;

          return currencyFormatter(currency)(value);
        },
        filterOperators: getGridNumericOperators().filter(op => {
          return [`>=`, `=`, `<=`].includes(op.value);
        }),
      },
      {
        field: InvoiceTableFields.invoiceOutstanding,
        type: `number`,
        headerName: `Outstanding`,
        valueGetter: (value, row) => toDecimal(row.invoice.totals.dueInCents),
        valueFormatter: (value, row) => {
          const currency = row.invoice.currency;

          return currencyFormatter(currency)(value);
        },
        filterOperators: getGridNumericOperators().filter(op => {
          return [`>=`, `=`, `<=`].includes(op.value);
        }),
        cellClassName: params => {
          if (params.row.invoice.externalStatus !== `AUTHORISED`) {
            return ``;
          }

          if (params.row.invoice.daysOverdue >= 1) {
            // The outstanding amount is now a overdue amount
            return `text-red-500`;
          }

          return ``;
        },
      },
      {
        field: InvoiceTableFields.daysOverdue,
        headerName: `Days Overdue`,
        type: `number`,
        width: 80,
        valueGetter: (value, row) => row.invoice.externalStatus === `AUTHORISED` ? row.invoice.daysOverdue : null,
        filterOperators: getGridNumericOperators().filter(op => {
          return [`>=`, `=`, `<=`].includes(op.value);
        }),
        cellClassName: params => {
          if (params.row.invoice.externalStatus !== `AUTHORISED`) {
            return ``;
          }

          if (params.row.invoice.daysOverdue > 28) {
            return `text-red-500`;
          }

          if (params.row.invoice.daysOverdue > 14) {
            return `text-orange-500`;
          }

          if (params.row.invoice.daysOverdue >= 1) {
            return `text-yellow-500`;
          }

          return `text-green-500`;
        },
      },
      {
        field: InvoiceTableFields.groupId,
        headerName: `Group`,
        description: `Paidnice Group`,
        type: `singleSelect`,
        width: defaultWidths[InvoiceTableFields.groupId],
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: groupOptions,
        valueGetter: (value, row) => row.group?.title,
        renderCell: ({ row }) => (
          <WrappedLink
            to={ `/groups/${ row.group?.id }` }
          >
            <Paragraph
              variant={ `link` }
              as={ `span` }
            >
              { row.group?.title }
            </Paragraph>
          </WrappedLink>
        ),
      },
    ];

    return result.map(col => {
      return {
        ...col,
        // headerAlign: `left`,
      };
    });
  }, []);

  function getRowClassName(params: GridRowClassNameParams<InvoiceListItem>){
    const classname = classNames(
      params.row.invoice.frozen ? `bg-sky-50` : ``,
    );

    return classname;
  }

  function onSync() {
    actionInvoices({ action: `sync-all`, organisationId: currentOrg.id });
  }

  const viewportHeight = window.innerHeight;
  const tableHeight = viewportHeight - 180; // px for the header

  return (
    <div
      style={ { height: tableHeight } }
    >
      <div className={ `mb-4 flex justify-between items-center` }>
        { /* Title */ }
        <div>
          <Heading
            variant={ `default` }
            size={ `xl` }
          >
            { `Invoices` }
          </Heading>
          <Paragraph
            variant={ `help` }
          >
            { `Invoices will be automatically imported as they are created in ${useAccountingSystemName()} in real time.` }
          </Paragraph>
        </div>

        { /* Resync / Ops */ }
        <div className={ `mt-4 sm:mt-0 sm:ml-16 sm:flex-none` }>
          <Tooltip title={ `Force a re-sync of invoices now.` }>
            <span>
              <Button
                onClick={ onSync }
                disabled={ currentOrg?.syncInProgress }
                color={ `lateGreenOutline` }
                size={ `sm` }
              >
                { currentOrg?.syncInProgress ? `Checking for updates...` : `Check for updates` }
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
      <DataGridPro
        apiRef={ apiRef }
        className={ `bg-white pt-2` }
        rows={ invoices }
        columns={ columns }
        loading={ isLoading || isFetching }
        filterMode={ `server` }
        paginationMode={ `server` }
        sortingMode={ `server` }

        // Filter Opts
        filterDebounceMs={ 300 }
        onFilterModelChange={ setFilterModel }
        filterModel={ filterModel }
        headerFilters
        headerFilterHeight={ 54 }

        // Enable pagination
        pagination
        pageSizeOptions={ [10, 25, 50, 100] }
        onPaginationModelChange={ setPaginationModel }
        rowCount={ count }
        paginationModel={ paginationModel }

        // Sorting
        sortModel={ sortModel }
        onSortModelChange={ setSortModel }

        // Checkboxes
        checkboxSelection
        rowSelectionModel={ rowSelectionModel }
        onRowSelectionModelChange={ setRowSelectionModel }
        keepNonExistentRowsSelected

        // Styling
        getRowClassName={ getRowClassName }
        rowHeight={ 38 }
        disableColumnMenu
        columnHeaderHeight={ 38 }

        disableVirtualization

        // Slots & Customisation
        slots={ {
          toolbar: CustomToolbar,
        } }
        slotProps={ {
          toolbar: {
            showQuickFilter: true,
            rowSelectionModel,
          },
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
        } }
        sx={ {
          '--DataGrid-overlayHeight': `300px`,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: `2px solid #e0e0e0`,
          },
        } }
        localeText={ {
          toolbarColumns: `Hide Columns`,
          toolbarExport: rowSelectionModel.length > 0 ? `Export ${rowSelectionModel.length} row${rowSelectionModel.length === 1 ? `` : `s`}` : `Export`,
        } }
      />
    </div>
  );
}

function CustomToolbar({ rowSelectionModel }) {
  const currentOrg = useGetSelectedOrganisation();

  const csvOptions: GridCsvExportOptions = {
    fileName: `invoice_export_${currentOrg?.legalName}_${new Date().toISOString()}`,
    getRowsToExport: rowSelectionModel.length ? () => rowSelectionModel : undefined,
  };

  return (
    <GridToolbarContainer>
      <div className={ `flex justify-between w-full` }>
        <div className={ `pl-2` }>
          <GridToolbarQuickFilter
            placeholder={ `Quickly search number, contact or reference..` }
            quickFilterParser={ value => [value] }
            sx={ { width: 350 } }
            debounceMs={ 350 }
          />
          <InvoiceBulkActionsToolbar
            rowSelectionModel={ rowSelectionModel }
          />
        </div>

        <div className={ `flex flex-col items-end` }>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarColumnsButton />
            <GridToolbarExport
              csvOptions={ csvOptions }
              printOptions={ {
                disableToolbarButton: true,
              } }
            />
          </div>
        </div>
      </div>
    </GridToolbarContainer>
  );
}

// Custom component that renders the date filter input without a placeholder
function CustomDateFilterInput(props) {
  return (
    <GridFilterInputDate
      { ...props }
      label={ props.value ? props.label : ` ` } // Workaround for placeholder always showing
    />
  );
}
