import { langauge, StatementColumns, StatementLogoPosition } from "shared";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";

import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { Notification } from "../../../../../common/Atoms/Notification";
import { Select } from "../../../../../common/Atoms/Select";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";
import { fieldIsValid } from "../../../../lib/helper";
// import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { SaveAndError } from "../SaveAndError";
import { StatementPreview } from "../StatementPreview";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { classNames } from "../../../../../common/lib/classNames";
import { Transition } from "../../../../../common/Atoms/Transition";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { HelpIcon } from "../../../Molecules/HelpIcon";

const positionOptions = [
  {
    value: StatementLogoPosition.Right,
    label: `Right (Best for square logos)`,
  },
  {
    value: StatementLogoPosition.Top,
    label: `Top (Best for wide logos)`,
  },
];

const MAX_STATEMENT_COLUMNS = 8;

export function StatementRoot() {
  const {
    formData,
    onChange,
    hasUnsavedChanges,
    invalids,
  } = useGeneralSettingsForm();

  const currentOrg = useGetSelectedOrganisation();

  function onStatementColumnsChange(columnIndex: number, value: StatementColumns | null) {
    if (!formData.statementColumns) {
      return;
    }

    const newColumns = [...formData.statementColumns];

    newColumns[columnIndex] = {
      type: value,
      truncate: newColumns[columnIndex]?.truncate,
    };

    const removedNulls = newColumns.filter(c => c.type !== null);

    onChange(`statementColumns`, removedNulls);
  }

  function onStatementColumnsTruncateChange(columnIndex: number, value: boolean) {
    if (!formData.statementColumns) {
      return;
    }

    if (!formData.statementColumns[columnIndex]?.type) {
      return;
    }

    const newColumns = [...formData.statementColumns];

    newColumns[columnIndex] = {
      type: newColumns[columnIndex]?.type,
      truncate: value,
    };

    onChange(`statementColumns`, newColumns);
  }

  return (
    <Card>
      <CardContent className={ `pt-6` }>
        <Grid cols={ 12 }>
          <GridItem span={ 6 }>
            <Select
              name={ `statementLogoPosition` }
              label={ `Statement Logo Position` }
              options={ positionOptions }
              selected={ formData.statementLogoPosition }
              onChange={ v => onChange(`statementLogoPosition`, v.value) }
              invalid={ fieldIsValid(`statementLogoPosition`, invalids) }
            />
          </GridItem>

          <GridItem span={ 12 }>
            <Toggle
              label={ `Disable statement links` }
              description={ `Disable links in the statement PDFs to view the invoice in the Paidnice customer portal.` }
              name={ `disableStatementLinks` }
              checked={ formData.disableStatementLinks || false }
              onChange={ () => onChange(`disableStatementLinks`, !formData.disableStatementLinks) }
            />
          </GridItem>
          <GridItem span={ 12 }>

            <Toggle
              label={ `Always use legal name` }
              description={ `Always use your organisation's legal name, even when "Use Display Name" is enabled.` }
              name={ `statementAlwaysLegalName` }
              checked={ formData.statementAlwaysLegalName || false }
              onChange={ () => onChange(`statementAlwaysLegalName`, !formData.statementAlwaysLegalName) }
            />
          </GridItem>
          { /* Aged Analysis BreakDown */ }
          <GridItem span={ 12 }>
            <Toggle
              label={ `Show Aged Analysis Breakdown` }
              description={ `Show the aged analysis breakdown on the statement & portal.` }
              onChange={ () => onChange(`statementHideAgedBreakdown`, !formData.statementHideAgedBreakdown) }
              checked={ !formData.statementHideAgedBreakdown }
              name={ `statementHideAgedBreakdown` }
            />
            <Transition
              speed={ `slow` }
              show={ !formData.statementHideAgedBreakdown }
            >
              <div>
                <Paragraph
                  variant={ `help` }
                >
                  { `Adjust the brackets of the aged debts shown on the statement. Clear to remove the breakpoint.` }
                </Paragraph>
                <div className={ `grid grid-cols-3 gap-8` }>
                  <InputTextAddon
                    dataType={ `number` }
                    name={ `agedAnalysisBreakpointOne` }
                    description={ `Aged Analysis Breakpoint One` }
                    value={ formData.agedAnalysisBreakpointOne || `` }
                    onChange={ v => onChange(`agedAnalysisBreakpointOne`, (v.value)) }
                    invalid={ fieldIsValid(`agedAnalysisBreakpointOne`, invalids) }
                    addOnText={ `days` }
                  />

                  <InputTextAddon
                    dataType={ `number` }
                    name={ `agedAnalysisBreakpointTwo` }
                    description={ `Aged Analysis Breakpoint Two` }
                    value={ formData.agedAnalysisBreakpointTwo || `` }
                    onChange={ v => onChange(`agedAnalysisBreakpointTwo`, (v.value)) }
                    invalid={ fieldIsValid(`agedAnalysisBreakpointTwo`, invalids) }
                    addOnText={ `days` }
                    clearable
                  />

                  <InputTextAddon
                    dataType={ `number` }
                    name={ `agedAnalysisBreakpointThree` }
                    description={ `Aged Analysis Breakpoint Three` }
                    value={ formData.agedAnalysisBreakpointThree || `` }
                    onChange={ v => onChange(`agedAnalysisBreakpointThree`, (v.value)) }
                    invalid={ fieldIsValid(`agedAnalysisBreakpointThree`, invalids) }
                    addOnText={ `days` }
                    clearable
                  />
                </div>
              </div>
            </Transition>
          </GridItem>
        </Grid>

        <div className={ `mt-4` }>
          <Toggle
            label={ `Group statement lines` }
            description={ `Group the statement lines based on the days until due. ` }
            name={ `splitStatementOnDue` }
            checked={ formData.splitStatementOnDue || false }
            onChange={ () => onChange(`splitStatementOnDue`, !formData.splitStatementOnDue) }
          />
          <Transition
            speed={ `slow` }
            show={ formData.splitStatementOnDue || false }
          >
            <div>
              <InputTextAddon
                dataType={ `number` }
                name={ `splitStatementOnDueIndex` }
                description={ `Use 0 to split overdue & outstanding on the due date.` }
                value={ formData.splitStatementOnDueIndex }
                onChange={ v => onChange(`splitStatementOnDueIndex`, (v.value)) }
                invalid={ fieldIsValid(`splitStatementOnDueIndex`, invalids) }
                addOnText={ `days overdue` }
              />
            </div>
          </Transition>
        </div>

        { /* Statement Column Preferences */ }
        <div className={ `mt-6 mb-2` }>
          <FormLabel>
            { `Statement Column Preferences` }
          </FormLabel>
          <FormDescription>
            { `Choose which columns to show on your statements.` }
          </FormDescription>
        </div>
        <div>
          <div className={ `grid grid-cols-7 mb-2` }>
            <Paragraph
              variant={ `subHeading` }
              className={ `col-span-2` }
            >
              { `Column Position` }
            </Paragraph>

            <Paragraph
              variant={ `subHeading` }
              className={ `col-span-3` }
            >
              { `Column Type` }
            </Paragraph>

            <div className={ `col-span-1 flex justify-end` }>
              <Paragraph
                variant={ `subHeading` }
                className={ `text-right` }
              >
                { `Truncate` }
              </Paragraph>
              <HelpIcon
                tooltip={ `If the content doesn't fit in the column, it will be truncated. E.g "INV-12345..."` }
              />

            </div>
          </div>
          { /* Map through the max statement columns  */ }
          {
            Array.from({ length: MAX_STATEMENT_COLUMNS }, (_, i) => {
              const columnOptions = [...Object.keys(StatementColumns).map(key => {
                return {
                  value: StatementColumns[key],
                  label: langauge.settings.statement.statementColumnSettings[StatementColumns[key]].label,
                };
              })].filter(x => {
                if (currentOrg.accountingSystemType !== `QBO` && x.value === StatementColumns.QBOPrivateNote) {
                  return false;
                }

                return true;
              });

              const totalColumnsWithValues = formData.statementColumns?.filter(c => !!c.type).length;

              const showClear = totalColumnsWithValues > 5 && !!formData.statementColumns?.[i]?.type;

              return (
                <div
                  key={ `statement-col-${i}` }
                  className={ classNames(
                    `grid grid-cols-7 p-1 rounded-sm`,
                    i % 2 === 0 ? `bg-gray-100` : ``,
                  ) }
                >
                  <div className={ `col-span-2 flex items-center` }>
                    <Paragraph variant={ `secondary` }>
                      { `Column ${i + 1}` }
                    </Paragraph>
                  </div>

                  <div className={ `col-span-3` }>
                    <Select
                      selected={ formData.statementColumns?.[i]?.type || null }
                      options={ columnOptions }
                      onChange={ e => onStatementColumnsChange(i, e.value) }
                      emptyText={ `Nothing` }
                    />
                  </div>

                  <div className={ `col-span-1 flex items-center justify-end` }>
                    <Toggle
                      checked={ formData.statementColumns?.[i]?.truncate || false }
                      onChange={ e => onStatementColumnsTruncateChange(i, e.value) }
                      disabled={ !formData.statementColumns?.[i]?.type }
                    />
                  </div>

                  <div className={ `col-span-1 flex justify-end items-center mr-8` }>
                    <Tooltip
                      title={
                        showClear ? `Remove Column` : (
                          totalColumnsWithValues <= 5 ? `At least 5 columns must be used` : `Column is already cleared`
                        )
                      }
                    >
                      <a
                        onClick={ () => onStatementColumnsChange(i, null) }
                        className={ `cursor-pointer` }
                      >
                        <XMarkIcon className={ classNames(
                          `h-5 w-5`,
                          showClear ? `text-red-500` : `text-gray-300`,
                        ) } />

                      </a>
                    </Tooltip>
                  </div>
                </div>
              );
            })
          }
        </div>

        <Notification
          className={ `mt-6` }
          type={ `info` }
        >
          { `Logo and payment terms/footer are set under "general"` }
        </Notification>

        <div className={ `flex justify-end mt-2` }>
          <StatementPreview
            hasUnsavedChanges={ hasUnsavedChanges }
          />
        </div>
        <SaveAndError
          className={ `mt-2` }
        />
      </CardContent>
    </Card>
  );
}
