import { useCallback, useEffect, useMemo, useState } from "react";
import { toDecimal } from "shared";
import { Else, If, Then, When } from "react-if";
import { Tooltip } from "@mui/material";
import { DateTime } from "luxon";

import { Card } from "../../../../common/Atoms/Card";
import { useOrgMetrics } from "../../../hooks/useOrgMetrics";
import { Select } from "../../../../common/Atoms/Select";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { LoadingElementPlaceholder } from "../../../../common/Atoms/LoadingElementPlaceholder";
import { Grid } from "../../../../common/Atoms/Grid";
import { GridItem } from "../../../../common/Atoms/GridItem";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { classNames } from "../../../../common/lib/classNames";
import { DatePicker } from "../../../../common/Atoms/DatePicker";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";

interface InvoiceValueProps {
  groupId?: number;
  embedded?: boolean;
}

const defaultBalanceState = {
  amountOverdueCents: 0,
  amountOutstandingCents: 0,
  amountCreditCents: 0,
  averageDaysOverdue: 0,
  salesLast30DaysCents: 0,
  daysSalesOutstanding: 0,
  daysSalesOverdue: 0,
};

export function InvoiceValue({ groupId, embedded = false }: InvoiceValueProps) {
  const [currentCurrency, setCurrentCurrency] = useState(``);
  const [currentBalances, setCurrentBalances] = useState(defaultBalanceState);
  const currentOrg = useGetSelectedOrganisation();

  const timezone = currentOrg?.validatedTimezone || `UTC`;

  const [asOfDate, setAsOfDate] = useState(DateTime.now().setZone(timezone).toISODate() as string);
  const { data: metricsResponse, isLoading, isFetching } = useOrgMetrics(groupId, asOfDate);

  // When org changes, reset
  useEffect(() => {
    if (currentOrg) {
      const tz = currentOrg.validatedTimezone || `UTC`;

      setAsOfDate(DateTime.now().setZone(tz).toISODate() as string);
    }
  }, [currentOrg]);

  const metrics = metricsResponse?.metrics;

  const generatedDate = metricsResponse?.generatedAt
    ? DateTime.fromJSDate(new Date(metricsResponse.generatedAt)).toRelative()
    : ``;

  const possibleCurrencies = useMemo(() => {
    if (!metrics) return [];

    return Object.keys(metrics.balances);
  }, [metrics]);

  useEffect(() => {
    if (!currentCurrency && possibleCurrencies.length > 0) {
      setCurrentCurrency(possibleCurrencies[0]);
    }

    if (!possibleCurrencies.includes(currentCurrency)) {
      setCurrentCurrency(possibleCurrencies[0]);
    }
  }, [possibleCurrencies, currentCurrency]);

  useEffect(() => {
    if (!metrics) return;

    if (metrics.balances[currentCurrency]) {
      setCurrentBalances(metrics.balances[currentCurrency]);
    }
    else {
      // When no balance object at all, or balance object does not have the current currency, set to default
      setCurrentBalances(defaultBalanceState);
    }
  }, [currentCurrency, metrics]);

  const formatter = useCallback(() => {
    return new Intl.NumberFormat(`en-US`, {
      style: `currency`,
      currency: currentCurrency || `USD`,
    });
  }, [ currentCurrency ]);

  const asOfDateReadable = useMemo(() => {
    const date = DateTime.fromISO(asOfDate);

    if (date.hasSame(DateTime.now(), `day`)) {
      return `Today`;
    }

    return date.toISODate() as string;
  }, [ asOfDate ]);

  const selectors = (
    <div className={ classNames(
      `flex items-end`,
      embedded ? `flex-row space-x-2` :`flex-col space-y-2`,
    ) }>
      <DatePicker
        onChange={ date => setAsOfDate(date.value) }
        value={ asOfDate }
        displayValue={ asOfDateReadable }
        name={ `asOfDate` }
        disableClear
        className={ `w-32` }
      />

      <When condition={ possibleCurrencies.length > 1 }>
        <div className={ `w-32` }>
          <Select
            options={ possibleCurrencies.map(currency => ({ label: currency, value: currency })) }
            onChange={ value => setCurrentCurrency(value.value) }
            selected={ currentCurrency }
          />
        </div>
      </When>
    </div>
  );

  let colCount = 3; //default to 3

  if (embedded) {
    colCount = possibleCurrencies.length > 1 ? 7 : 6; // 7 columns if we need a currency selector
  }

  const renderMetricsContent = () => (
    <LoadingOverlay
      loading={ isFetching }
    >
      <div className={ `space-y-6 mt-4` }>
        <Grid
          cols={ colCount }
          colsSmallScreen= { 3 } // always keep it narrow on small screens
          gapX={ 4 }
          gapY={ 4 }
        >
          { /* Outstanding Balance */ }
          <GridItem>
            <div className={ `p-4 rounded-lg bg-gray-50` }>
              <Tooltip
                title={ `Total outstanding accounts receivable balance` }
                arrow
                placement={ `top` }
              >
                <div>  { /* Tooltip needs a div */ }
                  <Heading className={ `text-gray-500 text-sm` }>
                    { `Outstanding` }
                  </Heading>
                </div>
              </Tooltip>
              <Heading className={ `mt-1 text-sm xl:text-lg` }>
                { formatter().format(toDecimal(currentBalances.amountOutstandingCents)) }
              </Heading>
              { currentBalances.amountCreditCents !== 0 && (
                <span className={ `text-xs italic text-gray-800` }>
                  { `less ` }{ formatter().format(toDecimal(currentBalances.amountCreditCents)) } { `credit` }
                </span>
              ) }
            </div>
          </GridItem>

          { /* DSO */ }
          { currentBalances.daysSalesOutstanding > 0 && (
            <GridItem>
              <div className={ `p-4 rounded-lg bg-gray-50` }>
                <Tooltip
                  title={ `Days Sales Outstanding - Outstanding balance divided by average daily sales` }
                  arrow
                  placement={ `top` }
                >
                  <div>  { /* Tooltip needs a div */ }
                    <Heading className={ `text-gray-500 text-sm` }>
                      { `Days Sales Outstanding` }
                    </Heading>
                  </div>
                </Tooltip>
                <Heading className={ `mt-1 text-sm xl:text-lg` }>
                  { currentBalances.daysSalesOutstanding } { `days` }
                </Heading>
              </div>
            </GridItem>
          ) }

          { /* 30 Day Sales */ }
          { currentBalances.salesLast30DaysCents > 0 && (
            <GridItem>
              <div className={ `p-4 rounded-lg bg-gray-50` }>
                <Tooltip
                  title={ `Total sales last 30 days` }
                  arrow
                  placement={ `top` }
                >
                  <div>  { /* Tooltip needs a div */ }
                    <Heading className={ `text-gray-500 text-sm` }>
                      { `30 Day Sales` }
                    </Heading>
                  </div>
                </Tooltip>
                <Heading className={ `mt-1 text-sm xl:text-lg` }>
                  { formatter().format(toDecimal(currentBalances.salesLast30DaysCents)) }
                </Heading>
              </div>
            </GridItem>
          ) }

          { /* Overdue Balance */ }
          <GridItem>
            <div className={ `p-4 rounded-lg bg-gray-50` }>
              <Tooltip
                title={ `Total accounts receivable balance overdue` }
                arrow
                placement={ `top` }
              >
                <div>  { /* Tooltip needs a div */ }
                  <Heading className={ `text-gray-500 text-sm` }>
                    { `Overdue` }
                  </Heading>
                </div>
              </Tooltip>
              <Heading className={ `mt-1 text-sm xl:text-lg text-red-500` }>
                { formatter().format(toDecimal(currentBalances.amountOverdueCents)) }
              </Heading>
            </div>
          </GridItem>

          { /* Days Sales Overdue */ }
          { currentBalances.daysSalesOverdue > 0 && (
            <GridItem>
              <div className={ `p-4 rounded-lg bg-gray-50` }>
                <Tooltip title={ `Days Sales Overdue - Overdue balance divided by average daily sales` }>
                  <div>  { /* Tooltip needs a div */ }
                    <Heading className={ `text-gray-500 text-sm` }>
                      { `Days Sales Overdue` }
                    </Heading>
                  </div>
                </Tooltip>
                <Heading className={ `mt-1 text-sm xl:text-lg text-red-500` }>
                  { currentBalances.daysSalesOverdue } { `days` }
                </Heading>
              </div>
            </GridItem>
          ) }

          { /* Average Days Overdue */ }
          { currentBalances.averageDaysOverdue > 0 && (
            <GridItem>
              <div className={ `p-4 rounded-lg bg-gray-50` }>
                <Tooltip title={ `Average number of days overdue for all overdue invoices` }>
                  <div>
                    <Heading className={ `text-gray-500 text-sm` }>
                      { `Avg Overdue Age` }
                    </Heading>
                  </div>
                </Tooltip>
                <Heading className={ `mt-1 text-sm xl:text-lg` }>
                  { currentBalances.averageDaysOverdue } { `days` }
                </Heading>
              </div>
            </GridItem>
          ) }
        </Grid>
      </div>
    </LoadingOverlay>
  );

  const content =(
    <>
      <MultiButtonCardHeader
        helpText={ embedded ? `` : `These values may differ from your accounting system due to Credit Notes not being included and FX rates` }
        title={ embedded ? `` : `Metrics` }
        noDivider={ embedded }
        className={ classNames(
          embedded ? `px-0 sm:px-0 pt-2 pb-0` : ``,
        ) }
        description={ (
          <span>
            { `Receivables as of ${asOfDateReadable} ` }
          </span>
        ) }
        cta={ selectors }
      />
      <CardContent
        className={ `h-full` }
      >
        <If condition={ isLoading }>
          <Then>
            <LoadingElementPlaceholder rows={ 3 }
              border={ false } />
          </Then>
          <Else>
            <div className={ `` }>
              { renderMetricsContent() }

              <When condition={ generatedDate }>
                <div className={ `flex justify-center mt-4` }>
                  <Tooltip title={ `Updates every 10 minutes` }>
                    <span>
                      <Paragraph
                        variant={ `help` }
                        size={ `xs` }
                      >
                        { `Last generated ${generatedDate}` }
                      </Paragraph>
                    </span>
                  </Tooltip>
                </div>
              </When>
            </div>
          </Else>
        </If>
      </CardContent>
    </>
  );

  if (embedded) {
    return content;
  }

  return (
    <Card className={ `min-h-full` }>
      { content }
    </Card>
  );
}
