import { When } from "react-if";
import { MixpanelEvents } from "shared";
import { useNavigate } from "react-router";

import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { GROUPS_POLICIES_ARTICLE } from "../../../constants/links";
import { mixpanelService } from "../../../../common/lib/mixpanel";
import { ActionStatusBadge } from "../../Actions/ActionStatusBadge";
import { useActionTableModels } from "../../../hooks/useActions";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";

export function NewResultSection() {
  const { resultNew, applyLoading, view, close } = useAdhocActionModal();
  const { setCurrentTab, setSortModel, setFilterModel } = useActionTableModels();
  const navigate = useNavigate();
  const currentOrg = useGetSelectedOrganisation();

  if (view !== `result`) return null;

  const successActions = resultNew?.createdActions || [];
  const failedChecks = resultNew?.failed || [];

  function goToActions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    setCurrentTab(null);

    setSortModel([
      {
        field: `actionOn`,
        sort: `desc`,
      },
    ]);

    setFilterModel({
      items: [{
        field: `systemActions`,
        operator: `is`,
        value: true,
        id: Math.round(Math.random() * 1e5),
      }],
    });

    close();

    navigate(`/${currentOrg?.id}/actions?persist=true`);
  }

  return (
    <LoadingOverlay
      loading={ applyLoading }
      message={ `Applying policy now...` }
    >
      <div className={ `mt-4 min-h-full` }>
        { /* Empty State */ }
        <When condition={ !resultNew }>
          { () => (
            <div
              className={ `min-h-[250px] flex flex-col items-center justify-center` }
            >
              <div className={ `flex flex-col items-center border border-slate-50 rounded-lg p-12 shadow-sm` }>
                <Heading
                  variant={ `secondary` }
                >
                  { `Looking to automate this?` }
                </Heading>
                <a
                  href={ GROUPS_POLICIES_ARTICLE }
                  target={ `_blank` }
                  rel={ `noreferrer noopener` }
                  onClick={ () => mixpanelService.trackMixpanelEvent(MixpanelEvents.PreviewAutomateThisLinkClick) }
                >
                  <Paragraph
                    variant={ `link` }
                    className={ `mt-2` }
                  >
                    { `Use policies to configure automatic actions.` }
                  </Paragraph>
                </a>
              </div>
            </div>
          ) }
        </When>

        { /* List the success actions */ }
        <div>
          <When condition={ successActions.length }>
            <Paragraph
              variant={ `subHeading` }
              className={ `mb-2` }
            >
              { `The following actions have been queued. They will be run shortly.` }
            </Paragraph>
          </When>
          {
            successActions.map(action => {
              return (
                <div
                  key={ action.id + `-success` }
                  className={ `grid grid-cols-3 gap-3 border border-slate-100 rounded-lg p-2 mb-2` }
                >
                  <Paragraph
                    variant={ `secondary` }
                    className={ `self-center` }

                  >
                    { action.invoiceNumber || action.contactName }
                  </Paragraph>

                  <Paragraph
                    variant={ `help` }
                  >
                    <ActionStatusBadge
                      action={ action }
                      size={ `sm` }
                    />
                  </Paragraph>
                </div>
              );
            })
          }
          <When condition={ successActions.length }>
            <div className={ `flex justify-center mt-1 mb-3` }>
              <a
                href={ `#` }
                onClick={ goToActions }
              >
                <Paragraph
                  variant={ `link` }
                >
                  { `View on actions page` }
                </Paragraph>
              </a>
            </div>
          </When>
        </div>

        { /* List the failed reasons */ }
        <When condition={ failedChecks.length }>
          <div className={ `mt-2` }>
            <Paragraph
              variant={ `subHeading` }
              className={ `mb-2` }
            >
              { `These actions could not be created` }
            </Paragraph>

            {
              failedChecks.map(failed => {
                return (
                  <div
                    key={ failed.identifier + `-failed` }
                    className={ `grid grid-cols-2 gap-3 border border-slate-100 rounded-lg p-2 mb-2` }
                  >
                    <Paragraph
                      variant={ `secondary` }
                      className={ `self-center` }

                    >
                      { failed.identifier }
                    </Paragraph>

                    <Paragraph
                      variant={ `help` }
                    >
                      { failed.reason }
                    </Paragraph>
                  </div>
                );
              })
            }
          </div>
        </When>
      </div>

    </LoadingOverlay>

  );
}
