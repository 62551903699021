import { GridRowSelectionModel } from "@mui/x-data-grid";
import { useMemo } from "react";
import { ActionStatus, ActionType, IActionResponseItem, PolicyState } from "shared";
import { Button } from "@mui/material";
import { When } from "react-if";

import { useActions, useActionTableModels } from "../../hooks/useActions";
import { useApproveActionsMutation, useRegenerateChildActionsBulkMutation, useRevertApprovalsMutation } from "../../services/api/actionApi/action";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";

interface BulkUpdatesToolbarProps {
  rowSelectionModel: GridRowSelectionModel;
}

export function BulkUpdatesToolbar({
  rowSelectionModel,
}: BulkUpdatesToolbarProps) {
  const { data: actionsRes } = useActions();
  const [approveActions, { isLoading: approveActionsLoading }] = useApproveActionsMutation();
  const [revertApprovals, { isLoading: revertApprovalsLoading }] = useRevertApprovalsMutation();
  const [regenerateBulk, { isLoading: regenerateBulkLoading }] = useRegenerateChildActionsBulkMutation();
  const currentOrg = useGetSelectedOrganisation();
  const { currentTab } = useActionTableModels();

  const actionsDetail: {
    action: IActionResponseItem;
    canApprove: boolean;
    canRevertApproval: boolean;
    canRegenerateChildActions: boolean;
  }[] = useMemo(() => {
    return rowSelectionModel
      .map(id => {
        const matchedAction = actionsRes?.actions.find(action => action.id === id);

        if (!matchedAction) return null;

        return {
          action: matchedAction,
          canApprove: matchedAction.status === ActionStatus.APPROVAL_PENDING,
          canRevertApproval: matchedAction.status === ActionStatus.PENDING && matchedAction.policies.some(policy => policy.state === PolicyState.APPROVAL),
          canRegenerateChildActions: matchedAction.status === ActionStatus.COMPLETE && matchedAction.type === ActionType.LATE_FEE,
        };
      })
      .filter(action => action);
  }, [rowSelectionModel]);

  const hasLateFees = actionsRes?.actions.some(action => action.type === ActionType.LATE_FEE && action.status === ActionStatus.COMPLETE);

  const totalCanRevertApproval = actionsDetail.filter(action => action.canRevertApproval).length;
  const totalCanApprove = actionsDetail.filter(action => action.canApprove).length;
  const totalCanRegenerateChildActions = actionsDetail.filter(action => action.canRegenerateChildActions).length;

  function onApprove() {
    approveActions({
      organisationId: currentOrg.id,
      actionIds: actionsDetail.filter(action => action.canApprove).map(action => action.action.id),
      force: false,
    });
  }

  function onApproveAndForceRun() {
    approveActions({
      organisationId: currentOrg.id,
      actionIds: actionsDetail.filter(action => action.canApprove).map(action => action.action.id),
      force: true,
    });
  }

  function onRevertApproval() {
    revertApprovals({
      organisationId: currentOrg.id,
      actionIds: actionsDetail.filter(action => action.canRevertApproval).map(action => action.action.id),
    });
  }

  function onRegenerateBulk() {
    regenerateBulk({
      organisationId: currentOrg.id,
      actionIds: actionsDetail.filter(action => action.canRegenerateChildActions).map(action => action.action.id),
    });
  }

  return (
    <>
      { /* Only display on the draft or all tab */ }
      <When condition={ !currentTab || currentTab === ActionStatus.APPROVAL_PENDING }>
        <Button
          disabled={ totalCanApprove === 0 || approveActionsLoading }
          onClick={ onApprove }
        >
          {
            approveActionsLoading ?
              `Approving...` :
              `Approve ${ totalCanApprove } action${totalCanApprove === 1 ? `` : `s`}`
          }
        </Button>
      </When>

      <When condition={ !currentTab || currentTab === ActionStatus.APPROVAL_PENDING }>
        <Button
          disabled={ totalCanApprove === 0 || approveActionsLoading }
          onClick={ onApproveAndForceRun }
        >
          {
            approveActionsLoading ?
              `Approving to run now...` :
              `Approve and Run Now ${ totalCanApprove } action${totalCanApprove === 1 ? `` : `s`}`
          }
        </Button>
      </When>

      { /* Only display on the all, or approved tab */ }
      <When condition={ !currentTab || currentTab === ActionStatus.PENDING }>
        <Button
          disabled={ totalCanRevertApproval === 0 || revertApprovalsLoading }
          onClick={ onRevertApproval }
        >
          {
            revertApprovalsLoading ?
              `Reverting Approval...` :
              `Undo Approval ${ totalCanRevertApproval } action${totalCanRevertApproval === 1 ? `` : `s`}`
          }
        </Button>
      </When>

      { /* Only Display on the all or complete tab */ }
      <When condition={ (!currentTab || currentTab === ActionStatus.COMPLETE) && hasLateFees }>
        <Button
          disabled={ totalCanRegenerateChildActions === 0 || regenerateBulkLoading }
          onClick={ onRegenerateBulk }
        >
          {
            regenerateBulkLoading ?
              `Regenerating...` :
              `Regenerate emails for ${ totalCanRegenerateChildActions } action${totalCanRegenerateChildActions === 1 ? `` : `s`}`
          }
        </Button>
      </When>
    </>
  );
}
