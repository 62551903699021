import { IAdhocActionResponse, IInvoiceListResponse, IInvoiceParams, IInvoiceResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const invoiceApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchInvoices: builder.query<IInvoiceListResponse, IInvoiceParams & { organisationId: string }>({
      query: ({
        organisationId,
        page,
        sortColumn,
        sortType,
        invoiceTextSearch,
        xeroStatus,
        limit,
        frozen,
        groupId,
        contactId,
        reference,
        number,
        contactName,
        dueDateMaxDate,
        dueDateMinDate,
        issueDateMaxDate,
        issueDateMinDate,
        dueAmount,
        dueAmountOperator,
        totalAmount,
        totalAmountOperator,
        isLateFee,
        daysOverdue,
        daysOverdueOperator,
      }) => ({
        url: `invoice`,
        method: `GET`,
        params: {
          page,
          sortColumn,
          sortType,
          invoiceTextSearch,
          xeroStatus,
          limit,
          frozen,
          groupId,
          contactId,
          reference,
          number,
          contactName,
          dueDateMaxDate,
          dueDateMinDate,
          issueDateMaxDate,
          issueDateMinDate,
          dueAmount,
          dueAmountOperator,
          totalAmount,
          totalAmountOperator,
          isLateFee,
          daysOverdue,
          daysOverdueOperator,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      keepUnusedDataFor: 1, // TEMP WORKAROUND FOR REPEATED QUERIES CACHE BREAKING DATAGRID
      providesTags: result => result.invoices ?
        [
          ...result.invoices.map(({ invoice }) => ({ type: CacheTags.Invoices, id: invoice.id })),
          { type: CacheTags.Invoices, id: `PARTIAL-LIST` },
        ]
        : [{ type: CacheTags.Invoices, id: `PARTIAL-LIST` }],
    }),
    fetchInvoice: builder.query<IInvoiceResponse, { organisationId: string, invoiceId: string}>({
      query: ({
        organisationId,
        invoiceId,
      }) => ({
        url: `invoice/${invoiceId}`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: result => result &&
        [
          { type: CacheTags.Invoices, id: result.invoice?.id },
        ],
    }),
    actionInvoice: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        action,
        penaltyId,
        discountId,
        actionId,
      }) => ({
        url: `invoice/${invoiceId}/action`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          action,
          penaltyId,
          discountId,
          actionId,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) => response?.invoice,
      invalidatesTags: ({ id }) => {
        return [{ type: CacheTags.Invoices, id }, { type: CacheTags.Actions, id: `LIST` }];
      },
    }),
    actionInvoices: builder.mutation({
      query: ({
        organisationId,
        invoiceIds,
        action,
      }) => ({
        url: `invoice/action/${action}`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          invoiceIds,
        },
      }),
      invalidatesTags: ({ invoiceIds, action }) => {
        if (action === `sync-all`) {
          return [{ type: CacheTags.Organisations, id: `LIST` }];
        }

        return invoiceIds?.map(id => ({ type: CacheTags.Invoices, id }));
      },
    }),
    sendInvoiceReminder: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        templateId,
      }) => ({
        url: `invoice/${invoiceId}/send-reminder`,
        method: `GET`,
        params: {
          templateId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    aiCallNow: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        phoneNumber,
      }) => ({
        url: `invoice/${invoiceId}/ai-call-now`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          phoneNumber,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    applyPolicyPreview: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        policyId,
      }) => ({
        url: `invoice/${invoiceId}/preview-policy/${policyId}/apply`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    setExpectedPaymentDate: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        date,
      }) => ({
        url: `invoice/${invoiceId}/expected-payment-date`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          date,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    createNote: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        content,
        mentionedUsers,
      }) => ({
        url: `invoice/${invoiceId}/note`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          content,
          mentionedUsers,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }],
    }),
    adhocInvoiceEmail: builder.mutation<IAdhocActionResponse, { organisationId: string, invoiceId: string }>({
      query: ({ organisationId, invoiceId }) => ({
        url: `invoice/${invoiceId}/adhoc/reminder`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.BadInvoices, id: `LIST` },
      ],
    }),
    adhocInvoiceSms: builder.mutation<IAdhocActionResponse, { organisationId: string, invoiceId: string }>({
      query: ({ organisationId, invoiceId }) => ({
        url: `invoice/${invoiceId}/adhoc/sms`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.BadInvoices, id: `LIST` },
      ],
    }),
    adhocLateFee: builder.mutation<IAdhocActionResponse, { organisationId: string, invoiceId: string }>({
      query: ({ organisationId, invoiceId }) => ({
        url: `invoice/${invoiceId}/adhoc/latefee`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.BadInvoices, id: `LIST` },
        { type: CacheTags.Invoices, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchInvoiceQuery,
  useFetchInvoicesQuery,
  useActionInvoiceMutation,
  useActionInvoicesMutation,
  useSendInvoiceReminderMutation,
  useSetExpectedPaymentDateMutation,
  useApplyPolicyPreviewMutation,
  useAiCallNowMutation,
  useCreateNoteMutation,
  useAdhocInvoiceEmailMutation,
  useAdhocLateFeeMutation,
  useAdhocInvoiceSmsMutation,
} = invoiceApi;
