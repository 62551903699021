import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { IInvalidField, IPolicy, PolicyCalculationType, policyConfigV2, PolicyType, validatePolicySync, yupErrorMessageParse } from "shared";
import { toast } from "react-toastify";

import { RootState } from "../../../store";
import { closeAndClear, resetDirty, updateFormData } from "../../../slices/policyDrawer";
import { UnsavedChangesModal } from "../../Modals/UnsavedChanges";
import { useDeletePolicyMutation, useUpdatePolicyMutation } from "../../../services/api/policyApi/policy";
import { Modal } from "../../../../common/Atoms/Modal";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { Card } from "../../../../common/Atoms/Card";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";

import { HandleChange, PolicyItem } from "./PolicyItem";
import { PolicyTestViewRoot } from "./PolicyTestView/PolicyTestViewRoot";
import { PolicyTitle } from "./PolicyForm/Fields/PolicyTitle";

export function PolicyDrawer() {
  // Hooks
  const drawerProps = useSelector((s: RootState) => s.policyDrawer);
  const dispatch = useDispatch();
  const [updatePolicy, {
    isLoading: saveLoading,
    isSuccess: saveSuccess,
    reset: resetSave,
  }] = useUpdatePolicyMutation();
  const [deletePolicy, {
    isLoading: deleteLoading,
    isSuccess: deleteSuccess,
    reset: resetDelete,
  }] = useDeletePolicyMutation();

  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  /** Close the drawer, upon successful save */
  const [shouldCloseOnSuccess, setShouldCloseOnSuccess] = useState(false);
  const [ invalids, setInvalids ] = useState<IInvalidField[]>([]);

  const { open, policyFormData, formDataDirty } = drawerProps;

  // Validate on load
  useEffect(() => {
    if (policyFormData) {
      const pol = { ...policyFormData };

      validate(pol.policy);
    }
  }, [ policyFormData ]);

  // When the update was successful, close the drawer
  useEffect(() => {
    if ((saveSuccess || deleteSuccess) && shouldCloseOnSuccess) {
      resetStates();

      dispatch(closeAndClear());
    }
  }, [ saveSuccess, deleteSuccess, shouldCloseOnSuccess ]);

  const resourceType = useMemo(() => {
    if (!policyFormData?.policy) {
      return null;
    }

    return policyConfigV2[policyFormData.policy.policy_type]?.resourceType;
  }, [policyFormData?.policy]);

  function resetStates() {
    setShowUnsavedModal(false);
    setShouldCloseOnSuccess(false);
    setInvalids([]);
    resetSave();
    resetDelete();
  }

  // Event Handlers
  function onOpenClose(show: boolean) {
    if (show) {
      console.warn(`No op`);

      return;
    }

    if (formDataDirty) {
      setShowUnsavedModal(true);

      return;
    }

    resetStates();
    dispatch(closeAndClear());
  }

  function onCancelExit() {
    resetStates();
    setShowUnsavedModal(false);
  }

  function onConfirmExit() {
    resetStates();
    dispatch(closeAndClear());
  }

  function onSaveAndClose() {
    const valid = validate({ ...policyFormData.policy });

    if (valid) {
      save();
    }

    // Not valid, go back to form
    setShowUnsavedModal(false);
  }

  function validate(pol: IPolicy) {
    try {
      const valid = validatePolicySync(pol, false);

      setInvalids([]);

      return valid;
    }
    catch (err) {
      console.error(err);

      const errorMessages = yupErrorMessageParse(err);

      if (Array.isArray(errorMessages)) {
        setInvalids(errorMessages);
      }
      else {
        setInvalids([{
          message: `An Unknown Error has occured.`,
        }]);
      }

      return false;
    }
  }

  async function save(closeAfterSave = true) {
    const pol = { ...policyFormData };

    const valid = validate(pol.policy);

    if (valid) {
      // Update policy first before any adhoc actions
      const updated = await updatePolicy({
        id: policyFormData.policy.id,
        organisationId: pol.policy.organisation_id,
        ...pol,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = updated.data as any;

      if (data?.success) {
        toast.success(data.success, {
          autoClose: 4000,
        });
      }

      setShouldCloseOnSuccess(closeAfterSave);

      if (!closeAfterSave) {
        dispatch(resetDirty());
      }
    }
  }

  function onDelete() {
    deletePolicy({
      id: policyFormData.policy.id,
      organisationId: policyFormData.policy.organisation_id,
    });

    setShouldCloseOnSuccess(true);
  }

  const modalTitle = (
    <span className={ `flex` }>
      <ExclamationCircleIcon
        className={ `h-6 w-6 text-red-500 mr-1` }
      />
      { `You have unsaved changes!` }
    </span>
  );

  const handleChange: HandleChange = (e, isGroupsChange) => {
    const update = isGroupsChange ?
    // Only update group IDS
      {
        ...policyFormData,
        groupIds: e.value,
      } :
      // Otherwise update the policy object
      {
        ...policyFormData,
        policy: {
          ...policyFormData.policy,
          [e.name]: e.value,
        },
      };

    if (update.policy.policy_type === PolicyType.STATEMENT_LATE_FEE) {
      if (update.policy.calculation_type === PolicyCalculationType.NOT_APPLICABLE) {
        update.policy.percent = 0;
      }
    }

    dispatch(updateFormData(update));
  };

  if (!policyFormData || !policyFormData.policy) {
    return null;
  }

  const { policy } = policyFormData;

  return (
    <Modal
      width={ `stripe` }
      open={ open }
      setOpen={ onOpenClose }
      title={ `` }
      className={ `bg-gray-50` }
      // closeButton={ false }
    >
      <PolicyTitle
        policy={ policy }
        invalids={ invalids }
        handleChange={ handleChange }
      />
      <div className={ `grid grid-cols-1 md:grid-cols-12` }>
        <div className={ `sm:col-span-7` }>
          <PolicyItem
            onSave={ save }
            onDelete={ onDelete }
            invalids={ invalids }
            loading={ saveLoading || deleteLoading }
            handleChange={ handleChange }
          />
          <UnsavedChangesModal
            show={ showUnsavedModal }
            onCancel={ onCancelExit }
            onConfirm={ onConfirmExit }
            onSaveAndExit={ onSaveAndClose }
            title={ modalTitle }
            message={ `Are you sure you want to exit and discard your changes?` }
          />
        </div>

        { /* Preview / Result Side side */ }
        <div className={ `md:col-span-5 ml-2` }>
          <Card
            className={ `px-3 py-3 sm:p-3 mt-4` }
          >
            <Heading>
              { `Test & Preview` }
            </Heading>
            <Paragraph
              variant={ `help` }
            >
              { `Use this tool to test if this policy will apply to a particular ${resourceType}` }
            </Paragraph>

            <PolicyTestViewRoot
              onSave={ save }
            />

          </Card>
        </div>
      </div>
    </Modal>
  );
}
