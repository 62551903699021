import { useParams } from "react-router";
import { Case, Switch, When } from "react-if";
import { Tooltip } from "@mui/material";
import { ActionType, InvoiceListItem, MixpanelEvents, MixpanelLocationTypes, toDecimal } from "shared";
import { BanknotesIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import { ChatBubbleLeftEllipsisIcon, EnvelopeIcon } from "@heroicons/react/20/solid";

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { useFetchBadInvoicesMetricsQuery } from "../../../services/api/organisationApi/organisation";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { LoadingElementPlaceholder } from "../../../../common/Atoms/LoadingElementPlaceholder";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { WrappedLink } from "../../WrappedLink";
import { classNames } from "../../../../common/lib/classNames";
import { Button } from "../../../../common/Atoms/Button";
import { Badge } from "../../../../common/Atoms/Badge";
import { mixpanelService } from "../../../../common/lib/mixpanel";
import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";

function renderCurrency(value: number, currencyCode: string) {
  const formatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: currencyCode,
  });

  return formatter.format(toDecimal(value));
}

export function BadInvoices() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { openSendInvoiceEmail, openIssueLateFee, openSendSms, openBulkReminders, openBulkLateFees } = useAdhocActionModal();
  const { data: response, isLoading } = useFetchBadInvoicesMetricsQuery({ orgId: organisationId }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
  });

  function onSendReminder(invoice: InvoiceListItem) {
    openSendInvoiceEmail(invoice.invoice.id, invoice.invoice.number);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.DashboardSendReminderNowClick, {
      location: MixpanelLocationTypes.Dashboard,
    });
  }

  function onIssueLateFee(invoice: InvoiceListItem) {
    openIssueLateFee(invoice.invoice.id, invoice.invoice.number);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.DashboardIssueLateFeeNowClick, {
      location: MixpanelLocationTypes.Dashboard,
    });
  }

  function onSendSms(invoice: InvoiceListItem) {
    openSendSms(invoice.invoice.id, invoice.invoice.number);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.DashboardSendSmsNowClick, {
      location: MixpanelLocationTypes.Dashboard,
    });
  }

  function onRemindAll() {
    if (response?.invoices?.length) {
      const invoiceIds = response.invoices.map(invoice => invoice.invoice.id);
      openBulkReminders(invoiceIds);

      mixpanelService.trackMixpanelEvent(MixpanelEvents.BulkAdhocAction, {
        location: MixpanelLocationTypes.Dashboard,
        action_type: ActionType.REMINDER,
      });
    }
  }

  function onMultipleLateFees() {
    if (response?.invoices?.length) {
      const invoiceIds = response.invoices.map(invoice => invoice.invoice.id);
      openBulkLateFees(invoiceIds);

      mixpanelService.trackMixpanelEvent(MixpanelEvents.BulkAdhocAction, {
        location: MixpanelLocationTypes.Dashboard,
        action_type: ActionType.LATE_FEE,
      });
    }
  }

  return (
    <>
      <Card className={ `w-full h-full` }>
        <MultiButtonCardHeader
          title={ `Oldest Invoices` }
          description={ `Invoices that are overdue by the most days` }
          buttons={ [
            {
              buttonText: `Send ${response?.invoices?.length || 0} reminder emails`,
              onClick: onRemindAll,
              disabled: !response?.invoices?.length,
            },
            {
              buttonText: `Issue ${response?.invoices?.length || 0} late fees`,
              onClick: onMultipleLateFees,
              disabled: !response?.invoices?.length,
            },
          ] }
          buttonsAsDropdownText={ `Quick Actions` }
          buttonDropdownSize={ `sm` }
          buttonDropdownTheme={ `outline` }
        />
        <CardContent className={ `mt-4` }>
          <Switch>
            { /* Loading Placeholder */ }
            <Case condition={ isLoading }>
              <LoadingElementPlaceholder
                border={ false }
                rows={ 6 }
              />
            </Case>

            { /* No Bad Invoices */ }
            <Case condition={ !response?.invoices?.length }>
              <div className={ `min-h-[200px] h-full flex flex-col items-center justify-center text-center` }>
                <FaceSmileIcon className={ `w-12 h-12 mx-auto text-gray-300` } />
                <Paragraph variant={ `secondary` }>
                  { `You have no overdue invoices!` }
                </Paragraph>
              </div>
            </Case>

            { /* Bad Invoices */ }
            <Case condition={ response?.invoices?.length }>
              <div className={ `space-y-1` }>
                { response?.invoices?.map(invoice => {
                  return (
                    <div
                      className={ classNames(
                        `grid grid-cols-3 pb-1`,
                      ) }
                      key={ invoice.invoice.id }
                    >
                      { /* Name link */ }
                      <div className={ `flex items-center space-x-1` }>
                        <WrappedLink
                          to={ `/invoices/${invoice.invoice.id}` }
                        >
                          <Paragraph
                            variant={ `link` }
                            className={ `truncate` }
                          >
                            { invoice.invoice.number || `[No Number]` }
                          </Paragraph>
                        </WrappedLink>

                        <When condition={ invoice.invoice.createdByUs }>
                          { /* Is a late fee invoice */ }
                          <Tooltip
                            title={ `This invoice was created as a late fee` }
                          >
                            <BanknotesIcon className={ `w-4 h-4 text-orange-400` } />
                          </Tooltip>
                        </When>
                      </div>

                      { /* Balances */ }
                      <Tooltip title={ `The due amount remaining out of ${renderCurrency(invoice.invoice.totals.totalInCents, invoice.invoice.currency)}` }>
                        <div className={ `max-w-fit place-self-center` }>
                          <Paragraph
                            className={ `text-red-500 font-normal` }
                          >
                            { renderCurrency(invoice.invoice.totals.dueInCents, invoice.invoice.currency) }
                          </Paragraph>
                        </div>
                      </Tooltip>

                      <div className={ classNames(
                        `col-span-1 flex justify-end mb-1`,
                      ) }>
                        <div>
                          <Tooltip title={ `Send Reminder Email` }>
                            <Button
                              size={ `sm` }
                              color={ `lateGreenOutline` }
                              className={ `font-light rounded-r-none rounded-l-md` }
                              onClick={ () => onSendReminder(invoice) }
                            >
                              <EnvelopeIcon className={ `w-4 h-4 m-1` } />
                              { /* { `Email` } */ }
                            </Button>
                          </Tooltip>
                          <Tooltip title={ `Send SMS` }>
                            <Button
                              size={ `sm` }
                              color={ `lateGreenOutline` }
                              className={ `font-light rounded-l-none rounded-r-md` }
                              onClick={ () => onSendSms(invoice) }
                            >
                              <ChatBubbleLeftEllipsisIcon className={ `w-4 h-4 m-1` } />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>

                      <div className={ `max-w-fit place-self-start` }>
                        <WrappedLink
                          to={ `/contacts/${invoice.invoice.contactId}` }
                        >
                          <Paragraph
                            className={ `font-light text-xs` }
                            variant={ `link` }
                          >
                            { invoice.contact.name }
                          </Paragraph>
                        </WrappedLink>
                      </div>

                      { /* Age */ }
                      <div className={ `max-w-fit place-self-center` }>
                        <Tooltip title={ `Due On: ${invoice.invoice.dueDate}` }>
                          <Badge
                            color={ `red` }
                          >
                            { invoice.invoice.daysOverdue }
                            { ` days` }
                          </Badge>
                        </Tooltip>
                      </div>

                      <div className={ classNames(
                        `col-span-1 flex justify-end`,
                      ) }>
                        <Button
                          size={ `sm` }
                          color={ `lateGreenOutline` }
                          className={ `font-light` }
                          onClick={ () => onIssueLateFee(invoice) }
                          disabled={ invoice.invoice.createdByUs }
                          tooltip={ invoice.invoice.createdByUs ? `This invoice was created as a late fee` : undefined }
                        >
                          { `Issue Late Fee` }
                        </Button>
                      </div>
                    </div>
                  );
                }) }
              </div>
            </Case>
          </Switch>
        </CardContent>
      </Card>
    </>
  );
}
