import { PolicyIncludeExcludeType } from "shared";
import { useMemo } from "react";

import { fieldIsValid } from "../../../../../lib/helper";
import { Select } from "../../../../../../common/Atoms/Select";
import { MultiSelect } from "../../../../../../common/Atoms/MultiSelect";
import { useContacts } from "../../../../../hooks/useContacts";

import { IFieldProps } from "./fieldProps";

export function XeroContactsGroupFilter({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const { data: contacts } = useContacts();
  const selectedGroups = policyFormData.xero_group_name?.split(`,`) || [];

  const externalAggregateNameOptions = useMemo(() => {
    if (!contacts || !contacts.externalAggregateNames) return [];

    const result = contacts.externalAggregateNames.filter(name => !!name).map(name => ({
      label: name,
      value: name,
    }));

    // Make sure selected ones also appear in the list
    selectedGroups.forEach(group => {
      if (!result.some(r => r.value === group)) {
        result.push({ label: group, value: group });
      }
    });

    return result;
  }, [contacts, selectedGroups]);

  if (!policyFormData.xero_group_name_exclude_include) {
    handleChange({
      name: `xero_group_name_exclude_include`,
      value: PolicyIncludeExcludeType.INCLUDE,
    });
  }

  function onGroupsChange(tags: string[]) {
    if (!tags.length) {
      handleChange({
        name: `xero_group_name`,
        value: null,
      });

      return;
    }

    handleChange({
      name: `xero_group_name`,
      value: tags.filter(f => f).join(`,`),
    });
  }

  return (
    <div>
      <div className={ `flex justify-between items-center space-x-4` }>
        <div className={ `w-full` }>
          <Select
            options={ [
              { value: PolicyIncludeExcludeType.EXCLUDE, label: `Exclude contacts in Xero group(s):` },
              { value: PolicyIncludeExcludeType.INCLUDE, label: `Include only contacts in Xero group(s):` },
            ] }
            selected={ policyFormData.xero_group_name_exclude_include }
            onChange={ handleChange }
            name={ `xero_group_name_exclude_include` }
            invalid={ fieldIsValid(`xero_group_name_exclude_include`, invalids) }
            valueClassname={ `text-gray-500` }
          />
        </div>

        <div>
          <MultiSelect
            options={ externalAggregateNameOptions }
            selected={ selectedGroups }
            onChange={ onGroupsChange }
            invalid={ fieldIsValid(`xero_group_name`, invalids) }
            display={ `${selectedGroups.length} groups selected` }
            valueClassname={ `text-gray-500` }
          />
        </div>
      </div>
    </div>
  );
}
