import { If, Then, Else, When } from "react-if";
import { useEffect, useState } from "react";
import { IInvalidField, IStripeAccountUpdate, StripeAccountConnectionStatus, stripeAccountSchema, yupErrorMessageParse } from "shared";

import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { env } from "../../../../../common/lib/env";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import stripeButton from "../../../../assets/stripe.svg";
import stripeLogo from "../../../../assets/stripe-logo.svg";
import { useDisconnectStripeAccountMutation, useUpdateStripeAccountMutation } from "../../../../services/api/stripeAccountApi/stripeAccount";
import { AccountSelector } from "../../Policies/PolicyForm/AccountSelector";
import { Button } from "../../../../../common/Atoms/Button";
import { Transition } from "../../../../../common/Atoms/Transition";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { CurrencyInput } from "../../../../../common/Atoms/CurrencyInput";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../lib/helper";
import { useAccountingResources } from "../../../../hooks/useAccountingResources";
import { Notification } from "../../../../../common/Atoms/Notification";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { useAccountingSystemTerm } from "../../../../hooks/useAccountingSystemTerm";
import { TaxSelector } from "../../Policies/PolicyForm/TaxSelector";

export function StripeConnect() {
  const currentOrg = useGetSelectedOrganisation();

  const [updateFn, { isLoading }] = useUpdateStripeAccountMutation();
  const [disconnectFn, { isLoading: isDisconnectLoading }] = useDisconnectStripeAccountMutation();
  const [formData, setFormData] = useState(currentOrg.stripeAccount || {} as IStripeAccountUpdate);
  const [ invalids, setInvalids ] = useState<IInvalidField[]>([]);
  const { data: accountingResources, isLoading: resourcesLoading } = useAccountingResources();

  useEffect(() => {
    if (currentOrg.stripeAccount) {
      setFormData(currentOrg.stripeAccount);
    }
  }, [currentOrg.stripeAccount]);

  async function onSave() {

    const valid = await validate(formData);

    console.log(`valid: ${valid}`);
    if (valid) {
      updateFn({
        orgId: currentOrg.id,
        stripeAccountId: currentOrg.stripeAccount?.id,
        update: formData,
      });
    }
  }

  function onChange(field: keyof IStripeAccountUpdate, value: string | number | boolean) {
    setFormData({
      ...formData,
      [field]: value,
    });
  }

  function onDisconnect() {
    disconnectFn({
      orgId: currentOrg.id,
      stripeAccountId: currentOrg.stripeAccount?.id,
    });
  }

  async function validate(stripeAccount: IStripeAccountUpdate) {
    if (!accountingResources?.accounts) {
      // Still loading..
      console.log(`Still loading resources`);

      return false;
    }

    try {
      const valid = await stripeAccountSchema.validate(stripeAccount, {
        abortEarly: false,
        strict: false,
      });

      setInvalids([]);

      return valid;
    }
    catch (err) {
      console.error(err);

      const errorMessages = yupErrorMessageParse(err);

      if (Array.isArray(errorMessages)) {
        setInvalids(errorMessages);
      }
      else {
        setInvalids([{
          message: `An Unknown Error has occured.`,
        }]);
      }

      return false;
    }
  }

  function onMappingChange({ currencyCode, stripePaymentsAccountExternalId }: { currencyCode: string, stripePaymentsAccountExternalId: string }) {
    setFormData({
      ...formData,
      paymentMappings: formData.paymentMappings.map(m => {
        if (m.currencyCode === currencyCode) {
          return {
            currencyCode,
            stripePaymentsAccountExternalId,
          };
        }

        return m;
      }),
    });
  }

  if (!currentOrg) return null;

  return (
    <TwoColFormRow
      heading={ `` }
      description={ <img src={ stripeLogo }
        className={ `h-10` } /> }
    >
      <Notification
        type={ `pro` }
      >
        { `You have early access to integrated Stripe payments. Please let us know if there is anything we can improve on!` }
      </Notification>
      <div className={ `mt-4` } />
      <If condition={ currentOrg.stripeAccount?.connectionStatus === StripeAccountConnectionStatus.CONNECTED }>
        <Then>

          <div className={ `flex justify-between` }>
            <Paragraph
              variant={ `secondary` }
            >
              { `${currentOrg.stripeAccount?.name}` }
            </Paragraph>
            <div className={ `flex flex-col items-end` }>
              <Button
                color={ `red` }
                onClick={ onDisconnect }
                loading={ isDisconnectLoading }
                disabled={ isDisconnectLoading }
              >
                { `Disconnect` }
              </Button>
              <When condition={ currentOrg?.stripeAccount?.otherOrgCount > 0 }>
                <Paragraph
                  className={ `max-w-[70%] text-right` }
                  variant={ `secondary` }
                >
                  { `Will disconnect for ${currentOrg?.stripeAccount?.otherOrgCount} other organisations using this Stripe Account in Paidnice!` }
                </Paragraph>
              </When>
            </div>
          </div>

          { /* Mappings */ }

          { /* TODO: Update text and messaging for QBO */ }
          <div className={ `space-y-3` }>
            <FormLabel>
              { `Bank Account For Stripe Revenue` }
            </FormLabel>
            <FormDescription>
              { `This account will have the invoice payment marked against it. Set it to the same account Stripe income is received at. We recommend using the Stripe bank feed.` }
            </FormDescription>
            {
              formData.paymentMappings?.map((m, i) => {
                return (

                  <div
                    className={ `grid grid-cols-6 items-center` }
                    key={ i + m.currencyCode }
                  >
                    <Paragraph
                      className={ `col-span-2` }
                    >
                      { m.currencyCode }
                    </Paragraph>
                    <div className={ `col-span-4` }>
                      <AccountSelector
                        name={ `stripePaymentsAccountExternalId` }
                        value={ m.stripePaymentsAccountExternalId }
                        onChange={ e => onMappingChange({ currencyCode: m.currencyCode, stripePaymentsAccountExternalId: e.value }) }
                        type={ `bank` }
                        valueKey={ `id` }
                        onlyCurrency={ m.currencyCode }
                        useLedgerAccounts={ true }
                      />
                    </div>
                  </div>
                );
              })
            }

            <AccountSelector
              label={ `Stripe Fees Expense Account` }
              description={ `This account will have the Stripe fees recorded against it.` }
              name={ `stripeFeeAccountExternalId` }
              value={ formData.stripeFeeAccountExternalId }
              onChange={ e => onChange(`stripeFeeAccountExternalId`, e.value) }
              type={ `expense` }
              valueKey={ `id` }
              invalid={ fieldIsValid(`stripeFeeAccountExternalId`, invalids) }
              useLedgerAccounts={ true }
            />

            { /* only show tax for QBO organisations */ }
            <When condition={ currentOrg.accountingSystemType === `QBO` }>
              <TaxSelector
                label={ useAccountingSystemTerm(`Tax Rate`) }
                code={ formData.stripeFeeTaxExternalId }
                onChange={ e => onChange(`stripeFeeTaxExternalId`, e.value) }
                name={ `stripeFeeTaxExternalId` }
                invalid={ fieldIsValid(`stripeFeeTaxExternalId`, invalids) }
                description={ `The tax rate on Stripe fees (if any).` }
                requireAndSupressDefault={ true }
              />
            </When>

            <Toggle
              label={ `Charge a processing fee?` }
              description={ `In some jurisdictions, charging processing fees to your customers is prohibited by law. It is your responsibility to act in accordance with applicable law.` }
              // helpIcon={ `The fee cannot currently be calculated in advance, so set this to your known Stripe fees for your region.` }
              name={ `surchargeEnabled` }
              checked={ formData.surchargeEnabled }
              onChange={ e => onChange(`surchargeEnabled`, e.value) }
            />

            <Transition
              speed={ `slow` }
              show={ !!formData.surchargeEnabled }
            >
              <div>
                <Grid cols={ currentOrg.accountingSystemType === `QBO` ? 2 : 3 }>
                  <GridItem
                    span={ 1 }
                    position={ `bottom` }
                  >
                    <InputTextAddon
                      label={ `Fee Percentage` }
                      name={ `surchargePercentage` }
                      value={ formData.surchargePercentage }
                      placeholder={ `1.75` }
                      onChange={ e => onChange(`surchargePercentage`, e.value) }
                      invalid={ fieldIsValid(`surchargePercentage`, invalids) }
                      addOnText={ `%` }
                    />
                  </GridItem>
                  <GridItem
                    span={ 1 }
                    position={ `bottom` }
                  >
                    <CurrencyInput
                      label={ `Fee Flat Rate Amount` }
                      name={ `surchargeFlatRateCents` }
                      value={ formData.surchargeFlatRateCents }
                      onChange={ e => onChange(`surchargeFlatRateCents`, e.value) }
                      invalid={ fieldIsValid(`surchargeFlatRateCents`, invalids) }
                    />
                  </GridItem>

                  <GridItem
                    span={ 1 }
                    position={ `bottom` }
                  >
                    <AccountSelector
                      label={ `Fee Revenue Account` }
                      helpIcon={ `The revenue account to record the income from processing fees against` }
                      value={ formData.stripeSurchargesAccountExternalId }
                      onChange={ e => onChange(`stripeSurchargesAccountExternalId`, e.value) }
                      type={ `income` }
                      valueKey={ `id` }
                      name={ `stripeSurchargesAccountExternalId` }
                      invalid={ fieldIsValid(`stripeSurchargesAccountExternalId`, invalids) }
                    />
                  </GridItem>
                  { /* only show tax for QBO organisations */ }
                  <When condition={ currentOrg.accountingSystemType === `QBO` }>
                    <GridItem
                      span={ 1 }
                      position={ `bottom` }
                    >
                      <TaxSelector
                        label={ `Fee Revenue ` + useAccountingSystemTerm(`Tax Rate`) }
                        code={ formData.stripeSurchargesTaxExternalId }
                        onChange={ e => onChange(`stripeSurchargesTaxExternalId`, e.value) }
                        name={ `stripeSurchargesTaxExternalId` }
                        invalid={ fieldIsValid(`stripeSurchargesTaxExternalId`, invalids) }
                        requireAndSupressDefault={ true }
                      />
                    </GridItem>
                  </When>
                </Grid>
              </div>
            </Transition>
          </div>
          { /* Error Section */ }
          <When condition={ invalids.length > 0 }>
            { () => (
              <Notification
                className={ `mt-6` }
                type={ `error` }
              >
                {
                  invalids[0].message
                }
              </Notification>
            ) }
          </When>
          { /* Button Row */ }
          <div className={ `flex justify-end pt-6` }>
            <Button
              onClick={ onSave }
              loading={ isLoading }
              disabled={ isLoading || resourcesLoading }
            >
              { `Save` }
            </Button>
          </div>
        </Then>
        <Else>
          <a
            href={ `${env.baseApiUrl}stripe/connect/org/${currentOrg.id}` }
            target={ `_blank` }
            rel={ `noopener noreferrer` }
          >
            <img
              src={ stripeButton }
              className={ `h-10` }
            />
          </a>
        </Else>
      </If>

    </TwoColFormRow>
  );
}
