import { Button } from "../../../../common/Atoms/Button";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { ContactTableFields, useContactTableModels } from "../../../hooks/useContacts";

export function ContactQuickFilterToolbar() {
  const { setFilterModel, setSortModel } = useContactTableModels();

  function onQuickFilter(type: string) {
    console.log(`Quick filter type: ${type}`);
    if (type === `has_balance`) {
      setFilterModel({
        items: [
          {
            field: ContactTableFields.outstandingBalance,
            operator: `>=`,
            value: 0.01,
            id: Math.round(Math.random() * 1e5),
          },
        ],
      });

      setSortModel([
        {
          field: ContactTableFields.outstandingBalance,
          sort: `desc`,
        },
      ]);
    }
  }

  return (
    <div>
      <Paragraph
        variant={ `help` }
        size={ `xs` }>
        {
          `Quick Filters`
        }
      </Paragraph>
      <div className={ `flex space-x-1` }>
        <Button
          onClick={ () => onQuickFilter(`has_balance`) }
          size={ `sm` }
          color={ `lateGreenOutline` }
        >
          {
            `Has Balance`
          }
        </Button>
      </div>
    </div>
  );
}
