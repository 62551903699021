
import { useEffect } from 'react';
import { When } from 'react-if';
import { ISenderDetail } from 'shared';

import { classNames }  from '../../../../common/lib/classNames';
import { useInvoice } from '../../../hooks/useInvoice';
import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { Strap } from '../../../../common/Atoms/Strap';
import { useActionInvoiceMutation } from '../../../services/api/invoiceApi/invoice';
import { InvoiceDocumentCommon } from '../../../../common/Components/Invoice/InvoiceDocument';
import { useInvoiceDetails } from '../../../../common/hooks/useInvoiceDetails';
import { useSettings } from '../../../hooks/useSettings';

import { InvoiceActions } from './InvoiceActions';
import { ActionOverview } from './InvoiceSections/ActionOverview';
import { InvoiceEvents } from './InvoiceEvents';

export function InvoiceDocument() {
  const { invoice } = useInvoice();
  const organisation = useGetSelectedOrganisation();
  const { data: settings } = useSettings();
  const [ actionInvoice, { isLoading: actionLoading } ] = useActionInvoiceMutation();
  const invoiceDetails = useInvoiceDetails(invoice);

  const senderDetail: ISenderDetail = {
    customerFacingCompanyName: organisation.legalName,
    companyNumber: settings?.companyNumber,
    companyNumberType: settings?.companyNumberType,
    taxNumber: settings?.taxNumber,
  };

  useEffect(() => {
    onResync();
  }, []);

  function onResync() {
    actionInvoice({
      organisationId: organisation.id,
      invoiceId: invoice.id,
      action: `resync`,
    });
  }

  if (!invoiceDetails) return null;

  return (
    <div>
      <div className={
        classNames(
          `mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3`,
        )
      }>
        <InvoiceActions
          invoiceDetails={ invoiceDetails }
        />

        { /* Invoice */ }
        <div className={
          classNames(
            ` bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg lg:col-span-2 lg:row-span-2 lg:row-end-2 `,
          )
        }>
          <When condition={ invoiceDetails.createdByUs }>
            <Strap
              type={ `warning` }
              className={ `sm:rounded-t-lg` }
            >
              { `This invoice was created by Paidnice. No invoice based late fee or discount policies will apply to it` }
            </Strap>
          </When>

          <When condition={ invoiceDetails.frozen }>
            <Strap
              type={ `frozen` }
              className={ classNames(
                invoiceDetails.createdByUs ? `` : `sm:rounded-t-lg`,
              ) }
            >
              { `This invoice is frozen. No policies will apply to it` }
            </Strap>
          </When>

          <When condition={ invoiceDetails.deleted }>
            <Strap
              type={ `error` }
              className={ classNames(
                (invoiceDetails.createdByUs || invoiceDetails.frozen) ? `` : `sm:rounded-t-lg`,
              ) }
            >
              { `This invoice is deleted.` }
            </Strap>
          </When>

          <InvoiceDocumentCommon
            invoice={ invoice }
            senderDetail={ senderDetail }
            onResync={ onResync }
            resyncLoading={ actionLoading }
            mode={ `app` }
            contactFrozen={ invoice?.contact?.frozen }
          />
        </div>

        <ActionOverview />

      </div>
      <InvoiceEvents />
    </div>
  );
}
