import { CheckCircleIcon, FaceFrownIcon, FaceSmileIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { currencyFormatter, QuoteEventType, toDecimal } from "shared";

export function getQuoteIcon(type: QuoteEventType) {
  switch (type) {
  case QuoteEventType.IMPORTED:
    return CheckCircleIcon;
  case QuoteEventType.ACCEPTED:
    return FaceSmileIcon;
  case QuoteEventType.DECLINED:
    return FaceFrownIcon;
  case QuoteEventType.SENT:
    return PaperAirplaneIcon;
  default:
    return FaceSmileIcon;
  }
}

export function formatAmount(amount: number, currency: string) {
  const formatter = currencyFormatter(currency);

  const isNegative = amount < 0;

  const result = formatter(toDecimal(amount));

  if (isNegative) {
    return `(${result.replace(`-`, ``)})`;
  }

  return formatter(toDecimal(amount));
}
