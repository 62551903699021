import { useGetSubscriptionMetadata } from '../hooks/useGetSubscription';
import { Notification } from '../../common/Atoms/Notification';
import { useGetSelectedOrganisation } from '../hooks/useGetSelectedOrganisation';
import { Button } from '../../common/Atoms/Button';
import { env } from '../../common/lib/env';

export function NotificationArea() {
  const currentOrg = useGetSelectedOrganisation();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();

  if (!currentOrg) return null;

  if (currentOrg?.connected) return null;

  if (!subscriptionMetadata) return null;

  function getReconnectLink() {
    if (currentOrg.accountingSystemType === `XERO`) {
      return `${env.apiUrl}/connect/xero`;
    }

    if (currentOrg.accountingSystemType === `QBO`) {
      return `${env.apiUrl}/connect/qbo`;
    }

    return ``;
  }

  return (
    <>
      <div className={ `pb-4` }>
        <Notification
          type={ `error` }
          title={ `Attention` }
          buttons={ (
            <a
              href={ getReconnectLink() }
            >
              <Button
                color={ `orangeOutline` }
              >
                { `Reconnect` }
              </Button>
            </a>
          ) }
        >
          { `Organisation is not connected` }
        </Notification>
      </div>
    </>
  );
}
