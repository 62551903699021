import { GridFilterModel, GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import { ContactStatus } from 'shared';

// Set defaults here
export const initialState: {
  filterModel: GridFilterModel
  sortModel: GridSortItem[];
  paginationModel: GridPaginationModel;
} = {
  filterModel: {
    items: [
      {
        field: `status`,
        operator: `is`,
        value: ContactStatus.ACTIVE,
        id: Math.round(Math.random() * 1e5),
      },
    ],
  },
  sortModel: [],
  paginationModel: {
    page: 0,
    pageSize: 100,
  },
};

const contactTableModelsSlice = createSlice({
  name: `contactTableModels`,
  initialState,
  reducers: {
    setFilterModel: (state, action) => {
      const parsed = action.payload.items.map(item => {
        if (item.value instanceof Date) {
          return {
            ...item,
            value: item.value.toISOString(),
          };
        }

        return item;
      });
      action.payload.items = parsed;
      state.filterModel = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    reset: () => initialState,
    resetSort: state => {
      state.sortModel = initialState.sortModel;
    },
    resetFilter: state => {
      state.filterModel = initialState.filterModel;
    },
    resetPagination: state => {
      state.paginationModel = initialState.paginationModel;
    },
  },
});

export const {
  setFilterModel,
  setSortModel,
  setPaginationModel,
  reset,
  resetSort,
  resetFilter,
  resetPagination,
} = contactTableModelsSlice.actions;

export const contactTableModelsReducer = contactTableModelsSlice.reducer;
