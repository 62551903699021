//  Embeds the cal.com calendar
import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

import { useUser } from "../../hooks/useUser";

export  function CalEmbed() {
  const { data: user } = useUser();

  useEffect(()=>{
    (async function() {
      const cal = await getCalApi();
      cal(`ui`, { "theme": `light`, "styles":{ "branding":{ "brandColor":`#FF5253` } },"layout":`month_view` });
    })();
  }, []);

  return <Cal
    calLink={ `team/paidnice/onboarding-session` }
    style={ { width:`100%`,height:`100%`,overflow:`scroll` } }
    config={
      {
        layout: `month_view`,
        name: user.name,
        email: user.email,
        fromApp: `true`,
      }
    }
  />;
}
