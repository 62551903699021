import { currencyFormatter } from 'shared';

export function parseCurrencyFilter(value?: number) {
  if (value === undefined || value === null) {
    return undefined;
  }

  const asString = value.toString();

  // If they have no decimal place, assume they want dollars
  if (asString.indexOf(`.`) === -1) {
    return value * 100;
  }

  // If they have a decimal place, assume they want cents
  const removedDecimal = asString.replace(`.`, ``);

  return Number(removedDecimal);
}

export {
  currencyFormatter,
};
