import { getGridBooleanOperators, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators, GridColDef, GridCsvExportOptions, GridLogicOperator, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { ContactStatus, currencyFormatter, displayPhoneNumber, IContactPerson, IContactResponseItem, toDecimal } from "shared";
import { useEffect, useMemo, useState } from "react";
import { DataGridPro, DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD, useGridApiRef } from "@mui/x-data-grid-pro";
// import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
// import { useLocalStorage } from "usehooks-ts";
import { Tooltip } from "@mui/material";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import { When } from "react-if";
import { useLocation } from "react-router";

import { ContactTableFields, useContactsV2, useContactTableModels } from "../../../hooks/useContacts";
import { WrappedLink } from "../../WrappedLink";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { useAccountingLanguge } from "../../../hooks/useAccountingLanguage";
import { filterColumns, getColumnForNewFilter, getTogglableColumns } from "../../../lib/datagrid";
import { Badge } from "../../../../common/Atoms/Badge";
import { Dropdown } from "../../../../common/Atoms/Dropdown";
import { useGroups } from "../../../hooks/useGroups";
import { useAddContactsToGroupMutation } from "../../../services/api/groupApi/group";
import { useContactTags } from "../../../hooks/useContactTags";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { useAccountingSystemName } from "../../../hooks/useAccountingSystemName";
import { useSyncContactsMutation } from "../../../services/api/contactsApi/contacts";
import { Button } from "../../../../common/Atoms/Button";
import { Notification } from "../../../../common/Atoms/Notification";
import { env } from "../../../../common/lib/env";

import { ContactQuickFilterToolbar } from "./ContactQuickFiltersToolbar";
import { ApplyTags } from "./ApplyTags";
import { FreezeContacts } from "./FreezeContacts";
import { QuickActions } from "./QuickActions";

interface ContactTableRootProps {
  context?: `group`
}

// augment the props for the toolbar slot
// eslint-disable-next-line quotes
declare module "@mui/x-data-grid"{
  interface ToolbarPropsOverrides {
    setRowSelectionModel: (value: GridRowSelectionModel) => void;
    selectedContacts: number[];
  }
}

type TransformedRows = Omit<IContactResponseItem
  & Omit<IContactPerson, `id`>, `id`>
  & {
    contactPersonId: string,
    contactId: number,
    hierarchy: string[],
    id: string,
    selectable: boolean,
  };

const groupingColDef: DataGridProProps[`groupingColDef`] = {
  headerName: ``,
  width: 1,
  minWidth: 1,
  resizable: false,
  renderCell: () => <CustomGridTreeDataGroupingCell />,
};

const neverShowColumns = [
  ContactTableFields.status,
  ContactTableFields.paidniceGroup,
  ContactTableFields.tags,
  ContactTableFields.mobileNumber,
  ContactTableFields.externalAccountNumber,
  ContactTableFields.includeInNotifications,
  ContactTableFields.portalUrl,
  ContactTableFields.externalId,
];

const getTreeDataPath: DataGridProProps[`getTreeDataPath`] = row => row.hierarchy;

export function ContactTableRoot({
  context,
}: ContactTableRootProps) {
  const { data, isLoading, isFetching } = useContactsV2();
  const { filterModel, setFilterModel, paginationModel, setPaginationModel, sortModel, setSortModel, resetModels } = useContactTableModels();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  // const [dataGridStateInvoice, setDataGridStateInvoice] = useLocalStorage<GridInitialStatePro>(`dataGridStateContact`, {});
  const accountingLanguage = useAccountingLanguge();
  const [ syncContacts, { isLoading: syncContactsLoading } ] = useSyncContactsMutation();
  const apiRef = useGridApiRef();
  const currentOrg = useGetSelectedOrganisation();
  const { tags } = useContactTags();
  const { data: groups } = useGroups();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const {
    contacts,
    totalCount,
    externalAggregateNames,
    totalVersionOneCount,
  } = data;

  const {
    contacts: {
      externalAggregateNameLabel,
      externalAccountNumberLabel,
    },
  } = accountingLanguage;

  // On first mount, reset the filters
  useEffect(() => {
    if (urlParams.has(`persist`)) {
      // Do nothing
    }
    else {
      resetModels();
    }
  }, []);

  const externalAggregateNameOptions = useMemo(() => {
    if (!externalAggregateNames) return [];

    return externalAggregateNames.filter(name => !!name).map(name => ({
      label: name,
      value: name,
    }));
  }, [externalAggregateNames]);

  const tagOptions = useMemo(() => {
    if (!tags) return [];

    return tags.map(tag => ({
      label: tag,
      value: tag,
    }));
  }, [tags]);

  const groupOptions = useMemo(() => {
    if (!groups) return [];

    return groups.map(g => ({
      label: g.title,
      value: g.id,
    }));
  }, [groups]);

  // ---- STORE AND RESTORE COL WIDTHS ----
  // const saveSnapshot = useCallback(() => {
  //   if (apiRef?.current?.exportState) {
  //     const currentState = apiRef.current.exportState();
  //     setDataGridStateInvoice(currentState);
  //   }
  // }, [apiRef]);

  // useLayoutEffect(() => {
  //   if (apiRef.current?.restoreState) {

  //     // We only want to set column widths and order
  //     const stateToRestore: GridInitialStatePro = {
  //       columns: dataGridStateInvoice.columns,
  //       pinnedColumns: dataGridStateInvoice.pinnedColumns,
  //     };

  //     apiRef.current.restoreState(stateToRestore);

  //     // handle refresh and navigating away/refreshing
  //     window.addEventListener(`beforeunload`, saveSnapshot);
  //   }

  //   return () => {
  //     // in case of an SPA remove the event-listener
  //     window.removeEventListener(`beforeunload`, saveSnapshot);
  //     saveSnapshot();
  //   };
  // }, [saveSnapshot, apiRef]);

  // ---- END STORE AND RESTORE COL WIDTHS ----

  // Extract all contact persons to the top line to let MUI group them
  const flattenedContacts = useMemo(() => {
    if (!contacts) return [];

    const flattened: Array<TransformedRows> = [];

    contacts.forEach(contact => {
      const primary = contact.primaryContactPerson;

      flattened.push({
        ...contact,
        ...primary,
        id: `${contact.id}_${primary.id}`,
        contactId: contact.id,
        contactPersonId: primary.id,
        hierarchy: [contact.id.toString()],
        selectable: true,
      });

      contact.contactPersons.forEach(additional => {
        flattened.push({
          ...contact,
          ...additional,
          id: `${contact.id}_${additional.id}`,
          contactId: contact.id,
          contactPersonId: additional.id,
          hierarchy: [contact.id.toString(), additional.id],
          selectable: false,
          // Set empty values for some fields to keep table clean
          name: ``,
          tags: [],
          externalAggregateNames: [],
          externalAccountNumber: ``,
          unpaidInvoiceCount: null,
        });
      });
    });

    return flattened;
  }, [contacts]);

  const columns: GridColDef<TransformedRows>[] = useMemo(() => {
    const result: GridColDef<TransformedRows>[] = [
      {
        field: ContactTableFields.name,
        headerName: `Name`,
        width: 220,
        type: `string`,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        renderCell: ({ row }) => {
          if (!row.selectable) return null;

          return (
            <div className={ `flex flex-col` }>
              <span className={ `flex` }>
                <WrappedLink
                  to={ `/contacts/${ row.contactId }` }
                >
                  <Paragraph
                    variant={ `link` }
                    as={ `span` }
                  >
                    { row.name }
                  </Paragraph>
                </WrappedLink>

                <div className={ `flex flex-wrap ml-2` }>
                  {
                    row.tags.map(tag => (
                      <Badge
                        key={ tag + row.id }
                        className={ `max-w-fit mr-2` }
                        color={ `yellow` }
                      >
                        { tag }
                      </Badge>
                    ))
                  }
                </div>
              </span>

              <WrappedLink
                to={ `/groups/${ row.groupId }` }
              >
                <Paragraph
                  variant={ `link` }
                  as={ `span` }
                  className={ `` }
                  size={ `xs` }
                >
                  { row.groupTitle }
                </Paragraph>
              </WrappedLink>

              <Tooltip title={ `Account Number` }>
                <span>
                  <Paragraph
                    as={ `span` }
                    variant={ `help` }
                  >
                    { row.externalAccountNumber }
                  </Paragraph>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: ContactTableFields.contactPersonName,
        headerName: `Person`,
        width: 110,
        type: `string`,
        valueGetter: (value, row) => displayPhoneNumber(row.firstName, row.lastName),
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        sortable: false,
      },
      {
        field: ContactTableFields.email,
        headerName: `Email`,
        width: 200,
        type: `string`,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        sortable: false,
        cellClassName: params => {
          if (params.row.emailError) {
            return `text-orange-600`;
          }
        },
        renderCell: ({ row }) => {
          return (
            <div>
              <div className={ `flex flex-wrap` }>
                <Tooltip title={ row.emailError || row.email }>
                  <span>
                    { row.email }
                  </span>
                </Tooltip>
                <When condition={ row.email }>
                  <Tooltip title={ row.includeInNotifications ? `Included in notifications` : `Excluded in notifications` }>
                    <span>
                      <Badge
                        className={ `ml-2 max-w-fit` }
                        color={ row.includeInNotifications ? `green` : `gray` }
                      >
                        { row.includeInNotifications ? `✔` : `✘` }
                      </Badge>
                    </span>
                  </Tooltip>
                </When>
              </div>

              <Paragraph>
                { displayPhoneNumber(row.mobileCountryCode, row.mobileNumber) }
              </Paragraph>
            </div>
          );
        },
      },
      {
        field: ContactTableFields.externalAggregateNames,
        headerName: externalAggregateNameLabel,
        width: 100,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: externalAggregateNameOptions,
        valueFormatter: (value, row) => row.externalAggregateNames.join(`, `),
      },
      {
        field: ContactTableFields.frozen,
        headerName: `Frozen`,
        width: 80,
        type: `boolean`,
        filterOperators: getGridBooleanOperators(),
        renderCell: params => {
          if (params.row.selectable) {
            if (params.row.frozen) {
              return (
                <Tooltip title={ `Contact is frozen. No policies will apply.` }>
                  <span className={ `ml-6 w-full h-full flex items-center` }>
                    <LockClosedIcon className={ `h-5 w-5 text-blue-400` } />
                  </span>
                </Tooltip>
              );
            }

            return (
              <Tooltip title={ `Contact not frozen` }>
                <span className={ `ml-6 w-full h-full flex items-center` }>
                  <LockOpenIcon className={ `h-5 w-5 text-gray-200` } />
                </span>
              </Tooltip>
            );
          }

          return null;
        },
      },
      {
        field: ContactTableFields.outstandingBalance,
        type: `number`,
        headerName: `Outstanding`,
        valueGetter: (value, row) => toDecimal(row.outstandingBalanceHome),
        valueFormatter: value => {
          return currencyFormatter(currentOrg?.baseCurrency)(value);
        },
        renderCell: params => {
          if (!params.row.selectable) return null;
        },
        filterOperators: getGridNumericOperators().filter(op => {
          return [`>=`, `=`, `<=`].includes(op.value);
        }),
      },
      {
        field: ContactTableFields.overdueBalance,
        type: `number`,
        headerName: `Overdue`,
        cellClassName: `text-red-500`,
        valueGetter: (value, row) => toDecimal(row.overdueBalanceHome),
        valueFormatter: value => {
          return currencyFormatter(currentOrg?.baseCurrency)(value);
        },
        renderCell: params => {
          if (!params.row.selectable) return null;
        },
        filterOperators: getGridNumericOperators().filter(op => {
          return [`>=`, `=`, `<=`].includes(op.value);
        }),
      },
      {
        field: ContactTableFields.unpaidInvoiceCount,
        headerName: `No. of Unpaid Invoices`,
        type: `number`,
        valueGetter: (value, row) => row.unpaidInvoiceCount,
        filterOperators: getGridNumericOperators().filter(op => {
          return [`>=`, `=`, `<=`].includes(op.value);
        }),
        width: 80,
      },

      // HIDDEN
      {
        field: ContactTableFields.paidniceGroup,
        headerName: `Group`,
        type: `singleSelect`,
        valueGetter: (value, row) => row.groupId,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: groupOptions,
      },
      {
        field: ContactTableFields.status,
        headerName: `Status`,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: [
          { value: ContactStatus.ACTIVE, label: `Active` },
          { value: ContactStatus.ARCHIVED, label: `Archived` },
        ],
      },
      {
        field: ContactTableFields.tags,
        headerName: `Tags`,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: tagOptions,
      },
      {
        field: ContactTableFields.mobileNumber,
        headerName: `Mobile`,
        type: `string`,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        valueGetter: (value, row) => displayPhoneNumber(row.mobileCountryCode, row.mobileNumber),
      },
      {
        field: ContactTableFields.externalAccountNumber,
        headerName: externalAccountNumberLabel,
        type: `string`,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
      },
      {
        field: ContactTableFields.includeInNotifications,
        headerName: `Include In Notifications`,
        type: `boolean`,
        filterOperators: getGridBooleanOperators(),
        description: `Is this contact person included in email notifications?`,
        sortable: false,
        filterable: false,
      },
      {
        field: ContactTableFields.portalUrl,
        headerName: `Portal URL`,
        type: `string`,
        filterable: false,
        sortable: false,
        valueGetter: (value, row) => `${env.portalWebUrl}/c/${row.hash}`,
      },
      {
        field: ContactTableFields.externalId,
        headerName: `External ID`,
        type: `string`,
        filterable: false,
        sortable: false,
        valueGetter: (value, row) => row.externalId,
      },
    ];

    return result;
  }, [externalAggregateNameLabel, externalAccountNumberLabel, externalAggregateNames, tagOptions, groupOptions]);

  const selectedContacts = useMemo(() => {
    const extractedIds = rowSelectionModel.map(rowId => {
      return Number((rowId as string).split(`_`)[0]);
    });

    return [...new Set([...extractedIds])];
  }, [rowSelectionModel]);

  function onContactSync() {
    syncContacts(currentOrg?.id);
  }

  const viewportHeight = window.innerHeight;
  const tableHeight = viewportHeight - 180; // px for the header

  return (
    <div
      style={ { height: tableHeight } }
    >
      <div className={ `mb-4 flex justify-between items-center` }>
        { /* Title */ }
        <div>
          <Heading
            variant={ `default` }
            size={ `xl` }
          >
            { `Contacts` }
          </Heading>
          <Paragraph
            variant={ `help` }
          >
            { `Contacts will be automatically imported as they are created or updated in ${useAccountingSystemName()} in real time.` }
          </Paragraph>
        </div>

        { /* Resync / Ops */ }
        <div className={ `mt-4 sm:mt-0 sm:ml-16 sm:flex-none` }>
          <Tooltip title={ `Force a re-sync of contacts now.` }>
            <span>
              <Button
                onClick={ onContactSync }
                loading={ syncContactsLoading }
                disabled={ currentOrg?.contactSyncInProgress || syncContactsLoading }
                color={ `lateGreenOutline` }
                size={ `sm` }
              >
                { currentOrg?.syncInProgress ? `Checking for updates...` : `Check for updates` }
              </Button>
            </span>
          </Tooltip>
        </div>

      </div>
      <When condition={ totalVersionOneCount > 0 }>
        <Notification
          type={ `warning` }
          className={ `mt-4 mb-0` }
        >
          {
            currentOrg?.contactSyncInProgress ?
              `${totalVersionOneCount} contacts are still waiting to be synced. They will be available shortly.` :
              `${totalVersionOneCount} contacts have not yet been synced. Please click sync or wait for the next daily sync.`
          }
        </Notification>
      </When>

      <DataGridPro
        apiRef={ apiRef }
        columns={ columns }
        rows={ flattenedContacts }
        loading={ isLoading || isFetching }
        initialState={ {
          columns: {
            columnVisibilityModel: {
              [GRID_TREE_DATA_GROUPING_FIELD]: false,
              [ContactTableFields.status]: false,
              [ContactTableFields.paidniceGroup]: false,
              [ContactTableFields.tags]: false,
              [ContactTableFields.mobileNumber]: false,
              [ContactTableFields.externalAccountNumber]: false,
              [ContactTableFields.includeInNotifications]: false,
              [ContactTableFields.portalUrl]: false,
              [ContactTableFields.externalId]: false,
            },
          },
        } }
        getRowHeight={ () => `auto` }
        // Tree data
        treeData
        getTreeDataPath={ getTreeDataPath }
        groupingColDef={ groupingColDef }
        defaultGroupingExpansionDepth={ 1 }
        getRowClassName={ ({ row }) => row.selectable ? `` : `text-gray-500` }

        // Filter
        filterDebounceMs={ 300 }
        filterMode={ `server` }
        filterModel={ filterModel }
        onFilterModelChange={ setFilterModel }
        // headerFilters
        // headerFilterHeight={ 54 }

        // Pagination
        pagination
        paginationMode={ `server` }
        paginationModel={ paginationModel }
        onPaginationModelChange={ setPaginationModel }
        pageSizeOptions={ [10, 25, 50, 100, 250] }
        rowCount={ totalCount }

        // Sort
        sortModel={ sortModel }
        onSortModelChange={ setSortModel }
        sortingMode={ `server` }

        // Selection
        checkboxSelection
        rowSelectionModel={ rowSelectionModel }
        onRowSelectionModelChange={ setRowSelectionModel }
        keepNonExistentRowsSelected
        isRowSelectable={ ({ row }) => row.selectable }

        // Misc
        disableVirtualization
        disableColumnMenu

        // Styling
        rowHeight={ 76 }
        columnHeaderHeight={ 42 }
        className={ `bg-white` }
        sx={ {
          '--DataGrid-overlayHeight': `300px`,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: `2px solid #e0e0e0`,
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: `8px` },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: `10px` },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: `22px` },
        } }
        localeText={ {
          toolbarColumns: `Hide Columns`,
          toolbarFilters: `Advanced Filters`,
          toolbarExport: rowSelectionModel.length > 0 ? `Export ${rowSelectionModel.length} row${rowSelectionModel.length === 1 ? `` : `s`}` : `Export`,
        } }

        // Slots
        slots={ {
          toolbar: CustomToolbar,
        } }
        slotProps={ {
          columnsManagement: {
            getTogglableColumns: cols => getTogglableColumns(cols, neverShowColumns),
          },
          toolbar: {
            showQuickFilter: true,
            rowSelectionModel,
            setRowSelectionModel,
            selectedContacts,
          },
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
          filterPanel: {
            filterFormProps: {
              filterColumns: args => filterColumns(args, context === `group` ? [ContactTableFields.paidniceGroup] : []),
            },
            getColumnForNewFilter: getColumnForNewFilter,
            logicOperators: [GridLogicOperator.And],
          },
        } }
      />

    </div>
  );
}

function CustomToolbar({ selectedContacts, setRowSelectionModel }) {
  const currentOrg = useGetSelectedOrganisation();
  const { data: groups } = useGroups();
  const [addContactsToGroup, { isLoading }] = useAddContactsToGroupMutation();

  const groupOptions = useMemo(() => {
    if (!groups) return [];

    return groups.map(g => ({
      label: g.title,
      value: g.id,
    }));
  }, [groups]);

  async function onMoveToGroup(e) {
    try {
      await addContactsToGroup({
        contactIds: [...selectedContacts],
        id: e.value,
        organisationId: currentOrg.id,
      });

      setRowSelectionModel([]);
    }
    catch (e) {
      console.error(e);
    }
  }

  const csvOptions: GridCsvExportOptions = {
    fileName: `contact_export_${currentOrg?.legalName}_${new Date().toISOString()}`,
    getRowsToExport: selectedContacts.length ? () => selectedContacts : undefined,
    fields: [
      ContactTableFields.name,
      ContactTableFields.contactPersonName,
      ContactTableFields.email,
      ContactTableFields.externalAggregateNames,
      ContactTableFields.frozen,
      ContactTableFields.outstandingBalance,
      ContactTableFields.overdueBalance,
      ContactTableFields.unpaidInvoiceCount,
      ContactTableFields.externalAccountNumber,
      ContactTableFields.includeInNotifications,
      ContactTableFields.tags,
      ContactTableFields.paidniceGroup,
      // Hidden in UI cols
      ContactTableFields.status,
      ContactTableFields.portalUrl,
      ContactTableFields.externalId,
    ],
  };

  return (
    <GridToolbarContainer>
      <div className={ `flex justify-between w-full pt-3` }>
        <div className={ `pl-2` }>
          <div className={ `flex flex-col items-start space-y-2` }>
            <GridToolbarQuickFilter
              placeholder={ `Quickly search name, email or account number..` }
              quickFilterParser={ value => [value] }
              sx={ { width: 350 } }
              debounceMs={ 350 }
            />
            <div className={ `flex space-x-2` }>
              <Dropdown
                size={ `sm` }
                position={ `right` }
                label={
                  selectedContacts.length === 0 ?
                    `Move contacts to group` :
                    `Move ${selectedContacts.length} contacts to group`
                }
                options={ [groupOptions] }
                disabled={ !selectedContacts.length || isLoading }
                onSelect={ onMoveToGroup }
              />

              <ApplyTags
                selectedContacts={ selectedContacts }
              />

              <FreezeContacts
                selectedContacts={ selectedContacts }
              />

              <QuickActions
                selectedContacts={ selectedContacts }
              />
            </div>
          </div>
        </div>

        <div className={ `flex flex-col items-end space-y-2` }>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarColumnsButton />
            <GridToolbarExport
              csvOptions={ csvOptions }
              printOptions={ {
                disableToolbarButton: true,
              } }
            />
          </div>

          <ContactQuickFilterToolbar />
        </div>
      </div>
    </GridToolbarContainer>
  );
}

function CustomGridTreeDataGroupingCell() {
  return null;
}
