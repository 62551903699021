import { IActionParams, IActionsResponse, PolicyType } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const actionApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchActions: builder.query<IActionsResponse, IActionParams>({
      query: ({ organisationId, ...params }) => ({
        url: `actions`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
        params: {
          ...params,
        },
      }),
      providesTags: result => result ?
        [
          ...result.actions.map(({ id }) => ({ type: CacheTags.Actions, id })),
          { type: CacheTags.Actions, id: `LIST` },
        ]
        : [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    // Actions for dash widget - initially only the draft ones due today
    fetchDashboardActions: builder.query<IActionsResponse, IActionParams>({
      query: ({ organisationId, ...params }) => ({
        url: `actions`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
        params: {
          ...params,
        },
      }),
      providesTags: result => result ?
        [
          ...result.actions.map(({ id }) => ({ type: CacheTags.DashActions, id })),
          { type: CacheTags.DashActions, id: `LIST` },
        ]
        : [{ type: CacheTags.DashActions, id: `LIST` }],
    }),
    revertAction: builder.mutation({
      query: ({
        organisationId,
        actionId,
      }) => ({
        url: `actions/${actionId}/revert`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    recalculateActions: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
      }) => ({
        url: `actions/recalculate`,
        method: `POST`,
        params: {
          invoiceId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.DashActions, id: `LIST` },
      ],
    }),
    actionNow: builder.mutation({
      query: ({
        organisationId,
        actionId,
      }) => ({
        url: `actions/${actionId}/now`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.DashActions, id: `LIST` },
      ],
    }),
    forceRecheckAll: builder.mutation({
      query: ({
        organisationId,
      }) => ({
        url: `actions/force-recheck`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    removeAction: builder.mutation({
      query: ({
        organisationId,
        actionId,
      }) => ({
        url: `actions/${actionId}`,
        method: `DELETE`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    removeAllFailedActions: builder.mutation({
      query: ({
        organisationId,
      }) => ({
        url: `actions`,
        method: `DELETE`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    removeFromBatch: builder.mutation({
      query: ({
        organisationId,
        actionId,
      }) => ({
        url: `actions/${actionId}/remove-from-batch`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    /** E.g recreate the late fee email actions */
    regenerateChildActions: builder.mutation({
      query: ({
        organisationId,
        actionId,
      }) => ({
        url: `actions/${actionId}/regenerate-child-actions`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),

    regenerateChildActionsBulk: builder.mutation({
      query: ({
        organisationId,
        actionIds,
      }) => ({
        url: `actions/bulk/regenerateChildActions`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          actionIds,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),

    previewAction: builder.query({
      query: ({ organisationId, actionId }) => ({
        url: `actions/${actionId}/preview`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
    }),

    approveActions: builder.mutation({
      query: ({
        organisationId,
        actionIds,
        force,
      }: {
        organisationId: string;
        actionIds: string[];
        force: boolean;
      }) => ({
        url: `actions/bulk/approve`,
        method: `POST`,
        data: {
          actionIds,
          force,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),

    revertApprovals: builder.mutation({
      query: ({
        organisationId,
        actionIds,
      }: {
        organisationId: string;
        actionIds: string[];
      }) => ({
        url: `actions/bulk/revert-approvals`,
        method: `POST`,
        data: {
          actionIds,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
    adhocBulkActions: builder.mutation({
      query: ({
        organisationId,
        type,
        invoiceIds,
        contactIds,
      }: {
        organisationId: string;
        type: PolicyType;
        invoiceIds?: string[];
        contactIds?: number[];
      }) => ({
        url: `actions/bulk/adhoc`,
        method: `POST`,
        data: {
          type,
          invoiceIds,
          contactIds,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
    }),
    removePendingAdhoc: builder.mutation({
      query: ({
        organisationId,
      }: {
        organisationId: string;
      }) => ({
        url: `actions/bulk/remove-pending`,
        method: `DELETE`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Actions, id: `LIST` }],
    }),
  }),
});

export const {
  useFetchActionsQuery,
  useRecalculateActionsMutation,
  useRevertActionMutation,
  useActionNowMutation,
  useForceRecheckAllMutation,
  useRemoveActionMutation,
  useRemoveAllFailedActionsMutation,
  useRemoveFromBatchMutation,
  useRegenerateChildActionsMutation,
  usePreviewActionQuery,
  useFetchDashboardActionsQuery,
  useApproveActionsMutation,
  useRevertApprovalsMutation,
  useAdhocBulkActionsMutation,
  useRemovePendingAdhocMutation,
  useRegenerateChildActionsBulkMutation,
} = actionApi;
