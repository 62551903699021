const env = {
  apiUrl: import.meta.env.VITE_API_URL,
  environment: import.meta.env.VITE_ENVIRONMENT,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  gtmId: import.meta.env.VITE_GTM_ID, // e.g 'GTM-000000'
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN,
  maintenanceMode: import.meta.env.VITE_MAINTENANCE_MODE === `true`,
  frigatePublicKey: import.meta.env.VITE_ENVIRONMENT === `production`
    ? `api_public_ema5aE1ik2Wf4qSAycc1e1OXlNJzFR5QWyVmkebxwUwTIhaPNbYPKHQdy8QICqId`
    : `api_public_B4uDrfpY7Red9QZluPiMZan67VolYYf0tPHXHCJvSmK9g4UmyJR83KwlTNi6N1aV`, // NOT A SECRET
  // Dev Tools
  frigateSetupFlowId: import.meta.env.VITE_ENVIRONMENT === `production`
    ? `flow_IiD9HYRRk6aff8Zt`
    : `flow_IbxaEFwSmRtkaunU`,
  noPolling: import.meta.env.VITE_NO_POLLING === `true`,
  legacyPolicyUser: import.meta.env.VITE_LEGACY_POLICY_USER === `true`,
  baseApiUrl: ``,
  basePortalApiUrl: ``,
  isProd: false,
  isDev: false,
  loginEnabled: false,
  isCypress: import.meta.env.VITE_IS_CYPRESS === `true`,
  isLocal: import.meta.env.VITE_LOCAL === `true`,
  portalWebUrl: import.meta.env.VITE_PORTAL_URL,
  stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  portalAuth0ClientId: import.meta.env.VITE_PORTAL_AUTH0_CLIENT_ID,
  portalAuth0Domain: import.meta.env.VITE_PORTAL_AUTH0_DOMAIN,
  auth0BackendApiAudience: import.meta.env.VITE_AUTH0_BACKEND_API_AUDIENCE,
  muiLicenceKey: import.meta.env.VITE_MUI_LICENCE_KEY,
  logrocketAppId: import.meta.env.VITE_LOGROCKET_APP_ID,
  newPolicyLayout: import.meta.env.VITE_NEW_POLICY_TEST === `true`,
  // PostHog
  posthogApiKey: import.meta.env.VITE_PUBLIC_POSTHOG_KEY,
  posthogApiHost: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  posthogUiHost: import.meta.env.VITE_PUBLIC_POSTHOG_UI_HOST,
  // For local testing without code change
  posthogEnabledOverride: import.meta.env.VITE_POSTHOG_ENABLED_DEV_OVERRIDE === `true`,
};

// Extra Helpers
env.baseApiUrl = `${env.apiUrl}/api/v1/`;
env.basePortalApiUrl = `${env.apiUrl}/api/portal/`;
env.isProd = env.environment === `production`;
env.isDev = env.environment !== `production` && env.environment !== `staging`;

env.loginEnabled = (!!env.portalAuth0ClientId && !!env.portalAuth0Domain);

export {
  env,
};
