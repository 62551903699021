import { useParams } from "react-router";
import { Button } from "@mui/material";

import { useAdhocActionModal } from "../../hooks/useAdhocActionModal";
import { useActionInvoicesMutation } from "../../services/api/invoiceApi/invoice";

interface InvoiceBulkActionsToolbarProps {
  rowSelectionModel: string[];
}

export function InvoiceBulkActionsToolbar({
  rowSelectionModel,
}: InvoiceBulkActionsToolbarProps) {
  const { organisationId } = useParams();
  const { openBulkReminders, openBulkLateFees } = useAdhocActionModal();
  const [actionInvoices, { isLoading: actionInvoiceLoading }] = useActionInvoicesMutation();

  function onUnfreezeSelected() {
    actionInvoices({
      action: `reset`,
      organisationId,
      invoiceIds: [...rowSelectionModel],
    });
  }

  function onFreezeSelected() {
    actionInvoices({
      action: `freeze`,
      organisationId,
      invoiceIds: [...rowSelectionModel],
    });
  }

  function onSendReminder() {
    openBulkReminders([...rowSelectionModel]);
  }

  function onApplyLateFee() {
    openBulkLateFees([...rowSelectionModel]);
  }

  return (
    <div>
      <Button
        onClick={ onFreezeSelected }
        disabled={ !rowSelectionModel.length || actionInvoiceLoading }
        size={ `small` }
      >
        {
          actionInvoiceLoading ? `Updating...` : `Freeze ${rowSelectionModel.length} invoice${rowSelectionModel.length === 1 ? `` : `s`}`
        }
      </Button>

      <Button
        onClick={ onUnfreezeSelected }
        disabled={ !rowSelectionModel.length || actionInvoiceLoading }
        size={ `small` }
      >
        {
          actionInvoiceLoading ? `Updating...` : `Unfreeze ${rowSelectionModel.length} invoice${rowSelectionModel.length === 1 ? `` : `s`}`
        }
      </Button>

      <Button
        onClick={ onSendReminder }
        disabled={ !rowSelectionModel.length }
        size={ `small` }
        color={ `info` }
      >
        { `Send ${rowSelectionModel.length} reminder${rowSelectionModel.length === 1 ? `` : `s`}` }
      </Button>

      <Button
        onClick={ onApplyLateFee }
        disabled={ !rowSelectionModel.length }
        size={ `small` }
        color={ `warning` }
      >
        { `Apply ${rowSelectionModel.length} late fee${rowSelectionModel.length === 1 ? `` : `s`}` }
      </Button>
    </div>
  );
}
