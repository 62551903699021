
import { When } from "react-if";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Button } from "../../../common/Atoms/Button";
import { Divider } from "../../../common/Atoms/Divider";
import { useTrialActionLimitReached } from "../../hooks/useTrialActionsReached";
import { useGetSubscriptionMetadata } from "../../hooks/useGetSubscription";
import { ActionLimitCTA } from "../Molecules/ActionLimitCTA";
import { closeAndClear } from "../../slices/actionDrawer";

interface ActionNowModal {
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
  message: string;
}

export function ActionNowModal({ onCancel, onConfirm, show, message }: ActionNowModal) {
  const actionLimitReached = useTrialActionLimitReached();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onClose() {
    // This is the top X, treat as cancel
    onCancel();
  }

  function onStartPlan() {
    if (subscriptionMetadata?.id) {

      onCancel();
      navigate(`/billing/subscriptions/${subscriptionMetadata.id}`);
    }
  }

  return (
    <Modal
      title={ `` }
      open={ show }
      setOpen={ onClose }
    >

      <div className={ `flex flex-col items-center mt-6` }>
        <Paragraph variant={ `primary` }>
          { message }
        </Paragraph>

        <Divider weight={ `light` } />

        <div>
          <Button
            color={ `gray` }
            className={ `mr-4` }
            onClick={ onCancel }
          >
            { `Cancel` }
          </Button>
          <Button
            className={ `mr-4` }
            color={ `red` }
            onClick={ onConfirm }
            disabled={ actionLimitReached }
          >
            { actionLimitReached ? `Free Action Limit Reached` : `Process Action Now` }
          </Button>

          <When condition={ actionLimitReached && !!subscriptionMetadata }>
            <Button
              className={ `mr-4` }
              color={ `green` }
              onClick={ onStartPlan }
            >
              { `Start Plan` }
            </Button>
          </When>
        </div>

        <ActionLimitCTA
          afterClick={ () => {
            onCancel();
            dispatch(closeAndClear());
          } }
        />
      </div>
    </Modal>
  );
}

