import React, { useMemo, useState } from 'react';
import { When } from 'react-if';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import * as DateFns from 'date-fns';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { IInvalidField } from 'shared';

import { classNames }  from '../lib/classNames';

import { ChangeFunction } from './types';

const months = {
  0: `January`,
  1: `February`,
  2: `March`,
  3: `April`,
  4: `May`,
  5: `June`,
  6: `July`,
  7: `August`,
  8: `September`,
  9: `October`,
  10: `November`,
  11: `December`,
};

interface IDatePickerProps {
  label?: string;
  value: null | string;
  name?: string;
  onChange: ChangeFunction<string, null | string>
  placeholder?: string;
  invalid?: boolean | IInvalidField;
  className?: string;
  displayValue?: null | string;
  disableClear?: boolean;
  showSaveButton?: boolean;
}

export function DatePicker({
  label,
  value,
  name,
  onChange,
  placeholder,
  invalid,
  className,
  displayValue,
  disableClear,
  showSaveButton,
}: IDatePickerProps) {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(value ? DateFns.getMonth(new Date(value)) : DateFns.getMonth(today));
  const [currentYear, setCurrentYear] = useState(value ? DateFns.getYear(new Date(value)) : DateFns.getYear(today));

  const days = useMemo(() => {
    const thisMonth = DateFns.setYear(DateFns.setMonth(new Date(), currentMonth), currentYear);
    const endOfMonth = DateFns.endOfMonth(thisMonth);
    const startOfMonth = DateFns.startOfMonth(thisMonth);
    const startOfWeekMonth = DateFns.startOfWeek(startOfMonth);
    const endOfWeekMonth = DateFns.endOfWeek(endOfMonth);

    const interval = {
      start: startOfWeekMonth,
      end: endOfWeekMonth,
    };

    return DateFns.eachDayOfInterval(interval).map(day => {
      const dateStr = DateFns.format(day, `yyyy-MM-dd`);

      return {
        date: dateStr,
        isCurrentMonth: DateFns.getMonth(day) === currentMonth,
        isToday: DateFns.isToday(day),
        isSelected: value ? DateFns.isSameDay(day, new Date(value)) : false,
        isPast: DateFns.isPast(day),
      };
    });
  }, [ currentMonth, currentYear, value ]);

  const handleMonthChangeUp = e => {
    e.preventDefault();

    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);

      return;
    }

    setCurrentMonth(currentMonth + 1);
  };

  const handleMonthChangeDown = e => {
    e.preventDefault();

    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);

      return;
    }

    setCurrentMonth(currentMonth - 1);
  };

  const handleDateClick = (e, day: typeof days[0], close) => {
    onChange({
      value: day.date,
      name,
    });

    close();
  };

  const onClear = e => {
    e.stopPropagation();

    onChange({
      name,
      value: null,
    });
  };

  return (
    <Popover className={
      classNames(
        `relative`,
        className,
      ) }
    >
      <PopoverButton
        as={ `div` }
      >
        <label htmlFor={ name }
          className={ `block text-sm font-medium text-gray-500` }>
          { label }
        </label>
        <div className={ `relative mt-1 rounded-md shadow-sm flex items-center` }>
          <input
            readOnly
            value={ displayValue || value || `` }
            type={ `text` }
            name={ name }
            id={ name }
            className={
              classNames(
                `block w-full rounded-md border-gray-300 focus:border-lateGreen-500 focus:ring-lateGreen-500 sm:text-sm`,
                invalid && `border-red-500`,
                disableClear ? `` : `pr-12`,
              )
            }
            placeholder={ placeholder }
          />
          <When condition={ !!value && !disableClear }>
            <button
              className={ `absolute right-2` }
              onClick={ onClear }
            >
              <XCircleIcon
                className={ `h-5 w-5 text-gray-400` }
                aria-hidden={ `true` }
              />
            </button>
          </When>
        </div>
      </PopoverButton>
      <PopoverPanel
        className={ `absolute z-10 top-0 left-0 w-full min-w-[250px]` }
      >
        { ({ close }) => (
          <div
            className={ `z-10 mt-10 text-center block bg-white shadow-md p-4 rounded-lg ` }
          >
            <div className={ `flex items-center text-gray-900` }>
              <button
                type={ `button` }
                onClick={ handleMonthChangeDown }
                className={ `-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500` }
              >
                <span className={ `sr-only` }>{ `Previous month` }</span>
                <ChevronLeftIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </button>
              <div className={ `flex-auto font-semibold` }>{ `${months[currentMonth]} ${currentYear}` }</div>
              <button
                onClick={ handleMonthChangeUp }
                type={ `button` }
                className={ `-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500` }
              >
                <span className={ `sr-only` }>{ `Next month` }</span>
                <ChevronRightIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </button>
            </div>
            <div className={ `mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500` }>
              <div>{ `S` }</div>
              <div>{ `M` }</div>
              <div>{ `T` }</div>
              <div>{ `W` }</div>
              <div>{ `T` }</div>
              <div>{ `F` }</div>
              <div>{ `S` }</div>
            </div>
            <div className={ `isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200` }>
              { days.map((day, dayIdx) => (
                <button
                  key={ day.date }
                  onClick={ e => handleDateClick(e, day, close) }
                  type={ `button` }
                  className={ classNames(
                    `py-1.5 hover:bg-gray-100 focus:z-10`,
                    day.isCurrentMonth ? `bg-white` : `bg-gray-50`,
                    (day.isSelected || day.isToday) && `font-semibold`,
                    day.isSelected && `text-white`,
                    !day.isSelected && day.isCurrentMonth && !day.isToday && `text-gray-900`,
                    !day.isSelected && !day.isCurrentMonth && !day.isToday && `text-gray-400`,
                    day.isToday && !day.isSelected && `text-lateGreen-600`,
                    dayIdx === 0 && `rounded-tl-lg`,
                    dayIdx === 6 && `rounded-tr-lg`,
                    dayIdx === days.length - 7 && `rounded-bl-lg`,
                    dayIdx === days.length - 1 && `rounded-br-lg`,
                  ) }
                >
                  <time
                    dateTime={ day.date }
                    className={ classNames(
                      `mx-auto flex h-7 w-7 items-center justify-center rounded-full`,
                      day.isSelected && day.isToday && `bg-lateGreen-600`,
                      day.isSelected && !day.isToday && `bg-gray-900`,
                    ) }
                  >
                    { day.date.split(`-`).pop().replace(/^0/, ``) }
                  </time>
                </button>
              )) }
            </div>
            <When condition={ showSaveButton }>
              <button
                type={ `button` }
                className={ `mt-8 w-full rounded-md border border-transparent bg-lateGreen-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-lateGreen-700 focus:outline-none focus:ring-2 focus:ring-lateGreen-500 focus:ring-offset-2` }
              >
                { `Save` }
              </button>
            </When>
          </div>
        ) }
      </PopoverPanel>
    </Popover>
  );
}
