import { EmailTemplateTypes, SubscriptionVerficationStatus } from "shared";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { Tooltip } from "@mui/material";

import { useEmailTemplatesByType } from "../../../hooks/useEmailTemplates";
import { useSendAnnouncementMutation } from "../../../services/api/contactsApi/contacts";
import { Modal } from "../../../../common/Atoms/Modal";
import { Button } from "../../../../common/Atoms/Button";
import { IOption, Select } from "../../../../common/Atoms/Select";
import { useGetSubscriptionMetadata } from "../../../hooks/useGetSubscription";

interface ISendAnnouncementProps {
  selectedContacts: number[];
}

export function SendAnnouncement({ selectedContacts }: ISendAnnouncementProps) {
  const announcementTemplates = useEmailTemplatesByType(EmailTemplateTypes.ANNOUNCEMENT);
  const statementTemplates = useEmailTemplatesByType(EmailTemplateTypes.STATEMENT_EMAIL);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [chosenTemplate, setChosenTemplate] = useState(``);
  const {
    subscriptionMetadata,
  } = useGetSubscriptionMetadata();
  const [sendAnnouncement, { isLoading: isSending }] = useSendAnnouncementMutation();
  const { organisationId } = useParams();

  async function onSend(templateId: string) {
    await sendAnnouncement({
      templateId,
      organisationId,
      contactIds: selectedContacts,
    });

    setShowEmailModal(false);
  }
  const options: IOption[] = useMemo(() => {
    const emailTemplates = [...announcementTemplates, ...statementTemplates];

    return emailTemplates.map(template => ({
      label: template.name,
      value: template.id,
      loading:isSending,
    }));
  }, [announcementTemplates, statementTemplates, isSending]);

  const disabled = !subscriptionMetadata || !subscriptionMetadata.hasSubscription || subscriptionMetadata.verificationStatus !== SubscriptionVerficationStatus.VERIFIED;

  return (
    <>
      <Modal
        open={ showEmailModal }
        setOpen={ () => setShowEmailModal(false) }
        title={ `Send an email to ${selectedContacts.length} contacts` }
      >
        <div className={ `space-y-4 px-4` }>

          <div className={ `flex justify-between items-end` }>
            <Select
              label={ `Email Template` }
              options={ options }
              selected={ chosenTemplate }
              emptyText={ `Select email template...` }
              onChange={
                e => setChosenTemplate(e.value)
              }
            />

          </div>
          <div className={ `flex justify-between items-end` }>
            <Button
              onClick={ () => onSend(chosenTemplate) }
              className={ `h-full mt-0` }
              loading={ isSending }
              disabled={ !chosenTemplate || !selectedContacts.length }
            >
              { `Confirm and send email` }
            </Button>
          </div>
        </div>
      </Modal>
      <Tooltip title={
        disabled ?
          `Disabled while account verification takes place` :
          `Send a one off email to the selected customers`
      }>
        <Button
          onClick={ () => setShowEmailModal(true) }
          disabled={ !selectedContacts.length || disabled }
          loading={ isSending }
          size={ `lg` }
        >
          { `Send Email` }
        </Button>
      </Tooltip>
    </>
  );
}
