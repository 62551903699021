import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { ContactStatus, IContactParams } from 'shared';

import { useFetchContactsQuery } from '../services/api/contactsApi/contacts';
import { RootState } from '../store';
import { initialState, setParams } from '../slices/contactParams';
import { reset, setFilterModel, setSortModel, setPaginationModel, resetFilter, resetSort, resetPagination } from '../slices/contactTableModels';
import { parseCurrencyFilter } from '../lib/currencyHelpers';

export enum ContactTableFields {
  name = `name`,
  paidniceGroup = `paidniceGroup`,
  frozen = `frozen`,
  externalAggregateNames = `externalAggregateNames`,
  outstandingBalance = `outstandingBalance`,
  overdueBalance = `overdueBalance`,
  unpaidInvoiceCount = `unpaidInvoiceCount`,
  // Spanned Rows
  email = `email`,
  mobileNumber = `mobileNumber`,
  includeInNotifications = `includeInNotifications`,
  contactPersonName = `contactPersonName`,

  // Hidden cols
  externalAccountNumber = `externalAccountNumber`,
  status = `status`,
  tags = `tags`,
  groupId = `groupId`,
  portalUrl = `portalUrl`,
  externalId = `externalId`,
}

export function useContacts() {
  const { organisationId, groupId: groupIdParam } = useParams<{ organisationId: string, groupId?: string }>();
  const { params } = useContactParams();

  const groupId = groupIdParam ? Number(groupIdParam) : undefined;

  const result = useFetchContactsQuery({
    ...params,
    // If groupId is present in URL, then override the params, otherwise the group ID params takes precedence, it may be defined or null
    groupId: groupId ? groupId : params.groupId,
    organisationId,
  }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    // pollingInterval:  1000 * 15, UNCOMMENT THIS LINE -----------------------------------------------------------------------------------------------
    skipPollingIfUnfocused: true,
  });

  return result;
}

export function useContactParams() {
  const params = useSelector((s: RootState) => s.contactParams);
  const dispatch = useDispatch();

  const updateParams = (update: Partial<typeof params>) => {
    return dispatch(setParams(update));
  };

  const resetParams = (keepKeys?: string[]) => {
    const newState = { ...initialState };

    if (keepKeys) {
      keepKeys.forEach(key => {
        newState[key] = params[key];
      });
    }

    dispatch(setParams(newState));
  };

  return {
    resetParams,
    updateParams,
    params,
  };
}

export function useContactTableModels() {
  const dispatch = useDispatch();
  const contactTableModels = useSelector((s: RootState) => s.contactTableModels);

  return {
    // State
    filterModel: contactTableModels.filterModel,
    sortModel: contactTableModels.sortModel,
    paginationModel: contactTableModels.paginationModel,
    // Dispatch
    setFilterModel: (filterModel: GridFilterModel) => dispatch(setFilterModel(filterModel)),
    setSortModel: (sortModel: GridSortModel) => dispatch(setSortModel(sortModel)),
    setPaginationModel: (paginationModel: GridPaginationModel) => dispatch(setPaginationModel(paginationModel)),
    resetModels: () => dispatch(reset()),
    resetSort: () => dispatch(resetSort()),
    resetFilter: () => dispatch(resetFilter()),
    resetPagination: () => dispatch(resetPagination()),
  };
}

export function useContactsV2() {
  const { filterModel, sortModel, paginationModel } = useContactTableModels();
  const { organisationId, groupId } = useParams<{ organisationId?: string, groupId?: string }>();

  const quickSearchStr = filterModel.quickFilterValues?.[0];

  const outstandingBalance = filterModel.items.find(f => f.field === ContactTableFields.outstandingBalance)?.value;
  const overdueBalance = filterModel.items.find(f => f.field === ContactTableFields.overdueBalance)?.value;
  const tags = filterModel.items.find(f => f.field === ContactTableFields.tags)?.value;

  const params: IContactParams = {
    limit: paginationModel.pageSize,
    page: paginationModel.page + 1, // MUI is 0 based

    status: filterModel.items.find(f => f.field === ContactTableFields.status)?.value as ContactStatus | undefined,
    frozen: filterModel.items.find(f => f.field === ContactTableFields.frozen)?.value,
    groupId: filterModel.items.find(f => f.field === ContactTableFields.paidniceGroup)?.value,
    searchStr: quickSearchStr,
    externalAggregateName: filterModel.items.find(f => f.field === ContactTableFields.externalAggregateNames)?.value,
    tags: tags ? [tags] : undefined,
    name: filterModel.items.find(f => f.field === ContactTableFields.name)?.value,
    personName: filterModel.items.find(f => f.field === ContactTableFields.contactPersonName)?.value,
    email: filterModel.items.find(f => f.field === ContactTableFields.email)?.value,
    mobile: filterModel.items.find(f => f.field === ContactTableFields.mobileNumber)?.value,
    externalAccountId: filterModel.items.find(f => f.field === ContactTableFields.externalAccountNumber)?.value,
    outstandingBalance: parseCurrencyFilter(outstandingBalance),
    outstandingBalanceOperator: filterModel.items.find(f => f.field === ContactTableFields.outstandingBalance)?.operator as `>=` | `<=` | `=`,
    overdueBalance: parseCurrencyFilter(overdueBalance),
    overdueBalanceOperator: filterModel.items.find(f => f.field === ContactTableFields.overdueBalance)?.operator as `>=` | `<=` | `=`,
    unpaidInvoiceCount: filterModel.items.find(f => f.field === ContactTableFields.unpaidInvoiceCount)?.value,
    unpaidInvoiceCountOperator: filterModel.items.find(f => f.field === ContactTableFields.unpaidInvoiceCount)?.operator as `>=` | `<=` | `=`,

    // Sort
    sortColumn: sortModel[0]?.field,
    sortDirection: sortModel[0]?.sort as `asc` | `desc` | undefined,
  };

  if (groupId) {
    params.groupId = Number(groupId);
  }

  const result = useFetchContactsQuery({
    ...params,
    organisationId,
  }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    pollingInterval:  1000 * 15,
    skipPollingIfUnfocused: true,
  });

  if (!result.data) {
    result.data = {
      contacts: [],
      totalCount: 0,
      externalAggregateNames: [],
      totalVersionOneCount: 0,
    };
  }

  return result;
}
