import { Fragment, useMemo } from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { When } from 'react-if';
import { IInvalidField } from 'shared';

import { classNames }  from '../lib/classNames';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';

import { Badge } from './Badge';
import { FormDescription } from './FormDescription';
import { Paragraph } from './Typography/Paragraph';
import { FormLabel } from './FormLabel';
import { SmallSpinner } from './SmallSpinner';

const classes = {
  invalid: `border-red-600`,
};

interface IOption {
  label: string
  value: string | number
  disabled?: boolean
  badge?: string
  badgeColor?: string
}

interface IMultiSelectProps {
  options: IOption[]
  selected?: string[] | number[]
  onChange: (value: string[] | number[]) => void
  label?: string
  invalid?: boolean | IInvalidField
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  display?: string | any
  clearable?: boolean
  description?: string
  warning?: string
  helpIcon?: string
  loading?: boolean
  valueClassname?: string
  openUpwards?: boolean
}

export function MultiSelect({
  options,
  selected: selectedValues,
  onChange,
  label,
  invalid,
  display,
  clearable,
  description,
  warning,
  helpIcon,
  loading,
  valueClassname,
  openUpwards,
}: IMultiSelectProps) {
  const opts = useMemo(() => {
    const newOpts = [ ...options ];

    return newOpts;
  }, [ selectedValues, options ]);

  function handleChange(value) {
    return onChange(value);
  }

  function onClear(e) {
    e.stopPropagation();
    e.preventDefault();

    return onChange([]);
  }

  return (
    <div className={ `min-w-full min-h-12` }>
      <Listbox value={ selectedValues }
        onChange={ handleChange }
        multiple>
        { ({ open }) => (
          <>
            <Label
              className={ `mb-2 flex` }
              as={ FormLabel }
            >
              { label }
              <When condition={ helpIcon }>
                <HelpIcon
                  tooltip={ helpIcon }
                  className={ `ml-2` }
                />
              </When>
            </Label>
            <When condition={ description }>
              <FormDescription>
                { description }
              </FormDescription>
            </When>
            <div className={ `relative` }>
              <ListboxButton as={ `div` }
                className={
                  classNames(
                    `relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500 sm:text-sm`,
                    invalid && classes[`invalid`],
                  )
                }>
                <span className={ `block truncate ` + valueClassname  }>{ display }</span>
                <span className={ `absolute inset-y-0 right-0 flex items-center pr-2` }>
                  <When condition={ clearable && selectedValues?.length }>
                    <button
                      onClick={ onClear }
                    >
                      <XCircleIcon
                        className={ `h-5 w-5 text-gray-400` }
                        aria-hidden={ `true` }
                      />
                    </button>
                  </When>

                  <When condition={ loading }>
                    <SmallSpinner className={ `ml-1` } />
                  </When>
                  <ChevronUpDownIcon className={ `h-5 w-5 text-gray-400` }
                    aria-hidden={ `true` } />
                </span>
              </ListboxButton>

              <Transition
                show={ open }
                as={ Fragment }
                leave={ `transition ease-in duration-100` }
                leaveFrom={ `opacity-100` }
                leaveTo={ `opacity-0` }
              >
                <ListboxOptions
                  anchor={ openUpwards ? {
                    to: `bottom start`,
                  } : undefined }
                  className={
                    classNames(
                      `absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`,
                      openUpwards ? `min-w-fit` : `w-full`,
                    )
                  }>
                  { opts.map(option => (
                    <ListboxOption
                      key={ option.value }
                      className={ ({ focus }) =>
                        classNames(
                          focus ? `text-white bg-lateGreen-600` : `text-gray-900`,
                          `relative cursor-default select-none py-2 pl-8 pr-4`,
                        )
                      }
                      value={ option.value }
                    >
                      { ({ selected, focus }) => {
                        return (
                          <>
                            <span className={ classNames(selected ? `font-semibold` : `font-normal`, `block truncate`) }>
                              { option.label }
                              { ` ` }
                              <When condition={ option.badge }>
                                <Badge message={ option.badge }
                                  color={ option.badgeColor } />
                              </When>
                            </span>

                            <When condition={ selected }>
                              <span
                                className={ classNames(
                                  focus ? `text-white` : `text-lateGreen-600`,
                                  `absolute inset-y-0 left-0 flex items-center pl-1.5`,
                                ) }
                              >
                                <CheckIcon className={ `h-5 w-5` }
                                  aria-hidden={ `true` } />
                              </span>
                            </When>
                          </>
                        );
                      } }
                    </ListboxOption>
                  )) }
                </ListboxOptions>
              </Transition>
              <When condition={ warning }>
                <Paragraph
                  className={ `mt-1` }
                  variant={ `warning` }
                >
                  { warning }
                </Paragraph>
              </When>
            </div>
          </>
        ) }
      </Listbox>
    </div>
  );
}
