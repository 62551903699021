import { createSlice } from '@reduxjs/toolkit';
import { IInvoiceParams } from 'shared';

export const initialState: IInvoiceParams = {
  page: 1,
  sortColumn: null,
  sortType: null,
  invoiceTextSearch: ``,
  xeroStatus: null,
  limit: 50,
  frozen: null,
  groupId: null,
};

const invoiceParamsSlice = createSlice({
  name: `invoiceParams`,
  initialState,
  reducers: {
    setParams(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setParams } = invoiceParamsSlice.actions;

export const invoiceParamsReducer = invoiceParamsSlice.reducer;
