import { allowedRegions, EmailTemplateTypes, findByAlias, InterestRateCountry, PolicyAmountsTaxLineType, PolicyCalculationType, PolicyStatementInterestTo, PolicyStatementType, PolicyType } from "shared";
import { useMemo } from "react";
import { When } from "react-if";

import { useAdhocActionModal } from "../../../../hooks/useAdhocActionModal";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { BANK_OF_ENGLAND_ARTICLE } from "../../../../constants/links";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { RadioStack } from "../../../../../common/Atoms/RadioStack";
import { Transition } from "../../../../../common/Atoms/Transition";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { ForwardRefGridItem, GridItem } from "../../../../../common/Atoms/GridItem";
import { Grid } from "../../../../../common/Atoms/Grid";
import { fieldIsValid } from "../../../../lib/helper";
import { TaxSelector } from "../PolicyForm/TaxSelector";
import { useAccountingSystemTerm } from "../../../../hooks/useAccountingSystemTerm";
import { AccountSelector } from "../PolicyForm/AccountSelector";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { Select } from "../../../../../common/Atoms/Select";
import { EmailTemplateSelector } from "../PolicyForm/Fields/EmailTemplateSelector";
import { useAccountingSystemTaxApplicability } from "../../../../hooks/useAccountingSystemTaxApplicability";

const calcucateOptions = [
  {
    title: `Interest Rate Per Annum`,
    description: `Applies a % of overdue, prorated per day`,
    value: PolicyCalculationType.PRORATA,
  },
  {
    title: `Percentage`,
    description: `Applies a fee equal to the percentage of the due amount owing at statement issue time`,
    value: PolicyCalculationType.PERCENT,
  },
];

const statementToOptions = [
  {
    label: `When this policy is applied`,
    value: PolicyStatementInterestTo.DEFAULT,
  },
  {
    label: `End of previous month`,
    value: PolicyStatementInterestTo.END_OF_PREVIOUS_MONTH,
  },
];

// TODO, Conditionally show
const taxInclusiveTypes = [
  {
    label: `Tax Inclusive`,
    value: PolicyAmountsTaxLineType.INCLUSIVE,
  },
  {
    label: `Tax Exclusive`,
    value: PolicyAmountsTaxLineType.EXCLUSIVE,
  },
  {
    label: `Not Applicable`,
    value: PolicyAmountsTaxLineType.NOT_APPLICABLE,
  },
];

function getInterestRateCopy(region: string) {
  if (findByAlias(region) === InterestRateCountry.UK) {
    return {
      label: `Apply the Bank of England base rate`,
      description: `Will apply the BoE base rate + your percent. Using the correct base rate(s) that the invoice period spans accross.`,
      helpLink: BANK_OF_ENGLAND_ARTICLE,
    };
  }

  return null;
}

export function AdhocInterestCharge() {
  const { type, currentSystemPolicy, currentSystemPolicyForm, update, contactName, contactId, invalids, isBulk, contactIds } = useAdhocActionModal();
  const currentOrg = useGetSelectedOrganisation();
  const accountingSystemTaxApplicability = useAccountingSystemTaxApplicability();

  const allowedCentralRate = useMemo(() => {
    if (currentOrg) {
      return allowedRegions.includes(currentOrg.region);
    }

    return false;
  }, [currentOrg]);

  const percentPostfix = useMemo(() => {
    if (!currentSystemPolicyForm.use_central_interest_rate || !allowedCentralRate) {
      return currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT ? `%` : `% annually`;
    }

    return currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT ? `%` : `%pa + BoE`;

  }, [currentSystemPolicyForm.calculation_type, currentSystemPolicyForm.use_central_interest_rate, allowedCentralRate]);

  const includedInvoiceOptions = useMemo(() => {
    const label = currentSystemPolicyForm?.calculation_type === PolicyCalculationType.PRORATA ? `Calculate interest from` : `Include Invoices`;

    const options = [
      {
        label: currentSystemPolicyForm?.calculation_type === PolicyCalculationType.PRORATA ? `The invoice due date` : `With an overdue balance`,
        value: PolicyStatementType.OVERDUE,
      },
      {
        label: currentSystemPolicyForm?.calculation_type === PolicyCalculationType.PRORATA ? `The invoice issue date` : `With an outstanding or overdue balance`,
        value: PolicyStatementType.OUTSTANDING,
      },
    ];

    return {
      label,
      options,
    };
  }, [currentSystemPolicyForm]);

  if (!currentSystemPolicy || !currentSystemPolicyForm) return null;

  if (!type) return null;

  if (type !== PolicyType.STATEMENT_LATE_FEE || currentSystemPolicy.policy_type !== PolicyType.STATEMENT_LATE_FEE) return null;

  if (!contactIds && !contactId) return null;

  const isProrata = currentSystemPolicyForm?.calculation_type === PolicyCalculationType.PRORATA;
  const percentLabel = currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT ? `Percent` : `Percent Per Annum`;

  const headingText = isBulk ?
    `Issue a one-off interest charge for ${contactIds.length} contacts` :
    `Issue a one-off interest charge for ${contactName || `this contact`}`;

  return (
    <div>
      <Heading>
        { headingText }
      </Heading>

      <div className={ `p-4 space-y-2` }>
        <RadioStack
          options={ calcucateOptions }
          onChange={ update }
          selected={ currentSystemPolicyForm.calculation_type }
          name={ `calculation_type` }
        />

        <Transition
          show={ isProrata && allowedCentralRate }
          speed={ `slow` }
        >
          <div>
            <Grid
              cols={ 3 }
              gapX={ 4 }
            >
              <GridItem span={ 3 }>
                <Toggle
                  { ...getInterestRateCopy(currentOrg?.region) }
                  checked={ currentSystemPolicyForm?.use_central_interest_rate || false }
                  onChange={ update }
                  name={ `use_central_interest_rate` }
                  invalid={ fieldIsValid(`use_central_interest_rate`, invalids) }
                />
              </GridItem>
            </Grid>
          </div>
        </Transition>

        <Grid
          cols={ 3 }
        >
          <GridItem
            span={ 1 }
            position={ `bottom` }
          >
            <AccountSelector
              label={ useAccountingSystemTerm(`Fee Income Account`) }
              value={ currentSystemPolicyForm.xero_account_code }
              valueKey={ `code` }
              onChange={ update }
              name={ `xero_account_code` }
              invalid={ fieldIsValid(`xero_account_code`, invalids) }
            />
          </GridItem>
          <GridItem
            span={ 1 }
            position={ `bottom` }
          >
            <TaxSelector
              label={ useAccountingSystemTerm(`Tax Rate`) }
              code={ currentSystemPolicyForm.tax_rate }
              onChange={ update }
              name={ `tax_rate` }
              invalid={ fieldIsValid(`tax_rate`, invalids) }
            />
          </GridItem>
          <GridItem
            span={ 1 }
            position={ `bottom` }
          >
            <InputTextAddon
              invalid={ fieldIsValid(`percent`, invalids) }
              helpIcon={ `Apply a percent of overdue line item to the late fee. Set to 0 to not add` }
              label={ percentLabel }
              value={ currentSystemPolicyForm.percent }
              onChange={ update }
              disabled={ currentSystemPolicyForm.calculation_type === PolicyCalculationType.NOT_APPLICABLE }
              addOnText={ percentPostfix }
              name={ `percent` }
            />
          </GridItem>
        </Grid>

        <Grid cols={ isProrata ? 2 : 1 }>
          <GridItem span={ 1 }>
            <Select
              label={ includedInvoiceOptions.label }
              options={ includedInvoiceOptions.options }
              onChange={ update }
              selected={ currentSystemPolicyForm.statement_type }
              name={ `statement_type` }
            />
          </GridItem>

          <Transition
            show={ isProrata }
            speed={ `slow` }
          >
            <ForwardRefGridItem span={ 1 }>
              <Select
                label={ `Calculate interest to` }
                options={ statementToOptions }
                onChange={ update }
                selected={ currentSystemPolicyForm.statement_interest_to || PolicyStatementInterestTo.DEFAULT }
                name={ `statement_interest_to` }
              />
            </ForwardRefGridItem>
          </Transition>
        </Grid>

        <When condition={ currentSystemPolicyForm.statement_type === PolicyStatementType.OVERDUE }>
          <Grid cols={ 2 }>
            <GridItem span={ 1 }>
              <InputTextAddon
                label={ `Minimum Overdue Debt Age` }
                helpIcon={ `Only include invoices/debts that are overdue by at least this many days.` }
                value={ currentSystemPolicyForm.overdue_age_min || `` }
                onChange={ update }
                name={ `overdue_age_min` }
                invalid={ fieldIsValid(`overdue_age_min`, invalids) }
                addOnText={ `days` }
                dataType={ `number` }
              />
            </GridItem>
            <GridItem span={ 1 }>
              <InputTextAddon
                label={ `Maximum Overdue Debt Age` }
                helpIcon={ `Only include invoices/debts that are overdue by no more than this many days. Leave blank for no limit.` }
                value={ currentSystemPolicyForm.overdue_age_max || `` }
                onChange={ update }
                name={ `overdue_age_max` }
                invalid={ fieldIsValid(`overdue_age_max`, invalids) }
                addOnText={ `days` }
                dataType={ `number` }
              />
            </GridItem>
          </Grid>
        </When>

        <EmailTemplateSelector
          value={ currentSystemPolicyForm.template_id }
          type={ EmailTemplateTypes.STATEMENT_INTEREST_EMAIL }
          handleChange={ update }
          invalid={ fieldIsValid(`template_id`, invalids) }
          name={ `template_id` }
          label={ `Send email notification to customer when interest is applied` }
          nullable
          emptyText={ `Do not send email notification` }
          helpIcon={ `Create or edit the template under Email Templates.` }
          currentPolicyId={ currentSystemPolicy.id }
        />

        <Transition
          speed={ `slow` }
          show={ isProrata || currentSystemPolicyForm.calculation_type === PolicyCalculationType.PERCENT }
        >
          <div>
            <Grid cols={ 2 }>
              <GridItem span={ 1 }>
                <Toggle
                  label={ `Include previous interest charges` }
                  description={ `Should we include previous interest charges in the calculation? (Compounding Interest)` }
                  helpIcon={ `This will include or exclude any invoice created by Paidnice as a interest charge or late fee` }
                  name={ `include_previous_interest` }
                  onChange={ update }
                  checked={ currentSystemPolicyForm.include_previous_interest }
                  invalid={ fieldIsValid(`include_previous_interest`, invalids) }
                />
              </GridItem>
              <GridItem span={ 1 }>
                <Toggle
                  label={ `Adjust for Credit` }
                  description={ `If the customer has any unallocated credit notes. Should we apply an adjustment to the charges?` }
                  name={ `adjust_for_credit` }
                  onChange={ update }
                  helpIcon={ `The reduction of the fee will be calculated with the same parameters as above` }
                  checked={ currentSystemPolicyForm.adjust_for_credit }
                  invalid={ fieldIsValid(`adjust_for_credit`, invalids) }
                />
              </GridItem>
            </Grid>
          </div>
        </Transition>

        <When condition={ accountingSystemTaxApplicability }>
          <Select
            label={ `If the amounts are taxable, are they tax inclusive or exclusive?` }
            options={ taxInclusiveTypes }
            onChange={ update }
            selected={ currentSystemPolicyForm.amounts_tax_line_type }
            name={ `amounts_tax_line_type` }
          />
        </When>
      </div>
    </div>
  );
}
