
import { PaymentProviderType, paymentProviderConfig } from "shared";
import { useMemo } from "react";
import { When } from "react-if";

import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { Divider } from "../../../../../common/Atoms/Divider";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Notification } from "../../../../../common/Atoms/Notification";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";

import { StripeConnect } from "./StripeConnect";
import { PinchPayments } from "./PinchPayments";

export function PaymentRoot() {
  const currentOrg = useGetSelectedOrganisation();
  const featureFlags = useFeatureFlags();

  const contents = useMemo(() => {
    const result = [];

    // Xero or QBO with feature flag
    if (currentOrg.accountingSystemType === `XERO` || (currentOrg.accountingSystemType === `QBO` && featureFlags.qboStripe)) {
      result.push(<StripeConnect />);
    }

    // for non-flagged QBO accounts, show a message to let them know they can request access to the beta
    if (currentOrg.accountingSystemType === `QBO` && !featureFlags.qboStripe) {
      result.push(
        <Card>
          <CardContent className={ `pt-6 flex justify-center items-center p-12` }>
            <Paragraph>
              { `Stripe is currently in beta for QuickBooks Online. Please contact us to request early access.` }
            </Paragraph>
          </CardContent>
        </Card>,
      );
    }

    if (paymentProviderConfig[PaymentProviderType.PINCH].enabledAccountingSystems.includes(currentOrg.accountingSystemType)) {
      result.push(<PinchPayments />);
    }

    return result;

  }, [currentOrg.accountingSystemType]);

  if (contents.length === 0) {
    return (
      <Card>
        <CardContent className={ `pt-6 flex justify-center items-center p-12` }>
          <Paragraph>
            { `No payment providers are available for your organisation currently. Please contact us to request an integration.` }
          </Paragraph>
        </CardContent>
      </Card>
    );
  }

  const hasBothMethods = currentOrg?.paymentProviders?.length > 0 && currentOrg?.stripeAccount?.connectionStatus === `connected`;

  return (
    <Card>
      <CardContent className={ `pt-6` }>
        <TwoColFormRow
          heading={ `Add a payment provider` }
          description={ `Connect or enable your preferred payment provider.` }
        >
          <When condition={ hasBothMethods }>
            <Notification
              type={ `warning` }
            >
              { `It is currently recommended to use a single payment provider to keep links consistent. Updates to allow multiple providers coming soon. Please reach out if you require this functionality.` }
            </Notification>
          </When>
          {
            contents.map((content, index) => (
              <div key={ index }
                className={ `mt-4` }>
                { content }

                {
                  index < contents.length - 1 && (
                    <Divider weight={ `light` } />
                  )
                }
              </div>
            ))
          }
        </TwoColFormRow>
      </CardContent>
    </Card>
  );
}
