import { useMemo } from "react";

import { fieldIsValid } from "../../../../../lib/helper";
import { useGetSelectedOrganisation } from "../../../../../hooks/useGetSelectedOrganisation";
import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";

import { IFieldProps } from "./fieldProps";

export function InvoiceReferenceIncludes({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const org = useGetSelectedOrganisation();
  const term = org.accountingSystemType === `XERO` ? `reference` : `memo`;

  const isRegex = useMemo(() => {
    const isRegex = /^re:\/.*\/$/.test(policyFormData.reference_contains || ``);

    return isRegex;
  },[term, policyFormData.reference_contains]);

  const label = useMemo(() => {
    if (isRegex) {
      return `Only include invoices where ${term} matches this regex:`;
    }

    return `Only include invoices where ${term} contains:`;
  }, [isRegex, term]);

  const description = useMemo(() => {
    if (isRegex) {
      return `Only include invoices that match this regex in the ${term}. Leave blank to include all invoices.`;
    }

    return `Only include invoices that have this value in the ${term}. Leave blank to include all invoices.`;
  }, [isRegex, term]);

  const helpText = useMemo(() => {
    if (isRegex) {
      return `Regular expressions allow you to filter invoices that do or do not match complex patterns.`;
    }

    return `This is not case sensitive. Caution: May also filter out previous interest charges!`;
  }, [isRegex, term]);

  return (
    <InputTextAddon
      label={ label }
      description={ description }
      helpIcon={ helpText }
      value={ policyFormData.reference_contains }
      onChange={ handleChange }
      name={ `reference_contains` }
      invalid={ fieldIsValid(`reference_contains`, invalids) }
    />
  );
}
