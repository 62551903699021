import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { gridFilterModelSelector, GridRenderHeaderFilterProps, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { useCallback, useMemo } from "react";

const getDefaultFilter = (field: string) => ({ field, operator: `is` });

export function LateFeeFilter(props: GridRenderHeaderFilterProps) {
  const { colDef } = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = useMemo(
    () => filterModel.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }

        return;
      }

      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
        value: event.target.value,
        id: Math.round(Math.random() * 1e5),
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? ``;

  return (
    <FormControl variant={ `standard` }
      sx={ { m: 1, minWidth: 30 } }
      fullWidth>
      <InputLabel id={ `select-is-late-fee-label` }>{ `` }</InputLabel>
      <Select
        labelId={ `select-is-late-fee-label` }
        id={ `select-is-late-fee` }
        value={ value }
        onChange={ handleChange }
      >
        <MenuItem value={ null }>
          <em>{ `Any` }</em>
        </MenuItem>
        <MenuItem value={ `true` }>{ `Only Late Fees` }</MenuItem>
        <MenuItem value={ `false` }>{ `Exclude Late Fees` }</MenuItem>
      </Select>
    </FormControl>
  );
}
