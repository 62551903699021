
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { Button } from "../../../../common/Atoms/Button";
import { useApiRequest } from "../../../hooks/useApiRequest";
import { env } from "../../../../common/lib/env";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { setState } from "../../../slices/statementPreviewModal";
import { orgHeader } from "../../../../common/lib/axiosBaseQuery";

interface StatementPreviewProps {
  hasUnsavedChanges: boolean;
}

export function StatementPreview({ hasUnsavedChanges }: StatementPreviewProps) {
  const [downloading, setDownloading] = useState(false);
  const currentOrg = useGetSelectedOrganisation();
  const request = useApiRequest();
  const dispatch = useDispatch();

  async function previewInBrowser() {
    setDownloading(true);

    try {
      const result = await request({
        method: `GET`,
        url: `${env.baseApiUrl}organisation/${currentOrg?.id}/previewStatementHtml`,
        headers: {
          [orgHeader]: currentOrg?.id,
        },
      });

      const html = result.data?.html;

      if (!html) {
        toast.error(`Could not create statement preview.`);

        return;
      }

      dispatch(setState({ show: true, html }));
    }
    catch (e) {
      const responseObj = await e.response?.data?.text ? await e.response?.data?.text() : null;
      toast.error(`${responseObj ? `${responseObj}` : `Could not create statement.`}`);
    }
    finally {
      setDownloading(false);
    }
  }

  return (
    <Button
      loading={ downloading }
      onClick={ previewInBrowser }
      disabled={ hasUnsavedChanges }
    >
      { hasUnsavedChanges ? `Preview (Unsaved Changes)` : `Preview Statement` }
    </Button>
  );
}
