import { MixpanelEvents, MixpanelLocationTypes, ActionType } from "shared";

import { Dropdown, DropdownOption } from "../../../../common/Atoms/Dropdown";
import { mixpanelService } from "../../../../common/lib/mixpanel";
import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";

interface IQuickActionsProps {
  selectedContacts: number[];
}

export function QuickActions({ selectedContacts }: IQuickActionsProps) {
  const { openBulkStatements, openBulkInterests } = useAdhocActionModal();

  function onBulkStatements() {
    openBulkStatements(selectedContacts);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.BulkAdhocAction, {
      location: MixpanelLocationTypes.ContactTable,
      action_type: ActionType.SEND_STATEMENT,
    });
  }

  function onBulkInterest() {
    openBulkInterests(selectedContacts);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.BulkAdhocAction, {
      location: MixpanelLocationTypes.ContactTable,
      action_type: ActionType.ISSUE_STATEMENT_LATE_FEE,
    });
  }

  const options: Array<DropdownOption[]> = [
    [
      {
        label: `Send ${selectedContacts.length} statements`,
        onClick: onBulkStatements,
      },
      {
        label: `Charge ${selectedContacts.length} contacts interest`,
        onClick: onBulkInterest,
      },
    ],
  ];

  return (
    <>
      <Dropdown
        options={ options }
        label={ `Quick Actions` }
        size={ `sm` }
        theme={ `outline` }
        disabled={ !selectedContacts.length }
      />
    </>
  );
}
